import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import {
  mediumStrenthRegex,
  passwordFeedback,
  strongStrenthRegex,
} from '../../../../utils/constants/passwords';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import UnauthPage from '../../../page/UnauthPage';
import {
  FormFields,
  SuccessObj,
  generateErrorMsg,
  getInitialValues,
  getValidationSchema,
  toApiData,
} from './UserActivate.functions';

type RouteParams = {
  code: string;
};

const UserActivateFormName = 'user-activate-form';

function UserActivate(): JSX.Element {
  const { t } = useTranslation();

  const { code } = useParams<RouteParams>();

  const defaultValues = useMemo(() => getInitialValues(code), [code]);

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);

  const methods = useForm({ defaultValues, resolver });
  const { reset } = methods;

  const { data, error, isLoading, reload } = useAxios<SuccessObj>();

  const history = useHistory();

  const [dialogVisibility, setDialogVisibility] = useState(false);

  const toastRef = useContext(ToastContext)?.toastRef!;

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      data?.message ?? t('User successfully activated')
    );

    setDialogVisibility(true);
  }, [t, data, prevData, toastRef]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    let err = error?.response?.data;
    errorToast(
      toastRef,
      t('Error'),
      t('Activate user failed {{errorMessage}}.', {
        errorMessage: err ? generateErrorMsg(t, err) : '',
      })
    );

    reset();
  }, [error, prevError, reset, t, toastRef]);

  function handleFormSubmision(values: FormFields) {
    reload({
      url: '/users/activate',
      data: toApiData(values),
      method: 'PUT',
      headers: { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID },
    });
  }

  return (
    <>
      <Dialog
        header={t('Activation Complete!')}
        visible={dialogVisibility}
        onHide={() => {
          setDialogVisibility(false);
        }}
        footer={
          <Button
            type="button"
            label={t('Login')}
            onClick={() => history.push('/login')}
          />
        }
      >
        <p>{t('Your account has been sucessfully activated.')}</p>
        <p>
          {t(
            'You can now log in using the username and password you chose during the registration.'
          )}
        </p>
      </Dialog>

      <UnauthPage title={t('Activate your account')}>
        <FormProvider {...methods}>
          <form
            id={UserActivateFormName}
            onSubmit={methods.handleSubmit(handleFormSubmision)}
          >
            <div className="p-fluid">
              <FieldWithErrors name="password" label={t('Password')}>
                <Controller
                  name="password"
                  render={({ field }) => (
                    <Password
                      {...field}
                      feedback
                      header={passwordFeedback(t)}
                      mediumRegex={mediumStrenthRegex as any}
                      strongRegex={strongStrenthRegex as any}
                      toggleMask
                      autoComplete="off"
                      promptLabel={t('Password')}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </FieldWithErrors>

              <FieldWithErrors name="repassword" label={t('Confirm Password')}>
                <Controller
                  name="repassword"
                  render={({ field }) => (
                    <Password
                      {...field}
                      feedback
                      mediumRegex={mediumStrenthRegex as any}
                      strongRegex={strongStrenthRegex as any}
                      toggleMask
                      autoComplete="off"
                      promptLabel={t('Confirm password')}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </FieldWithErrors>
              <Button
                type="submit"
                label={t('Activate')}
                className="p-mt-5"
                disabled={isLoading}
              />
            </div>
          </form>
        </FormProvider>
      </UnauthPage>
    </>
  );
}

export default UserActivate;
