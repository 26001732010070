import { SelectButton } from 'primereact/selectbutton';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OrderType } from '../../../../../types/api/orders';
import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import Step from '../../../../Stepper/Inline/Step';
import { FormFields } from '../CreateEditRecreate.functions';
import Note from './OrderSettings/Fields/Note';
import Letter from './OrderSettings/OrderTypes/Letter';
import Package from './OrderSettings/OrderTypes/Package';

function OrderSettings(): JSX.Element {
  const { t } = useTranslation();
  const proizvod_id = useWatch<FormFields, 'proizvod_id'>({
    name: 'proizvod_id',
  });

  return (
    <Step title={t('Order Settings')} fullWidth>
      <FieldWithErrors name="proizvod_id" label={t('Product type')}>
        <Controller
          name="proizvod_id"
          render={({ field }) => (
            <SelectButton
              id="proizvod_id"
              options={[
                { label: t('Package'), value: OrderType.Package },
                { label: t('Letter'), value: OrderType.Letter },
              ]}
              unselectable={false}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FieldWithErrors>
      {proizvod_id === OrderType.Package ? <Package /> : <Letter />}
      <Note />
    </Step>
  );
}

export default OrderSettings;
