type PhoneNumberKeys = 'mobile' | 'phone';

type PhoneNumberRegexObj = {
  [key: string]: {
    [K in PhoneNumberKeys]: RegExp;
  };
};

export const phoneNumberRegexes: PhoneNumberRegexObj = {
  mk: {
    mobile: /^(?:((?:\+?|(?:00))389)|(0))(7\d)(\d{3})(\d{3})$/,
    phone:
      /^(?:(?:(\+?|(?:00))(389))|(0))(?:(?:(2)(\d{4}))|(31|32|33|34|42|43|44|45|46|47|48)(\d{3}))(\d{3})$/,
  },
  sr: {
    mobile: /^(\+?381|0)?6(([0-6]|[8-9])\d{7}|(77|78)\d{6}){1}$/,
    phone:
      /^(?:(\+?381|0)(10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|30|31|32|33|34|35|36|37)\d{7})|(\+?383|0)(28|29|38|39)\d{7}$/,
  },
};

export const invalidPhoneNumberCharactersRegex = /[^\d+]|(?!^)\+/g;
