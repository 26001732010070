import './OrderResult.scss';

import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type Props = {
  action: 'create' | 'edit' | 'recreate';
  hasFailed: boolean;
  onCreateASimilarOrderBtnClick: () => void;
  onCreateANewOrderBtnClick: () => void;
  onPrintDeliveryNoteClick: () => void;
  onPrintStickerClick: () => void;
};

function OrderResult({
  action,
  hasFailed,
  onCreateASimilarOrderBtnClick,
  onCreateANewOrderBtnClick,
  onPrintDeliveryNoteClick,
  onPrintStickerClick,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();

  const title =
    action === 'create'
      ? t('Order Created')
      : action === 'edit'
      ? t('Order Edited')
      : t('Order Recreated');

  const subtitle =
    action === 'create'
      ? !hasFailed
        ? t('Your order was successfully created!')
        : t('Something went wrong while creating your order.')
      : action === 'edit'
      ? !hasFailed
        ? t('Your order was successfully edited!')
        : t('Something went wrong while editing your order.')
      : !hasFailed
      ? t('Your order was successfully recreated!')
      : t('Something went wrong while recreating your order.');

  const className = classNames('order-action-result', {
    'result-error': hasFailed,
    'result-success': !hasFailed,
  });

  return (
    <div className={className}>
      <div>
        <FontAwesomeIcon
          icon={hasFailed ? faTimesCircle : faCheckCircle}
          className="status-icon"
        />
      </div>

      <h2 className="title">{hasFailed ? t('Oops!') : title}</h2>
      <div className="subtitle">{subtitle}</div>

      <div className="order-result-button">
        <Button
          type="button"
          label={t('Create a similar order')}
          icon="fas fa-undo"
          className="p-mr-2"
          onClick={onCreateASimilarOrderBtnClick}
        />
        <Button
          type="button"
          label={t('Create a new order')}
          icon="fas fa-fast-backward"
          className="p-mr-2 p-button-outlined"
          onClick={onCreateANewOrderBtnClick}
        />
        <Button
          type="button"
          label={t('Go to My Orders')}
          icon="fas fa-shipping-fast"
          className="p-button-outlined"
          onClick={() => {
            history.replace('/orders');
          }}
        />
        {!hasFailed && (
          <Button
            type="button"
            label={t('Print Address Book')}
            icon="fas fa-address-book"
            className="p-button-outlined"
            onClick={onPrintDeliveryNoteClick}
          />
        )}
        {!hasFailed && (
          <Button
            type="button"
            label={t('Print sticker')}
            icon="fas fa-ticket-alt"
            className="p-button-outlined"
            onClick={onPrintStickerClick}
          />
        )}
      </div>
    </div>
  );
}

export default OrderResult;
