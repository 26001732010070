import { useTranslation } from 'react-i18next';

import noDataIllustration from '../../../assets/svg/no-data-amico.svg';

type Props = {
  title?: string;
  text?: string | JSX.Element;
};

function EmptyMessage({ title: _title, text: _text }: Props): JSX.Element {
  const { t } = useTranslation();

  const title = _title ?? t('No data found');
  const text =
    _text ??
    t("Your filters didn't match any data.<br />Please try again later.", {
      interpolation: { escapeValue: false },
    });

  return (
    <div className="p-text-center">
      <img src={noDataIllustration} style={{ maxWidth: 300 }} alt="" />

      <h2 className="p-mb-2">{title}</h2>
      <p className="p-m-0">{text}</p>
    </div>
  );
}

export default EmptyMessage;
