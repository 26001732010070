import { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Numeric } from '../types/general';
import { getSearchQueryParam } from '../utils/helpers/searchQuery';
import useSearchQueryParam from './useSearchQueryParam';

function useSearchQueryDropdownParam(
  param: string,
  filter: Numeric | null,
  setFilter: Dispatch<SetStateAction<any>>,
  dropdownOptions: { value: Numeric }[],
  nullable: boolean = true
) {
  const location = useLocation();

  const [initialValue] = useState(() =>
    getSearchQueryParam(location.search, param)
  );

  useSearchQueryParam(param, filter ? String(filter) : '');

  useEffect(() => {
    if (filter !== initialValue || dropdownOptions.length === 0) {
      return;
    }

    const value = dropdownOptions.find(
      (o) =>
        (typeof o.value === 'number' ? String(o.value) : o.value) === filter
    )?.value;

    if ((typeof value === 'number' && !isNaN(value)) || !!value) {
      setFilter(value);
    } else {
      setFilter(nullable ? null : '');
    }
  }, [dropdownOptions, filter, initialValue, nullable, setFilter]);
}

export default useSearchQueryDropdownParam;
