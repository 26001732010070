import './QRCode.scss';

import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../hooks/useAxios';
import { QRCodeResponse } from './QRCode.functions';

function QRCodeGenerate() {
  const { t } = useTranslation();
  const [qrCode, setQRCode] = useState<QRCodeResponse>();

  const { data: generatedQRCode, isLoading: generatedQRCodeIsLoading } =
    useAxios<QRCodeResponse>(`/users/otp`);

  const {
    data: generatedForcedQRCode,
    isLoading: generatedForcedQRCodeIsLoading,
    reload: reloadGeneratedForcedQRCode,
  } = useAxios<QRCodeResponse>(`/users/otp?force_new=true`, {
    skipWhen: true,
  });

  useEffect(() => {
    setQRCode(generatedQRCode);
  }, [generatedQRCode]);

  useEffect(() => {
    setQRCode(generatedForcedQRCode);
  }, [generatedForcedQRCode]);

  function handleForceNewQRCode() {
    reloadGeneratedForcedQRCode({});
  }

  return (
    <div className="qrcode-generate">
      {generatedQRCodeIsLoading || generatedForcedQRCodeIsLoading ? (
        <>
          <Skeleton width="100%" height="5rem" className="p-mb-3" />
        </>
      ) : (
        <div className="qrcode-generate-body">
          <div>
            <h3>{t('Acceptance code')}</h3>
            <QRCode
              value={qrCode?.code ?? ''}
              level="L"
              size={256}
              className="qr-code"
            />
          </div>
          <h3>{qrCode?.code}</h3>
          <div className="qr-code-description">
            <span>{t('Valid until')}</span>
            <span>{qrCode?.expiry_time}</span>
          </div>
          <div>
            <Button
              onClick={handleForceNewQRCode}
              type="button"
              label={t('Generate new Acceptance code')}
              className="main-btn button-secondary"
              disabled={generatedForcedQRCodeIsLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default QRCodeGenerate;
