import './ErrorDialog.scss';

import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isVisible: boolean;
  onHide: () => void;
  children: ReactNode;
};

function ErrorDialog({ isVisible, onHide, children }: Props): JSX.Element {
  const { t } = useTranslation();

  const header = t('Error');

  return (
    <Dialog
      header={header}
      visible={isVisible}
      closeOnEscape={false}
      blockScroll
      onHide={onHide}
      resizable={false}
      className="error-dialog"
    >
      {children}
    </Dialog>
  );
}

export default ErrorDialog;
