import LanguageSelector from './LanguageSelector';

function UnauthHeader(): JSX.Element {
  return (
    <div className="right-section">
      <LanguageSelector />
    </div>
  );
}

export default UnauthHeader;
