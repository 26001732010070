import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UnauthPage from '../../../../../page/UnauthPage';

function ResetPasswordCodeExpired(): JSX.Element {
  const { t } = useTranslation();

  return (
    <UnauthPage
      title={t('auth.resetPasswordCodeExpired.title')}
      description={t('auth.resetPasswordCodeExpired.description')}
    >
      <Trans t={t} i18nKey="auth.resetPasswordCodeExpired.content">
        <p>
          Please request a new{' '}
          <Link to="/profile/forgot-password">password reset link</Link> or go
          to the <Link to="/login">login page</Link>.
        </p>
      </Trans>
    </UnauthPage>
  );
}

export default ResetPasswordCodeExpired;
