import * as Sentry from '@sentry/react';
import { Middleware, Store } from 'redux';

import { ReduxState } from '../../types/redux';
import { deleteCookie, setCookie } from '../../utils/helpers/cookies';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_AUTH_TOKEN,
  loginUser,
} from '../actions/userActions';

function persistUser(store: Store<ReduxState>) {
  try {
    localStorage.setItem('user', JSON.stringify(store.getState().user));
  } catch (e) {
    Sentry.captureException(e);
  }
}

const userMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case LOGIN_USER:
      persistUser(store as Store<ReduxState>);

      if (!(action as ReturnType<typeof loginUser>).rememberMe) {
        setCookie(
          'customerSessionKey',
          String((action as ReturnType<typeof loginUser>).timestamp)
        );
      }
      break;

    case REFRESH_AUTH_TOKEN:
      persistUser(store as Store<ReduxState>);
      break;

    case LOGOUT_USER:
      try {
        sessionStorage.clear();
        localStorage.removeItem('user');
        deleteCookie('customerSessionKey');
      } catch (e) {
        Sentry.captureException(e);
      }
      break;

    default:
      return;
  }
};

export default userMiddleware;
