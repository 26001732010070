import { Field, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useAxios from '../../../../../../hooks/useAxios';
import { ImportListTypeResource } from '../../../../../../types/api/importlisttypes';
import { ReduxState } from '../../../../../../types/redux';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { FormValues } from '../FileImporter.functions';

type Props = {
  listTypeData: ImportListTypeResource | undefined;
  listTypeReload: ReturnType<typeof useAxios>['reload'];
};

function ListType({ listTypeData, listTypeReload }: Props): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: listTypesData } = useAxios<{ id: number; name: string }[]>(
    `/clients/${clientLoggedIn}/importlists/types`,
    {
      skipWhen: !clientLoggedIn,
    }
  );

  const listTypesOptions = useMemo(
    () =>
      listTypesData?.map((listType) => ({
        value: { id: listType.id, name: listType.name },
        label: listType.name,
      })) ?? [],
    [listTypesData]
  );

  useEffect(() => {
    if (!values.list_type || typeof values.list_type === 'string') {
      return;
    }

    listTypeReload({ url: `/importlisttypes/${values.list_type.id}` });
  }, [listTypeReload, values.list_type]);

  useEffect(() => {
    setFieldValue('postarina', listTypeData?.plaka_postarina_isprakac ?? '');
    setFieldValue('otkup', listTypeData?.plaka_otkup_isprakac ?? '');
    setFieldValue(
      'osiguruvanje',
      listTypeData?.plaka_osiguruvanje_isprakac ?? ''
    );
    setFieldValue(
      'povraten_dokument',
      listTypeData?.plaka_povraten_dokument_isprakac ?? ''
    );
  }, [
    listTypeData?.plaka_osiguruvanje_isprakac,
    listTypeData?.plaka_otkup_isprakac,
    listTypeData?.plaka_postarina_isprakac,
    listTypeData?.plaka_povraten_dokument_isprakac,
    setFieldValue,
  ]);

  return (
    <div className="p-fluid" style={{ maxWidth: 360 }}>
      <FieldWithErrors name="list_type" label={t('List Type')}>
        <Field
          inputId="list_type"
          name="list_type"
          as={Dropdown}
          filter
          options={listTypesOptions}
          disabled={listTypesData?.length === 0}
          placeholder={
            listTypesData?.length === 0
              ? t("You don't have any list types assigned")
              : ''
          }
          className="data-cy-listtype"
        />
      </FieldWithErrors>
    </div>
  );
}

export default ListType;
