import { Checkbox } from 'primereact/checkbox';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback, useContext, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { usePhoneOptionsContext } from '../../../../../context/PhoneOptionsContext';
import {
  ShippingLocation,
  UnregisteredClientId,
} from '../../../../../types/api/orders';
import { LabelValue } from '../../../../../types/options';
import { ReduxState } from '../../../../../types/redux';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import { invalidPhoneNumberCharactersRegex } from '../../../../../utils/constants/phoneNumbers';
import { placeItemTemplate } from '../../../../../utils/helpers/misc';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../../utils/helpers/phoneNumbers';
import AutoCompleteInput from '../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import Step from '../../../../Stepper/Inline/Step';
import { CustomerRole, FormFields } from '../CreateEditRecreate.functions';
import StepsContext from '../StepsContext';
import Contact from './Fields/Contact';
import SaveContact from './Fields/SaveContact';

function Sender(): JSX.Element {
  const { t } = useTranslation();

  const {
    sender: {
      isPlacesDataLoading,
      placesOptions,
      isStreetsDataLoading,
      streetOptions,
      isContactDataLoading,
      contactData,
      reloadContactData,
      subsidiaries,
      hubOptions,
    },
  } = useContext(StepsContext);

  const userClientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state?.user.client_id
  );

  const { setValue } = useFormContext<FormFields>();

  const internal_shipment = useWatch<FormFields, 'internal_shipment'>({
    name: 'internal_shipment',
  });

  const customer_role = useWatch<FormFields, 'customer_role'>({
    name: 'customer_role',
  });

  const mesto_od_id = useWatch<FormFields, 'mesto_od_id'>({
    name: 'mesto_od_id',
  });

  const mesto_od_ime = useWatch<FormFields, 'mesto_od_ime'>({
    name: 'mesto_od_ime',
  });

  const ulica_od_id = useWatch<FormFields, 'ulica_od_id'>({
    name: 'ulica_od_id',
  });

  const ulica_od_ime = useWatch<FormFields, 'ulica_od_ime'>({
    name: 'ulica_od_ime',
  });

  const klient_do_id = useWatch<FormFields, 'klient_do_id'>({
    name: 'klient_do_id',
  });

  const mobilen_od_region = useWatch<FormFields, 'mobilen_od_region'>({
    name: 'mobilen_od_region',
  });

  const telefon_od_region = useWatch<FormFields, 'telefon_od_region'>({
    name: 'telefon_od_region',
  });

  const pickup_location_type_id = useWatch<
    FormFields,
    'pickup_location_type_id'
  >({
    name: 'pickup_location_type_id',
  });

  const handlePlaceFilterChange = useCallback(
    (value: string) => {
      setValue('mesto_od_ime', value);
    },
    [setValue]
  );

  const handlePlaceSelectionChange = useCallback(
    (value: string | null) => {
      setValue('mesto_od_id', value ?? '');
    },
    [setValue]
  );

  const handleStreetFilterChange = useCallback(
    (value: string) => {
      setValue('ulica_od_ime', value);
    },
    [setValue]
  );

  const handleStreetSelectionChange = useCallback(
    (value: string | null) => {
      setValue('ulica_od_id', value ?? '');
    },
    [setValue]
  );

  const subsidiaryOptions: LabelValue<number>[] =
    subsidiaries
      ?.filter((s) => s.id !== klient_do_id)
      .map((subsidiary) => {
        return { label: subsidiary.ime, value: subsidiary.id };
      }) ?? [];

  function handleSenderSubsidiaryChange(e: DropdownChangeParams) {
    const subsidiary = subsidiaries?.find((s) => {
      return s.id === e.value;
    });

    setValue('klient_od_id', subsidiary?.id ?? UnregisteredClientId.Sender);
    setValue('klient_od_ime', subsidiary?.ime ?? '');
    setValue('mesto_od_id', subsidiary?.mesto_id ?? '');
    setValue('mesto_od_ime', subsidiary?.place_name ?? '');
    setValue('mobilen_od', subsidiary?.mobilen ?? '');
    setValue('telefon_od', subsidiary?.telefon ?? '');
    setValue('senderInvoice', subsidiary?.faktura ?? 0);
    if (isStreetFreeInputAllowed) {
      setValue('adresa_od', subsidiary?.adresa ?? '');
    } else {
      setValue('ulica_od_id', subsidiary?.ulica_id ?? '');
      setValue('ulica_od_ime', subsidiary?.street_name ?? '');
      setValue('broj_od', subsidiary?.broj ?? '');
      setValue('vlez_od', subsidiary?.vlez ?? '');
      setValue('stan_od', subsidiary?.stan ?? '');
    }
  }

  const isHeadquarter = useMemo<boolean>(() => {
    const me = subsidiaries?.find((s) => s.id === userClientId);

    return !!me && me?.id === me?.direkcija_id;
  }, [subsidiaries, userClientId]);

  const isDropdownShown =
    internal_shipment ||
    (isHeadquarter && customer_role === CustomerRole.Sender);

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  return (
    <Step title={t('Sender')} className="p-fluid">
      {!isDropdownShown ? (
        <FieldWithErrors name="klient_od_ime" label={t('Sender Name')}>
          <Contact
            isOptionsLoading={isContactDataLoading}
            contactData={contactData}
            role={CustomerRole.Sender}
            reloadContactData={reloadContactData}
          />
        </FieldWithErrors>
      ) : (
        <FieldWithErrors name="klient_od_id" label={t('Sender Name')}>
          <Controller
            name="klient_od_id"
            render={({ field }) => (
              <Dropdown
                name="klient_od_id"
                inputId="klient_od_id"
                value={field.value}
                filter
                options={subsidiaryOptions}
                onChange={handleSenderSubsidiaryChange}
              />
            )}
          />
        </FieldWithErrors>
      )}

      <div className="p-grid">
        <FieldWithErrors
          className="p-col-12 p-md-6"
          name="mobilen_od"
          label={t('Mobile')}
        >
          <div className="p-inputgroup">
            <Controller
              name="mobilen_od_region"
              render={({ field }) => (
                <Dropdown
                  style={{ padding: 0, width: '5rem' }}
                  id="mobilen_od_region"
                  options={languages}
                  itemTemplate={countryOptionTemplate}
                  valueTemplate={selectedCountryTemplate}
                  data-cy="mobilen_od_region"
                  {...field}
                />
              )}
            />
            <Controller
              name="mobilen_od"
              render={({ field }) => (
                <InputText
                  name="mobilen_od"
                  style={{ width: '100%' }}
                  value={formatMobileNumber(
                    field.value,
                    mobilen_od_region ||
                      getPhoneorMobileNumberRegion(field.value)
                  )}
                  onChange={(e) => {
                    field.onChange(
                      e.target.value?.replace(
                        invalidPhoneNumberCharactersRegex,
                        ''
                      )
                    );
                  }}
                  className="data-cy-mobile"
                />
              )}
            />
          </div>
        </FieldWithErrors>

        <FieldWithErrors
          className="p-col-12 p-md-6"
          name="telefon_od"
          label={t('Phone')}
        >
          <div className="p-inputgroup">
            <Controller
              name="telefon_od_region"
              render={({ field }) => (
                <Dropdown
                  className="telefon_od_region"
                  style={{ padding: 0, width: '5rem' }}
                  id="telefon_od_region"
                  options={languages}
                  itemTemplate={countryOptionTemplate}
                  valueTemplate={selectedCountryTemplate}
                  data-cy="telefon_od_region"
                  {...field}
                />
              )}
            />
            <Controller
              name="telefon_od"
              render={({ field }) => (
                <InputText
                  name="telefon_od"
                  style={{ width: '100%' }}
                  value={formatPhoneNumber(
                    field.value,
                    telefon_od_region ||
                      getPhoneorMobileNumberRegion(field.value)
                  )}
                  onChange={(e) => {
                    field.onChange(
                      e.target.value?.replace(
                        invalidPhoneNumberCharactersRegex,
                        ''
                      )
                    );
                  }}
                  className="data-cy-phone"
                />
              )}
            />
          </div>
        </FieldWithErrors>
      </div>

      <FieldWithErrors name="mesto_od_id" label={t('Place')}>
        <Controller
          name="mesto_od_id"
          render={({ field }) => (
            <AutoCompleteInput
              filterValue={mesto_od_ime}
              value={field.value}
              onFilterChange={handlePlaceFilterChange}
              onSelectionChange={handlePlaceSelectionChange}
              options={placesOptions}
              itemTemplate={placeItemTemplate}
              valueTemplate={placeItemTemplate}
              placeholder={isPlacesDataLoading ? t('Loading...') : ''}
              filterDataCy="place"
              optionsClassName="data-cy-place-options"
            />
          )}
        />
      </FieldWithErrors>
      {isStreetFreeInputAllowed ? (
        <FieldWithErrors name="adresa_od" label={t('Address')}>
          <Controller
            name="adresa_od"
            render={({ field }) => (
              <InputText {...field} disabled={!mesto_od_id} maxLength={256} />
            )}
          />
        </FieldWithErrors>
      ) : (
        <>
          {' '}
          <FieldWithErrors name="ulica_od_id" label={t('Street')}>
            <Controller
              name="ulica_od_id"
              render={({ field }) => (
                <AutoCompleteInput
                  lazy={false}
                  name="ulica_od_id"
                  id="ulica_od_id"
                  filterValue={ulica_od_ime ?? ''}
                  value={field.value}
                  onFilterChange={handleStreetFilterChange}
                  onSelectionChange={handleStreetSelectionChange}
                  options={streetOptions}
                  disabled={!mesto_od_id || isStreetsDataLoading}
                  placeholder={isStreetsDataLoading ? 'Loading...' : ''}
                  filterDataCy="street"
                  optionsClassName="data-cy-street"
                />
              )}
            />
          </FieldWithErrors>
          <div className="p-grid">
            <div className="p-col-4">
              <FieldWithErrors name="broj_od" label={t('Street No.')}>
                <Controller
                  name="broj_od"
                  render={({ field }) => (
                    <InputText
                      id="broj_od"
                      name="broj_od"
                      value={field.value}
                      disabled={!ulica_od_id}
                      onChange={field.onChange}
                      className="data-cy-street_no"
                    />
                  )}
                />
              </FieldWithErrors>
            </div>

            <div className="p-col-4">
              <FieldWithErrors name="vlez_od" label={t('Entrance No.')}>
                <Controller
                  name="vlez_od"
                  render={({ field }) => (
                    <InputText
                      id="vlez_od"
                      name="vlez_od"
                      value={field.value}
                      disabled={!ulica_od_id}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FieldWithErrors>
            </div>

            <div className="p-col-4">
              <FieldWithErrors name="stan_od" label={t('Flat No.')}>
                <Controller
                  name="stan_od"
                  render={({ field }) => (
                    <InputText
                      id="stan_od"
                      name="stan_od"
                      value={field.value}
                      disabled={!ulica_od_id}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FieldWithErrors>
            </div>
          </div>
        </>
      )}

      <FieldWithErrors
        name="pickup_location_type_id"
        label={t('Handover in warehouse')}
      >
        <div className="p-d-flex p-jc-around p-ai-center">
          <div className="p-col-1">
            <Controller
              name="pickup_location_type_id"
              render={({ field }) => (
                <Checkbox
                  inputId="pickup_location_type_id"
                  name="pickup_location_type_id"
                  value={field.value}
                  checked={field.value === ShippingLocation.Hub}
                  onChange={(e) => {
                    field.onChange(
                      e.checked
                        ? ShippingLocation.Hub
                        : ShippingLocation.HomeAddress
                    );
                  }}
                  className="data-cy-deliver_to_hub"
                />
              )}
            />
          </div>

          <div className="p-col">
            <Controller
              name="hub_od_id"
              render={({ field }) => (
                <Dropdown
                  inputId="hub_od_id"
                  name="hub_od_id"
                  value={String(field.value)}
                  options={hubOptions}
                  filter
                  disabled={pickup_location_type_id !== ShippingLocation.Hub}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </div>
      </FieldWithErrors>
      {!isDropdownShown && <SaveContact isSender />}
    </Step>
  );
}

export default Sender;
