import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import HeadlessStepperContext from '../HeadlessStepperContext';

export type HeaderProps = {
  title: string;
  isStepIndicatorShown?: boolean;
};
function Header({ title, isStepIndicatorShown }: HeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { totalSteps, stepIndex } = useContext(HeadlessStepperContext);

  return (
    <>
      {title}

      <span
        className={classNames('step-indicator', {
          'p-d-none': !isStepIndicatorShown,
        })}
      >
        {t('Step')} {stepIndex + 1} {t('of')} {totalSteps}
      </span>
    </>
  );
}

export default Header;
