import classNames from 'classnames';
import { HTMLProps, ReactNode } from 'react';

type Props = HTMLProps<HTMLDivElement> & {
  title?: string;
  children: ReactNode;
  childrenWrapperClassName?: string;
  subtitle?: string;
  fullWidth?: boolean;
};

function Step({
  title,
  subtitle,
  children,
  childrenWrapperClassName,
  fullWidth = true,
  ...other
}: Props): JSX.Element {
  return (
    <div className={classNames('step', { 'full-width': fullWidth })} {...other}>
      <div className="p-fluid">
        <div className="title-and-subtitle-section">
          <span className="section-title">{title}</span>
          {subtitle && <span className="section-subtitle">{subtitle}</span>}
        </div>
        <div className={childrenWrapperClassName}>{children}</div>
      </div>
    </div>
  );
}

export default Step;
