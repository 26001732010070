import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useMediaQuery from '../../hooks/useMediaQuery';
import { ReduxState } from '../../types/redux';

function HeaderMenu(): JSX.Element {
  const { t } = useTranslation();
  const headerMenu = useRef<any>(null);
  const history = useHistory();
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user,
    shallowEqual
  );

  const splitBtnMenuModel: MenuItem[] = [
    {
      label: t('Profile'),
      icon: 'fas fa-address-card',
      command: () => history.push('/profile'),
    },
    {
      label: t('Email Configuration'),
      icon: 'fas fa-envelope-open-text',
      command: () => history.push('/email-configuration'),
    },
    {
      label: t('Notifications'),
      icon: 'fas fa-bell',
      command: () => history.push('/notifications'),
    },
    {
      label: t('Acceptance code'),
      icon: 'fas fa-qrcode',
      command: () => history.push('/qr-code'),
    },
    {
      label: t('Log out'),
      icon: 'fas fa-sign-out-alt',
      command: () => history.push('/logout'),
    },
  ];

  return (
    <div className="cart flex justify-content-center">
      <Menu model={splitBtnMenuModel} popup ref={headerMenu} id="header_menu" />
      <Button
        icon="fas fa-user-alt"
        label={`${!isOnMobile ? user.client_name : ''}`}
        onClick={(event) => headerMenu.current?.toggle(event)}
        aria-controls="header_menu"
        aria-haspopup
        className="p-button-outlined menu-button"
      />
    </div>
  );
}

export default HeaderMenu;
