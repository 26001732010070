import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../context/ToastContext';
import { AtLeastOne } from '../types/util';
import { errorToast, successToast } from '../utils/helpers/primereact';
import usePrevious from './usePrevious';

type Option = {
  title?: string;
  summary: string;
  callback?: () => void;
};

export type UseToastMessageOptions = AtLeastOne<{
  success: Option;
  error: Option;
}>;

function useToastMessage<D, E>(
  data: D | undefined,
  error: E | undefined,
  options: UseToastMessageOptions
) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const previousData = usePrevious(data);
  const previousError = usePrevious(error);

  useEffect(() => {
    if (!data || data === previousData || !options.success) {
      return;
    }

    options.success.callback?.();

    successToast(
      toastRef,
      options.success.title ?? t('Success'),
      options.success.summary
    );
  }, [data, options.success, previousData, t, toastRef]);

  useEffect(() => {
    if (!error || error === previousError || !options.error) {
      return;
    }

    options.error.callback?.();

    errorToast(
      toastRef,
      options.error.title ?? t('Error'),
      options.error.summary
    );
  }, [error, options.error, previousError, t, toastRef]);
}

export default useToastMessage;
