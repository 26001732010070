import dayjs from 'dayjs';
import { $enum } from 'ts-enum-util';

export function tryInt(value: any): number | undefined {
  let parsedValue = NaN;

  if (typeof value === 'number') {
    parsedValue = value;
  } else if (typeof value === 'string') {
    parsedValue = parseInt(value);
  } else if (typeof value === 'boolean') {
    parsedValue = value === true ? 1 : 0;
  }

  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function tryEnumValue<
  T extends Record<Extract<keyof T, string>, string | number>
>(value: any, enumOptions: T) {
  return $enum(enumOptions).isValue(value) ? value : undefined;
}

export function tryFloat(value: any): number | undefined {
  let parsedValue = NaN;

  if (typeof value === 'number') {
    parsedValue = value;
  } else if (typeof value === 'string') {
    parsedValue = parseFloat(value);
  } else if (typeof value === 'boolean') {
    parsedValue = value === true ? 1 : 0;
  }

  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function tryString(value: any): string | undefined {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number' && !isNaN(value)) {
    return String(value);
  }

  return undefined;
}

export function tryArray(value: any): any[] | undefined {
  if (Array.isArray(value)) {
    return value;
  }

  return undefined;
}

export function tryIsoDate(str: string): Date | undefined {
  const parsed = Date.parse(str);

  return isNaN(parsed) ? undefined : new Date(parsed);
}

export function tryTimeRange(
  value: any,
  asDateTuple?: boolean
): string | [Date, Date] | undefined {
  if (typeof value !== 'string' || value.split('-').length !== 2) {
    return undefined;
  }

  const timeRangeRegex = /^(?:(?:0|1)?[0-9]|2[0-3]):(?:[0-5][0-9])$/;

  const timeArr = value.split('-');
  const timeFrom = timeArr[0];
  const timeFromArr = timeFrom.split(':');
  const timeTo = timeArr[1];
  const timeToArr = timeTo.split(':');

  if (!timeRangeRegex.test(timeFrom) || !timeRangeRegex.test(timeTo)) {
    return undefined;
  }

  const from = dayjs()
    .set('hours', parseInt(timeFromArr[0]))
    .set('minutes', parseInt(timeFromArr[1]));

  const to = dayjs()
    .set('hours', parseInt(timeToArr[0]))
    .set('minutes', parseInt(timeToArr[1]));

  if (from.isAfter(to)) {
    return undefined;
  }

  return asDateTuple ? [from.toDate(), to.toDate()] : value;
}

export function tryStringify<T>(obj: T): string {
  let str = '';

  try {
    str = JSON.stringify(obj);
  } catch (e) {}

  return str;
}

export function tryParse<T>(value: string | null): T | undefined {
  return value === 'undefined' ? undefined : JSON.parse(value ?? '');
}
