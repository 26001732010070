import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function renderIcon(icon: string | IconDefinition) {
  if (typeof icon === 'string') {
    return <img className={icon} alt="" />;
  }

  return <FontAwesomeIcon icon={icon} />;
}
