import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EmailAction, EmailRecipient } from '../../../enums/email';

type Props = {
  emailAction: EmailAction;
  emailRecipient: EmailRecipient;
};

function Fields({ emailAction, emailRecipient }: Props): JSX.Element {
  const { t } = useTranslation();

  const name = `email_configuration.${emailAction}.${emailRecipient}`;

  const { fields, remove } = useFieldArray({
    name,
  });

  return (
    <>
      {fields.length ? (
        fields.map((field, idx) => (
          <div className="p-mb-2 p-fluid p-d-flex p-ai-baseline" key={field.id}>
            <span style={{ width: 35 }}>{`${idx + 1}.`}</span>

            <Controller
              name={`${name}.${idx}.email`}
              render={({ field }) => (
                <InputText id={field.name} {...field} className="p-mx-2" />
              )}
            />

            <Button
              type="button"
              icon="fas fa-trash"
              onClick={() => remove(idx)}
              className="p-button-secondary p-button-text"
            />
          </div>
        ))
      ) : (
        <p>
          <i>{t('No emails are added yet.')}</i>
        </p>
      )}
    </>
  );
}

export default Fields;
