import { TFunction } from 'react-i18next';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';

import { EmailAction, EmailRecipient } from '../../../enums/email';
import {
  ClientResource,
  ClientSelfUpdateRequestPayload,
} from '../../../types/api/clients';
import { isEmail } from '../../../utils/helpers/validation';
import { FormFields } from './EmailConfiguration.types';

export function getDefaultValues({
  clientResource,
}: {
  clientResource: ClientResource | undefined;
}): FormFields {
  const email_configuration = {} as FormFields['email_configuration'];

  const emailActionEnumValues = $enum(EmailAction).getValues();
  const emailRecipientEnumValues = $enum(EmailRecipient).getValues();

  for (const action of emailActionEnumValues) {
    if (!email_configuration[action]) {
      email_configuration[action] =
        {} as FormFields['email_configuration'][typeof action];
    }

    for (const recipient of emailRecipientEnumValues) {
      email_configuration[action][recipient] = (
        clientResource?.email_configuration?.[action]?.[recipient] ?? []
      ).map((email) => ({ email }));
    }
  }

  return { email_configuration };
}

export function toApiData(values: FormFields): ClientSelfUpdateRequestPayload {
  const email_configuration = {} as NonNullable<
    ClientSelfUpdateRequestPayload['email_configuration']
  >;

  const emailActionEnumValues = $enum(EmailAction).getValues();
  const emailRecipientEnumValues = $enum(EmailRecipient).getValues();

  for (const action of emailActionEnumValues) {
    if (!email_configuration[action]) {
      email_configuration[action] = {} as NonNullable<
        ClientSelfUpdateRequestPayload['email_configuration']
      >[typeof action];
    }

    for (const recipient of emailRecipientEnumValues) {
      email_configuration[action][recipient] = values.email_configuration[
        action
      ][recipient].map((field) => field.email);
    }
  }

  return { email_configuration };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    email_configuration: Yup.object()
      .required()
      .test(
        'email_configuration',
        t('Invalid email address(es) entered!'),
        (value) => {
          let emailAddresses = [];

          const emailActionEnumValues = $enum(EmailAction).getValues();
          const emailRecipientEnumValues = $enum(EmailRecipient).getValues();

          for (const action of emailActionEnumValues) {
            for (const recipient of emailRecipientEnumValues) {
              emailAddresses.push(
                ...(value as any as FormFields['email_configuration'])[action][
                  recipient
                ]
              );
            }
          }

          return emailAddresses.every((field) => isEmail(field.email));
        }
      ),
  });
}
