import * as Sentry from '@sentry/react';

import usePrevious from './usePrevious';

function useHaveValuesChanged(...values: any[]): boolean {
  const prevValues = usePrevious(values);

  try {
    return values === prevValues
      ? false
      : JSON.stringify(values) !== JSON.stringify(prevValues);
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        data: values,
      },
    });

    return false;
  }
}

export default useHaveValuesChanged;
