import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';

function ReferenceNo2(): JSX.Element {
  const { t } = useTranslation();

  return (
    <FieldWithErrors name="reference2" label={t('Reference No. 2')}>
      <Controller
        name="reference2"
        render={({ field }) => (
          <InputText
            id="reference2"
            name="reference2"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default ReferenceNo2;
