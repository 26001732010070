import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OrderType } from '../../../../../../../types/api/orders';
import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import {
  FallbackValues,
  FormFields,
} from '../../../CreateEditRecreate.functions';
import StepsContext from '../../../StepsContext';

function InsuredAmount(): JSX.Element {
  const { t } = useTranslation();

  const {
    orderSettings: { action },
  } = useContext(StepsContext);

  const isEditing = action === 'edit';

  const { setValue } = useFormContext<FormFields>();

  const proizvod_id = useWatch<FormFields, 'proizvod_id'>({
    name: 'proizvod_id',
  });

  const otkup = useWatch<FormFields, 'otkup'>({
    name: 'otkup',
  });

  const isRedemptionFilledOut = !!otkup && parseFloat(otkup) > 0;

  const isInsuredAmountDisabled =
    proizvod_id === OrderType.Package && isRedemptionFilledOut;

  const tooltip = t(
    'Only one of redemption or insured amount can be present at a time.'
  );

  return (
    <>
      <Tooltip
        target=".disabled-insured-amount-tooltip"
        position="left"
        style={isInsuredAmountDisabled ? {} : { display: 'none' }}
      />

      <FieldWithErrors name="vrednost" label={t('Insured amount')}>
        <div
          className="disabled-insured-amount-tooltip"
          data-pr-tooltip={tooltip}
        >
          <Controller
            name="vrednost"
            render={({ field }) => (
              <InputText
                id="vrednost"
                name="vrednost"
                value={field.value}
                disabled={isInsuredAmountDisabled}
                onChange={(e) => {
                  const value = e.target.value.replace(
                    invalidDecimalPointCharactersRegex,
                    ''
                  );

                  setValue('vrednost', value);

                  // API's default value for 'vrednost' is "0.00",
                  //  but during order creation it's an empty string (for the sake of UX).
                  //  So, if 'vrednost' is reset and then set to its default value,
                  //  not resetting 'otkup' properly might result in a false-negative
                  //  _.isEqual(objDiff(...)) upon form submissions
                  if (parseFloat(value) > 0) {
                    setValue(
                      'otkup',
                      isEditing ? FallbackValues.Redemption : ''
                    );
                  }
                }}
                className="data-cy-insured_amount"
              />
            )}
          />
        </div>
      </FieldWithErrors>
    </>
  );
}

export default InsuredAmount;
