// File reserved for functional programing concepts only.
export const noop = () => {};

export function unary<T, R>(fn: (arg: T) => R): (arg: T) => R {
  function wrapper(arg: T) {
    return fn(arg);
  }

  return wrapper;
}
