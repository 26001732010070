import './ErrorPage.scss';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import illustration500 from '../../assets/svg/500-internal-server-error-bro.svg';

function Page500(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="page-500">
      <div className="error-container p-shadow-1">
        <h1>{t('Houston, we have a problem!')}</h1>
        <p>
          {t('The page you are looking for is temporarily unavailable')}.
          <br />
          {t(
            'This should be fixed anytime soon. Contact us if the problem persists'
          )}
          .
        </p>

        <img src={illustration500} alt="" />

        <p>
          {t('Try going to the')} <Link to="/">{t('home page')}</Link>.
        </p>
      </div>
    </div>
  );
}

export default Page500;
