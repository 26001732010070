import { useEffect, useState } from 'react';

function useMediaQuery(query: string): boolean {
  const [isMatching, setIsMatching] = useState<boolean>(
    window.matchMedia(query).matches
  );

  function mediaQueryListener(e: MediaQueryListEvent) {
    setIsMatching(e.matches);
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    // Compatibility with older browsers
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', mediaQueryListener);
    } else if (mediaQuery.addListener) {
      mediaQuery.addListener(mediaQueryListener);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', mediaQueryListener);
      } else if (mediaQuery.removeListener) {
        mediaQuery.removeListener(mediaQueryListener);
      }
    };
  }, [query]);

  return isMatching;
}

export default useMediaQuery;
