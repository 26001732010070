export const debounceTimeout = 250;
export const halfSecundDebounceTimeout = 500;
export const secundDebounceTimeout = 1000;

export const isDevEnv =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_CLIENT_NAME === 'koder';

export const isStreetFreeInputAllowed =
  typeof process.env.REACT_APP_STREET_FREE_INPUT === 'string' &&
  !!parseInt(process.env.REACT_APP_STREET_FREE_INPUT);
