import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../context/ToastContext';
import useIsMounted from '../../../hooks/useIsMounted';
import { warnToast } from '../../../utils/helpers/primereact';

function IndexedDBNotifier() {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { toastRef } = useContext(ToastContext);

  useEffect(() => {
    if (!window.indexedDB && isMounted.current) {
      warnToast(
        toastRef,
        t('IndexedDB is disabled!'),
        t(
          'For a better user experience please enable IndexedDB in your browser settings'
        )
      );
    }
  }, [toastRef, t, isMounted]);

  return null;
}

export default IndexedDBNotifier;
