// Data Table
export function getDataTableProps(t) {
  return {
    emptyMessage: t("Your filters didn't match any records"),
    emptyMessageError: t(
      'An error occured. Please make sure you are connected to the internet and try again'
    ),
    paginatorTemplate:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    pageReportTemplate: t(
      'Showing {first} to {last} of {totalRecords} entries'
    ),
    emptyPageReportTemplate: t('Showing 0 of 0 entries'),
  };
}
