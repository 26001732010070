import OrderSettings from './OrderSettings';
import Payments from './Payments';

function SettingsAndPayments() {
  return (
    <div className="settings-payments">
      <OrderSettings />
      <Payments />
    </div>
  );
}

export default SettingsAndPayments;
