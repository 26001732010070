import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { yupRequiredField } from '../../../../utils/helpers/yup';
import { FormFields } from './ForgotPassword.types';

export const initialFormValues = {
  email: '',
};

export const getValidationSchema = (t: TFunction): Yup.SchemaOf<FormFields> => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('Invalid email address entered!'))
      .required(yupRequiredField(t, t('Email'))),
  });
};
