import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../context/ToastContext';
import { OrderStatus } from '../../../../../enums/orders';
import useAxios from '../../../../../hooks/useAxios';
import usePrevious from '../../../../../hooks/usePrevious';
import { OrderCollection } from '../../../../../types/api/orders';
import { Numeric } from '../../../../../types/general';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import { generateErrorMsg, toApiData } from './CancelOrderDialog.functions';

type Props = {
  visible: boolean;
  data: OrderCollection['data'];
  onHide: () => void;
  reloadOrders: () => void;
  setSelectionMultiple: Dispatch<SetStateAction<any[]>>;
};

function CancelOrderDialog({
  visible,
  data,
  onHide,
  reloadOrders,
  setSelectionMultiple,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const {
    data: deleteData,
    error: deleteError,
    reload: deleteReload,
    isLoading: isDeleteLoading,
  } = useAxios<undefined, { error?: string }>();

  function handleCancelOrder(shipmentId: Numeric) {
    if (!shipmentId) {
      return;
    }

    deleteReload({ url: `orders/${shipmentId}`, method: 'DELETE' });
  }

  const prevDeleteData = usePrevious(deleteData);
  const prevDeleteError = usePrevious(deleteError);

  useEffect(() => {
    if (!deleteData || deleteData === prevDeleteData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        data.length > 1
          ? t('Orders successfully cancelled.')
          : t('Order {{serialNo}} was successfully cancelled.', {
              serialNo: data[0]?.seriski_broj ?? '',
            })
      );
    }

    onHide();
    reloadOrders();
    setSelectionMultiple([]);
  }, [
    data,
    deleteData,
    onHide,
    prevDeleteData,
    reloadOrders,
    setSelectionMultiple,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError) {
      return;
    }

    const errText = deleteError?.response?.data?.error;

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        data.length > 1
          ? t('Failed group cancelling. {{errorMessage}}', {
              errorMessage: errText ? generateErrorMsg(t, errText) : '',
            })
          : t('Failed order {{serialNo}} cancelling. {{errorMessage}}.', {
              serialNo: data[0]?.seriski_broj ?? '',
              errorMessage: errText ? generateErrorMsg(t, errText) : '',
            })
      );
    }

    onHide();
  }, [data, deleteError, onHide, prevDeleteError, t, toastRef]);

  function handleDeleteSubmision() {
    if (!data.length) {
      return;
    }
    const cancellableData = toApiData(data);

    cancellableData.forEach((o) => handleCancelOrder(o.id));
  }

  const dialogFooter = (
    <div className="p-pt-4">
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={isDeleteLoading ? t('Cancelling...') : t('Cancel')}
        disabled={isDeleteLoading}
        onClick={() => {
          handleDeleteSubmision();
        }}
        className="p-button-danger"
      />
    </div>
  );

  const nonDeletableOrders = useMemo(
    () => data.filter((o) => o?.status_id >= OrderStatus.Cancelled),
    [data]
  );

  const deletableOrders = useMemo(
    () => data.filter((o) => o?.status_id < OrderStatus.Cancelled),
    [data]
  );

  const nonDeletableOrdersSerialNos = nonDeletableOrders.map(
    (o) => ` ${o.seriski_broj};`
  );

  return (
    <Dialog
      header={t('Cancel order')}
      footer={dialogFooter}
      visible={visible}
      resizable={false}
      onHide={onHide}
      style={{ maxWidth: '480px' }}
    >
      {nonDeletableOrders.length > 0 && deletableOrders.length > 0 && (
        <p>
          <b className="p-mt-0" style={{ color: 'var(--yellow-500)' }}>
            {t('WARNING')}: {t('The following orders cannot be cancelled')}
            {nonDeletableOrdersSerialNos}
            <br />
            {t(' You can still cancel the rest.')}
          </b>
        </p>
      )}

      <p>
        {deletableOrders.length > 1 ? (
          t('Are you sure you want to cancel this group of orders?')
        ) : (
          <>
            {t('Are you sure you want to cancel the following order?')}{' '}
            {data?.map((o) => (
              <b key={o.id}>{o?.seriski_broj}</b>
            ))}
          </>
        )}
      </p>
      <p>{t('This action cannot be undone.')}</p>
    </Dialog>
  );
}

export default CancelOrderDialog;
