import {
  PhoneNumberFormat,
  PhoneNumberType,
  PhoneNumberUtil,
} from 'google-libphonenumber';
import { $enum } from 'ts-enum-util';

import { CountryPhone } from '../../enums/countries';

export function formatPhoneOrMobileNumber(number: string): string {
  const region = getPhoneorMobileNumberRegion(number);

  return isValidPhoneNumber(number, region)
    ? formatPhoneNumber(number, region)
    : isValidMobileNumber(number, region)
    ? formatMobileNumber(number, region)
    : number;
}

export function isValidPhoneNumber(
  value: string,
  phoneRegion: string
): boolean {
  if (!value) {
    return true;
  }
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, phoneRegion);

    if (!phoneUtil.isPossibleNumber(phoneNumber)) {
      return false;
    }

    return (
      phoneUtil.isValidNumberForRegion(phoneNumber, phoneRegion) &&
      phoneUtil.getNumberType(phoneNumber) === PhoneNumberType.FIXED_LINE
    );
  } catch {
    return false;
  }
}

export function isValidMobileNumber(
  value: string,
  phoneRegion: string
): boolean {
  if (!value) {
    return true;
  }
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    const mobileNumber = phoneUtil.parseAndKeepRawInput(value, phoneRegion);

    if (!phoneUtil.isPossibleNumber(mobileNumber)) {
      return false;
    }

    return (
      phoneUtil.isValidNumberForRegion(mobileNumber, phoneRegion) &&
      phoneUtil.getNumberType(mobileNumber) === PhoneNumberType.MOBILE
    );
  } catch {
    return false;
  }
}

export function formatPhoneNumber(
  phoneNumber: string,
  phoneRegion: CountryPhone
): string {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    if (isValidPhoneNumber(phoneNumber, phoneRegion)) {
      return phoneUtil.format(
        phoneUtil.parse(phoneNumber, phoneRegion),
        PhoneNumberFormat.INTERNATIONAL
      );
    }

    return phoneNumber;
  } catch {
    return phoneNumber;
  }
}

export function formatMobileNumber(
  mobileNumber: string,
  mobileRegion: CountryPhone
): string {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    if (isValidMobileNumber(mobileNumber, mobileRegion)) {
      return phoneUtil.format(
        phoneUtil.parse(mobileNumber, mobileRegion),
        PhoneNumberFormat.INTERNATIONAL
      );
    }

    return mobileNumber;
  } catch {
    return mobileNumber;
  }
}

export function getSupportedCountriesForPhoneNumbers(): CountryPhone[] {
  const supportedRegions = PhoneNumberUtil.getInstance().getSupportedRegions();

  const appSupportedRegions = $enum(CountryPhone)
    .getValues()
    .filter((region) =>
      supportedRegions.includes(region.toUpperCase())
    ) as CountryPhone[];

  return appSupportedRegions;
}

export function getPhoneorMobileNumberRegion(
  phone: string | null | undefined
): CountryPhone {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const defaultLanguageRegion = $enum(CountryPhone).asValueOrDefault(
    process.env.REACT_APP_COUNTRY,
    CountryPhone.Macedonia
  );

  if (phone) {
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
      if (!phoneUtil.isPossibleNumber(phoneNumber)) {
        return defaultLanguageRegion;
      }

      const region = phoneUtil.getRegionCodeForNumber(phoneNumber);
      if ($enum(CountryPhone).isValue(region)) {
        return region;
      }

      throw new Error('Unsupported phone region');
    } catch {
      return defaultLanguageRegion;
    }
  }

  return defaultLanguageRegion;
}
