import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { UserPasswordRequestPayload } from '../../../../types/api/users';
import { yupRequiredPasswordField } from '../../../../utils/helpers/yup';
import { FormFields } from './ResetPassword.types';

export const initialFormValues: FormFields = {
  newPassword: '',
};

export function getValidationSchema(t: TFunction): Yup.SchemaOf<FormFields> {
  return Yup.object().shape({
    newPassword: yupRequiredPasswordField(t, 'New Password'),
  });
}

export function toApiData(
  code: string,
  values: FormFields
): UserPasswordRequestPayload {
  return {
    code,
    password: values.newPassword,
    repassword: values.newPassword,
  };
}
