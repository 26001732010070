import { faList } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'primereact/column';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { ClientImportListTypeCollection } from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { Unpacked } from '../../../types/util';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Table from '../../DataTable/Table/Table';
import Flex from '../../Layout/flex/Flex';
import MainContent from '../../Layout/flex/MainContent';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './BulkOrderTemplates.functions';
import useTableFilters from './useTableFilters';

function BulkOrderTemplates(): JSX.Element {
  const { t } = useTranslation();
  usePageTitle(t('Batch Order Templates'));
  const { bottomRightToastRef } = useContext(ToastContext);
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const {
    tableRef,
    page,
    limit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Unpacked<ClientImportListTypeCollection>>(tableStorageKey);

  const [contextMenuSelection, setContextMenuSelection] = useState<
    Unpacked<ClientImportListTypeCollection> | undefined
  >(undefined);
  const [action, setAction] = useState<string>('');
  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(page, limit, columnHeadersMap, columnHeadersMap, (c) =>
      additionalColumnProperties(
        t,
        c as keyof typeof columnHeadersMap,
        setContextMenuSelection,
        setAction
      )
    );

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters();

  const clientID = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state?.user?.client_id
  );

  const { data, error, isLoading, reload } =
    useAxios<ClientImportListTypeCollection>(
      `/clients/${clientID}/importlists/types`
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction
        )}
      />,
    ],
    [columns, setContextMenuSelection, t]
  );

  const handleCmExportToCSVClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/importlisttypes/${contextMenuSelection.id}/export/csv`,
      getFileName(t('BatchOrderTemplates'), contextMenuSelection.name),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  const handleCmExportToExcelClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    downloadFile(
      `/importlisttypes/${contextMenuSelection.id}/export/excel`,
      getFileName(t('BatchOrderTemplates'), contextMenuSelection.name),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }, [contextMenuSelection, t, bottomRightToastRef]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'export-to-excel') {
        handleCmExportToExcelClick();
      }
      if (action === 'export-to-cvs') {
        handleCmExportToCSVClick();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    handleCmExportToExcelClick,
    handleCmExportToCSVClick,
  ]);

  const dataToDisplay = useMemo(
    () =>
      data?.filter((item) =>
        item.name.toLowerCase().startsWith(httpFiltersObj.name.toLowerCase())
      ),
    [data, httpFiltersObj.name]
  );

  return (
    <div className="page list-types">
      <HeaderPages
        title={t('Batch Order Templates')}
        subtitle={t('View assigned batch order templates.')}
        icon={faList}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={160}
        />
        <MainContent>
          <Table
            headerTitle=""
            columnOptions={columnOptions}
            columns={finalColumns}
            data={dataToDisplay}
            hasError={!!error}
            isLoading={isLoading}
            rebuildTooltip
            ref={tableRef}
            reload={reload}
            rows={limit}
            filterHeight={160}
            selectedColumns={selectedColumns}
            selection={selection}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelection}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            lazy={false}
            displayActionColumn
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default BulkOrderTemplates;
