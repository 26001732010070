import { PhoneNumberUtil } from 'google-libphonenumber';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { LabelValue } from '../types/options';
import { renderIcon } from '../utils/helpers/icon';
import { getSupportedCountriesForPhoneNumbers } from '../utils/helpers/phoneNumbers';

type Context = {
  languages?: {
    label: number;
    value: string;
  }[];
  selectedCountryTemplate?: ReactNode;
  countryOptionTemplate?: ReactNode;
};

type ProviderProps = {
  children: ReactNode;
};

const PhoneOptionsContext = createContext<Context>({});

const phoneUtil = PhoneNumberUtil.getInstance();

function PhoneOptionsContextProvider({ children }: ProviderProps): JSX.Element {
  const selectedCountryTemplate = useCallback(
    (option: LabelValue<string>, props: { placeholder: string }) => {
      if (option) {
        return (
          <div className="country-item country-item-value p-d-flex">
            {renderIcon(`flag ${option.value}-flag`)}
          </div>
        );
      }

      return <span>{props.placeholder}</span>;
    },
    []
  );

  const countryOptionTemplate = useCallback((option: LabelValue<string>) => {
    return (
      <div className="country-item">
        {renderIcon(`flag ${option.value}-flag`)}
        <div style={{ display: 'inline', padding: '1rem' }}>
          {`+${option.label}`}
        </div>
      </div>
    );
  }, []);

  const languages = useMemo(
    () =>
      getSupportedCountriesForPhoneNumbers().map((country) => {
        return {
          label: phoneUtil.getCountryCodeForRegion(country),
          value: String(country),
        };
      }),
    []
  );

  return (
    <PhoneOptionsContext.Provider
      value={{ languages, selectedCountryTemplate, countryOptionTemplate }}
    >
      {children}
    </PhoneOptionsContext.Provider>
  );
}

function usePhoneOptionsContext() {
  const context = useContext(PhoneOptionsContext);

  if (!context) {
    throw new Error(
      'usePhoneOptionsContext must be used within the PhoneProvider'
    );
  }

  return context;
}

export { PhoneOptionsContextProvider, usePhoneOptionsContext };
