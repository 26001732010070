import './Stepper.scss';

import classNames from 'classnames';
import { HTMLProps } from 'react';

import HeadlessStepper, { HeadlessStepperProps } from '../HeadlessStepper';
import Content from './Content';
import Footer, { FooterProps } from './Footer';
import Header, { HeaderProps } from './Header';

type Props = Omit<HeadlessStepperProps, 'children'> &
  Omit<HeaderProps, 'isExpanded' | 'setIsExpanded'> &
  FooterProps &
  Pick<HTMLProps<HTMLDivElement>, 'style' | 'className'> & {
    maxUnexpandedWidth?: number;
  };

function Stepper({
  // Header
  title,
  isStepIndicatorShown,
  // Footer
  onCancelBtnClick,
  backButtonProps,
  nextButtonProps,
  cancelButtonProps,
  submitBtnLabel,
  // Own
  maxUnexpandedWidth = 720,
  style,
  className,
  ...rest
}: Props): JSX.Element {
  return (
    <HeadlessStepper {...rest}>
      <div className={classNames('inline-stepper', className)}>
        <Header title={title} />
        <Content />
        <Footer
          isStepIndicatorShown={isStepIndicatorShown}
          onCancelBtnClick={onCancelBtnClick}
          backButtonProps={backButtonProps}
          nextButtonProps={nextButtonProps}
          cancelButtonProps={cancelButtonProps}
          submitBtnLabel={submitBtnLabel}
        />
      </div>
    </HeadlessStepper>
  );
}

export default Stepper;
