import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'primereact/column';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPagination } from '../../../types/api';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import { queryString } from '../../../utils/helpers/http';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Table from '../../DataTable/Table/Table';
import Flex from '../../Layout/flex/Flex';
import MainContent from '../../Layout/flex/MainContent';
import useTableFilters from './useTableFilters';
import {
  COD,
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './Warrants.functions';

function Warrants(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  usePageTitle(t('Warrants'));
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const { bottomRightToastRef } = useContext(ToastContext);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<COD>(tableStorageKey);

  const [contextMenuSelection, setContextMenuSelection] = useState<
    COD | undefined
  >(undefined);
  const [action, setAction] = useState<string>('');
  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(page, limit, columnHeadersMap, columnHeadersMap, (c) =>
      additionalColumnProperties(
        t,
        c as keyof typeof columnHeadersMap,
        setContextMenuSelection,
        setAction
      )
    );

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage, limit);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, error, isLoading, reload } = useAxios<WithPagination<COD[]>>(
    '/cashondeliveries' + queryString(httpFiltersObj),
    {
      skipWhen: !canLoadData,
    }
  );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction
        )}
      />,
    ],
    [columns, setContextMenuSelection, t]
  );

  const handleCMViewParcelsClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    history.push(`/warrants/${contextMenuSelection?.id}/parcels`);
  }, [history, contextMenuSelection]);

  const handleCmExportToCSVClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/cashondeliveries/${contextMenuSelection.id}/export/csv`,
      getFileName(t('Warrants'), [
        contextMenuSelection.seriski ?? '',
        contextMenuSelection.client_name,
      ]),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  const handleCmExportToExcelClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/cashondeliveries/${contextMenuSelection.id}/export/excel`,
      getFileName(t('Warrants'), [
        contextMenuSelection.seriski ?? '',
        contextMenuSelection.client_name,
      ]),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  const handleCmExportToPdfClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/cod?cod_id=${contextMenuSelection.id}`,
      getFileName(t('Warrants'), [
        contextMenuSelection.seriski ?? '',
        contextMenuSelection.client_name,
      ]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'view-orders') {
        handleCMViewParcelsClick();
      }
      if (action === 'export-to-cvs') {
        handleCmExportToCSVClick();
      }
      if (action === 'export-to-excel') {
        handleCmExportToExcelClick();
      }
      if (action === 'export-to-pdf') {
        handleCmExportToPdfClick();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    handleCMViewParcelsClick,
    handleCmExportToCSVClick,
    handleCmExportToExcelClick,
    handleCmExportToPdfClick,
  ]);

  return (
    <div className="page warrants">
      <HeaderPages
        title={t('Warrants')}
        subtitle={t('View and manage COD warrants')}
        icon={faFileContract}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={160}
        />
        <MainContent>
          <Table
            headerTitle=""
            columnOptions={columnOptions}
            columns={finalColumns}
            data={data}
            hasError={!!error}
            isLoading={isLoading}
            rebuildTooltip
            ref={tableRef}
            reload={reload}
            filterHeight={160}
            isReloadDisabled={!canLoadData}
            rows={limit}
            selectedColumns={selectedColumns}
            selection={selection}
            setLimit={setLimit}
            setPage={setPage}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelection}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            onRowDoubleClick={handleCMViewParcelsClick}
            displayActionColumn
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default Warrants;
