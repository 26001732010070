import './BulkOrders.scss';

import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { ImportListCollection } from '../../../types/api/importlists';
import { ReduxState } from '../../../types/redux';
import { Unpacked } from '../../../types/util';
import {
  downloadFile,
  getFileName,
  printAddressOrSticker,
  printSpecification,
} from '../../../utils/helpers/files';
import { queryString } from '../../../utils/helpers/http';
import { tryString } from '../../../utils/helpers/parse';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Table from '../../DataTable/Table/Table';
import Flex from '../../Layout/flex/Flex';
import MainContent from '../../Layout/flex/MainContent';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './BulkOrders.functions';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import ImportLogDialog from './Dialogs/View/ImportLog/ImportLogDialog';
import ShipmentsDialog from './Dialogs/View/Shipments/ShipmentsDialog';
import useTableFilters from './useTableFilters';

function BulkOrders(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  usePageTitle(t('Batch Orders'));
  const { bottomRightToastRef } = useContext(ToastContext);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Unpacked<ImportListCollection>>(tableStorageKey);
  const loggedUser = useSelector<ReduxState, ReduxState['user']>(
    (state) => state?.user
  );

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);
  const [action, setAction] = useState<string>('');
  const [contextMenuSelection, setContextMenuSelection] = useState<
    Unpacked<ImportListCollection> | undefined
  >(undefined);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(page, limit, columnHeadersMap, columnHeadersMap, (c) =>
      additionalColumnProperties(
        t,
        c as keyof typeof columnHeadersMap,
        setContextMenuSelection,
        setAction
      )
    );

  const canLoadData =
    httpFiltersObj.created_date_from && httpFiltersObj.created_date_to;

  const { data, isLoading, reload, error } = useAxios<ImportListCollection>(
    '/importlists' + queryString(httpFiltersObj),
    {
      skipWhen: !canLoadData,
    }
  );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction
        )}
      />,
    ],
    [columns, setContextMenuSelection, setAction, t]
  );

  const dataWithNumericParcelsCount = useMemo(
    () =>
      data
        ? {
            ...data,
            data: data.data.map((r) => ({
              ...r,
              parcels_count: parseInt(r.parcels_count),
            })),
          }
        : data,
    [data]
  );

  function handleFileImportClick() {
    history.push('/orders/batch/file-import');
  }

  function handleWebImportClick() {
    history.push('/orders/batch/web-import');
  }

  const { id: bulkOrderID } = useParams<{ id?: string }>();

  const {
    show: showImportLogDialog,
    hide: hideImportLogDialog,
    isVisible: isImportLogDialogVisible,
  } = useRouteDialog(
    '/orders/batch',
    `${bulkOrderID ?? contextMenuSelection?.id}/log`
  );

  const handleCmViewLogClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    showImportLogDialog();
  }, [contextMenuSelection, showImportLogDialog]);

  const {
    show: showShipmentsDialog,
    hide: hideShipmentsDialog,
    isVisible: isShipmentsDialogVisible,
  } = useRouteDialog(
    '/orders/batch',
    `${bulkOrderID ?? contextMenuSelection?.id}/orders`
  );

  const handleCmViewShipmentsClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    showShipmentsDialog();
  }, [contextMenuSelection, showShipmentsDialog]);

  const handleCmExportToCsv = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/importlists/${contextMenuSelection?.id}`,
      getFileName(t('BatchOrders'), contextMenuSelection.name),
      FileTypes.CSV,
      bottomRightToastRef,
      (res: { content: string }) => res.content
    );
  }, [t, contextMenuSelection, bottomRightToastRef]);

  const printAddressSticker = useCallback(
    async (typePrint: 'AddressBook' | 'Sticker') => {
      if (!contextMenuSelection) {
        return;
      }
      const queryParams = {
        list_id: contextMenuSelection.id,
      };

      printAddressOrSticker(
        queryParams,
        contextMenuSelection,
        false,
        typePrint,
        loggedUser,
        bottomRightToastRef
      );
    },
    [bottomRightToastRef, contextMenuSelection, loggedUser]
  );

  const reloadPickupSpec = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    const queryParams = {
      list_id: contextMenuSelection.id,
    };

    printSpecification(queryParams, bottomRightToastRef);
  }, [bottomRightToastRef, contextMenuSelection]);

  const handlePrintSpecification = useCallback(() => {
    reloadPickupSpec();
  }, [reloadPickupSpec]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'view-import-log') {
        handleCmViewLogClick();
      }
      if (action === 'view-orders') {
        handleCmViewShipmentsClick();
      }
      if (action === 'print-address-books') {
        printAddressSticker('AddressBook');
      }
      if (action === 'print-stickers') {
        printAddressSticker('Sticker');
      }
      if (action === 'print-specification') {
        handlePrintSpecification();
      }
      if (action === 'export-to-csv') {
        handleCmExportToCsv();
      }
      if (action === 'delete-list-and-addresses') {
        setIsDeleteDialogVisible(true);
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    handleCmViewLogClick,
    handleCmViewShipmentsClick,
    printAddressSticker,
    handlePrintSpecification,
    handleCmExportToCsv,
  ]);

  return (
    <div className="page bulk-orders">
      <HeaderPages
        title={t('Batch Orders')}
        subtitle={t('View and manage batch orders')}
        icon={faClipboardList}
      >
        <Button
          type="button"
          label={t('Web Import')}
          icon="fas fa-globe"
          className="p-mr-2 main-btn"
          onClick={() => handleWebImportClick()}
        />

        <Button
          type="button"
          label={t('File Import')}
          icon="fas fa-file-import"
          className="main-btn p-button-outlined"
          onClick={() => handleFileImportClick()}
        />
      </HeaderPages>

      <ImportLogDialog
        id={bulkOrderID ?? tryString(selection?.id)}
        name={selection?.name}
        visible={isImportLogDialogVisible}
        onHide={hideImportLogDialog}
        showShipmentsDialog={showShipmentsDialog}
      />

      <ShipmentsDialog
        id={bulkOrderID ?? tryString(selection?.id)}
        name={selection?.name}
        visible={isShipmentsDialogVisible}
        onHide={hideShipmentsDialog}
        showImportLogDialog={showImportLogDialog}
      />

      <DeleteDialog
        selectedList={contextMenuSelection}
        isShown={isDeleteDialogVisible}
        onHide={() => setIsDeleteDialogVisible(false)}
        reloadImportedLists={reload}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={160}
        />
        <MainContent>
          <Table
            headerTitle=""
            columnOptions={columnOptions}
            columns={finalColumns}
            data={dataWithNumericParcelsCount}
            hasError={!!error}
            isLoading={isLoading}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            onRowDoubleClick={() => handleCmViewShipmentsClick()}
            rebuildTooltip
            ref={tableRef}
            reload={reload}
            filterHeight={160}
            isReloadDisabled={!canLoadData}
            rows={limit}
            selectedColumns={selectedColumns}
            selection={selection}
            selectionPageOnly
            setLimit={setLimit}
            setPage={setPage}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelection}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            displayActionColumn
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default BulkOrders;
