import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import usePageTitle from '../../../../hooks/usePageTitle';
import { logoutUser } from '../../../../redux/actions/userActions';

function Logout() {
  const { t } = useTranslation();

  usePageTitle(t('Logging out...'));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return <Redirect to="/" />;
}

export default Logout;
