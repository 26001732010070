import { combineReducers } from 'redux';

import httpReducer from './httpReducer';
import languageReducer from './languageReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
  user: userReducer,
  http: httpReducer,
  language: languageReducer,
});

export default reducers;
