import './Header.scss';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { logos } from '../../configs/logos';
import { MenuContext } from '../../context/MenuContext';
import { ClientPost } from '../../enums/clients';
import HeaderMenu from './HeaderMenu';
import LanguageSelector from './LanguageSelector';

function AuthHeader(): JSX.Element {
  const { setIsMenuExpanded, isMenuExpanded } = useContext(MenuContext);
  function toggleMenu() {
    setIsMenuExpanded(!isMenuExpanded);
  }

  return (
    <>
      <div className="menu-btn" onClick={() => toggleMenu()}>
        <FontAwesomeIcon icon={faBars} />
      </div>
      <Link to="/" className="logo">
        <img
          src={
            logos[process.env.REACT_APP_CLIENT_NAME as ClientPost] ??
            logos[ClientPost.Koder]
          }
          alt="Logo"
        />
      </Link>
      <div className="right-section">
        <LanguageSelector />
        <HeaderMenu />
      </div>
    </>
  );
}

export default AuthHeader;
