import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';

import { ReduxState } from '../../types/redux';
import SentryRoute from './SentryRoute';

function AuthRoute({ ...routeProps }: RouteProps): JSX.Element {
  const isUserLoggedIn = useSelector<ReduxState>(
    (state) => state.user.isLoggedIn
  );

  return isUserLoggedIn ? (
    <SentryRoute {...routeProps} />
  ) : (
    <Redirect to="/login" />
  );
}

export default AuthRoute;
