import { useMemo } from 'react';

import usePageTitle from './usePageTitle';

function usePageTitleToggler(
  whenTruthy: string,
  whenFalsy: string,
  value: boolean
): void {
  const title = useMemo(
    () => (value ? whenTruthy : whenFalsy),
    [value, whenFalsy, whenTruthy]
  );

  usePageTitle(title);
}

export default usePageTitleToggler;
