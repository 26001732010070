import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxios from '../../../hooks/useAxios';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { ClientImportListTypeCollection } from '../../../types/api/clients';
import { ImportListTypeResource } from '../../../types/api/importlisttypes';
import { ReduxState } from '../../../types/redux';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import AutoCompleteInput from '../../Forms/AutoCompleteInput/AutoCompleteInput';
import { ClientListTypeOption } from './BulkOrders.types';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [listTypeFilter, setListTypeFilter] = useState('');
  const [listTypeFilterObj, setListTypeFilterObj] =
    useState<ClientListTypeOption | null>(null);
  const [nameFilter, setNameFilter] = useState(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );
  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date()
  );
  const [dateToFilter, setDateToFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );
  const [barcodeFilter, setBarcodeFilter] = useState(
    () => getSearchQueryParam(location.search, 'barcode') ?? ''
  );
  const [senderName, setSenderName] = useState<string>(
    () => getSearchQueryParam(location.search, 'sender_name') ?? ''
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);
  const debouncedListTypeFilter = useDebounce(listTypeFilter, debounceTimeout);
  const debouncedBarcodeFilter = useDebounce(barcodeFilter, debounceTimeout);
  const debouncedSenderName = useDebounce(senderName, debounceTimeout);

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: listTypeData } = useAxios<ClientImportListTypeCollection>(
    `/clients/${clientLoggedIn}/importlists/types` +
      queryString({ name: debouncedListTypeFilter }),
    { skipWhen: _.isObject(listTypeFilterObj) }
  );

  const listTypeOptions = useMemo(
    () =>
      listTypeData?.map((listType) => {
        return {
          label: listType.name,
          value: listType,
        };
      }) ?? [],
    [listTypeData]
  );

  const filtersArr = useMemo(
    () => [
      dateFromFilter,
      dateToFilter,
      listTypeFilterObj,
      debouncedNameFilter,
      debouncedBarcodeFilter,
      debouncedSenderName,
    ],
    [
      dateFromFilter,
      dateToFilter,
      debouncedBarcodeFilter,
      debouncedNameFilter,
      listTypeFilterObj,
      debouncedSenderName,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    dateFromFilter,
    dateToFilter,
    listTypeFilterObj,
    debouncedNameFilter,
    debouncedBarcodeFilter,
    debouncedSenderName,
  ]);

  useSearchQueryParam('name', debouncedNameFilter);
  useSearchQueryParam('barcode', debouncedBarcodeFilter);
  useSearchQueryDateParam('date_from', dateFromFilter);
  useSearchQueryDateParam('date_to', dateToFilter);
  useSearchQueryParam('sender_name', debouncedSenderName);

  useSearchQueryAutoCompleteInputParam<
    ClientListTypeOption,
    ImportListTypeResource
  >({
    param: 'listType',
    filterValue: listTypeFilter,
    setFilterValue: setListTypeFilter,
    filterValueObj: listTypeFilterObj,
    setFilterValueObj: setListTypeFilterObj,
    axiosRequestConfig: (listTypeId) => `/importlisttypes/${listTypeId}`,
    initialFilterValueDataPath: 'name',
    filterValueObjPath: 'id',
    initialDataModifier: (listTypeResource) => ({
      id: listTypeResource.id,
      name: listTypeResource.name,
    }),
  });

  const httpFiltersObj = useMemo(
    () => ({
      page: haveFiltersChanged ? 1 : page,
      limit,
      client_id: clientLoggedIn,
      name: debouncedNameFilter,
      import_list_type_id: listTypeFilterObj?.id,
      barcode: debouncedBarcodeFilter,
      created_date_from: dateFromFilter
        ? httpDateFormat(dateFromFilter)
        : undefined,
      created_date_to: dateToFilter ? httpDateFormat(dateToFilter) : undefined,
      sender_name: debouncedSenderName,
    }),
    [
      clientLoggedIn,
      dateFromFilter,
      dateToFilter,
      debouncedBarcodeFilter,
      debouncedNameFilter,
      haveFiltersChanged,
      limit,
      listTypeFilterObj?.id,
      debouncedSenderName,
      page,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    dateFromFilter, // date_from and date_to is count like one
    listTypeFilterObj,
    debouncedNameFilter,
    debouncedBarcodeFilter,
    senderName,
  }).filter(Boolean).length;

  const basicFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Basic filters')}</span>
        {basicFiltersActiveFilterCount > 0 && (
          <span className="badge">{basicFiltersActiveFilterCount}</span>
        )}
      </div>
    ),
    [t, basicFiltersActiveFilterCount]
  );

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                value={dateFromFilter ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="filter_date"
                onChange={(e) => {
                  setDateFromFilter(e.value as Date | null);
                }}
                className={classNames({
                  sidebar_filter_active: dateFromFilter,
                })}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                value={dateToFilter ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="filter_date"
                onChange={(e) => {
                  setDateToFilter(e.value as Date | null);
                }}
                className={classNames({
                  sidebar_filter_active: dateToFilter,
                })}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_imported_list_name">{t('Name')}</label>
            <InputText
              id="filter_imported_list_name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: nameFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_barcode">{t('Barcode')}</label>
            <InputText
              id="filter_barcode"
              value={barcodeFilter}
              onChange={(e) => setBarcodeFilter(e.target.value)}
              className={classNames({
                sidebar_filter_active: barcodeFilter,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_list_type">{t('List Type')}</label>
            <AutoCompleteInput
              id="filter_list_type"
              filterValue={listTypeFilter}
              value={listTypeFilterObj}
              options={listTypeOptions}
              onFilterChange={setListTypeFilter}
              onSelectionChange={setListTypeFilterObj}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="sender_name">{t('Sender Name')}</label>
            <InputText
              id="sender_name"
              value={senderName}
              className={classNames({
                sidebar_filter_active: senderName,
              })}
              onChange={(e) => setSenderName(e.target.value)}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      barcodeFilter,
      dateFromFilter,
      dateToFilter,
      listTypeFilter,
      listTypeFilterObj,
      listTypeOptions,
      nameFilter,
      basicFiltersHeaderTemplate,
      senderName,
      t,
    ]
  );

  function resetAllFilters() {
    setDateFromFilter(new Date());
    setDateToFilter(new Date());
    setNameFilter('');
    setBarcodeFilter('');
    setListTypeFilter('');
    setListTypeFilterObj(null);
    setSenderName('');
  }

  // here if there in the future have more tabs, sum all counter tabs with useMemo
  const headerFiltersCount = basicFiltersActiveFilterCount;

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
