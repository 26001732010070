import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useContext, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import {
  mediumStrenthRegex,
  passwordFeedback,
  strongStrenthRegex,
} from '../../../../utils/constants/passwords';
import { errorToast } from '../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import UnauthPage from '../../../page/UnauthPage';
import {
  getValidationSchema,
  initialFormValues,
  toApiData,
} from './ResetPassword.functions';
import {
  FormFields,
  ResetPasswordError,
  RouteParams,
} from './ResetPassword.types';

function ResetPassword(): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();

  const { toastRef } = useContext(ToastContext);

  const { code } = useParams<RouteParams>();

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);

  const methods = useForm<FormFields>({
    resolver,
    defaultValues: initialFormValues,
  });

  const { handleSubmit } = methods;

  const {
    data: resetPasswordData,
    error: resetPasswordError,
    isLoading,
    reload: resetPasswordRequestReload,
  } = useAxios<undefined, ResetPasswordError>();

  const prevResetPasswordData = usePrevious(resetPasswordData);
  const prevResetPasswordError = usePrevious(resetPasswordError);

  useEffect(() => {
    if (!resetPasswordData || resetPasswordData === prevResetPasswordData) {
      return;
    }

    history.replace('/profile/password-reset/success');
  }, [resetPasswordData, history, t, toastRef, prevResetPasswordData]);

  useEffect(() => {
    if (!resetPasswordError || resetPasswordError === prevResetPasswordError) {
      return;
    }

    const err_code = resetPasswordError.response?.data.error_description?.code;
    const err_pass =
      resetPasswordError.response?.data.error_description?.password;

    const errorMessage = err_code ?? err_pass;

    switch (errorMessage) {
      case 'empty':
        history.replace('/login');
        break;

      case 'invalid_parameter':
      case 'invalid_user_status':
        errorToast(
          toastRef,
          t('Error'),
          t('An error occured, please contact support.')
        );
        break;

      case 'expired':
        history.replace('/profile/password-reset/expired');
        break;

      default:
        errorToast(
          toastRef,
          t('Error'),
          t(
            'An error occured while resetting your password. Please request a new password reset link and try again later.'
          )
        );
    }
  }, [history, prevResetPasswordError, resetPasswordError, t, toastRef]);

  function handleFormSubmit(formValues: FormFields) {
    if (!code) {
      return;
    }

    resetPasswordRequestReload({
      url: `/users/password-reset?client_id=${process.env.REACT_APP_CLIENT_ID}`,
      method: 'PUT',
      data: toApiData(code, formValues),
    });
  }

  return (
    <UnauthPage
      title={t('Reset your password')}
      description={t(
        'Create a new password for your account. If you have problems resetting your password, please contact support.'
      )}
      className="p-fluid"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FieldWithErrors name="newPassword" label={t('New Password')}>
            <Controller
              name="newPassword"
              render={({ field }) => (
                <Password
                  value={field.value}
                  feedback
                  header={passwordFeedback(t)}
                  onChange={field.onChange}
                  mediumRegex={mediumStrenthRegex as any}
                  strongRegex={strongStrenthRegex as any}
                  toggleMask
                  autoComplete="off"
                  promptLabel=" "
                />
              )}
            />
          </FieldWithErrors>

          <Button
            type="submit"
            label={isLoading ? t('Loading...') : t('Submit')}
            disabled={isLoading}
            style={{ width: '100%' }}
          />
        </form>
      </FormProvider>

      <Link to="/login" className="p-d-block p-text-center p-mt-3">
        {t('Login')}
      </Link>
    </UnauthPage>
  );
}

export default ResetPassword;
