import { Toast, ToastMessage } from 'primereact/toast';
import { RefObject } from 'react';

import { contextMenuSeparator, toastLife } from '../../configs/primereact';
import { ContextMenuItem } from '../../types/api/primereact';

function showToast(
  toastRef: RefObject<Toast> | undefined,
  summary: string,
  detail: string,
  severity: 'success' | 'info' | 'warn' | 'error',
  options: ToastMessage
): void {
  if (!toastRef?.current) {
    return;
  }

  toastRef.current.show({
    severity,
    summary,
    detail: detail,
    life: toastLife,
    ...options,
  });
}

export function successToast(
  toastRef: RefObject<Toast> | undefined,
  summary: string,
  detail: string,
  options: ToastMessage = {}
): void {
  showToast(toastRef, summary, detail, 'success', options);
}

export function errorToast(
  toastRef: RefObject<Toast> | undefined,
  summary: string,
  detail: string,
  options: ToastMessage = {}
): void {
  showToast(toastRef, summary, detail, 'error', options);
}

export function warnToast(
  toastRef: RefObject<Toast> | undefined,
  summary: string,
  detail: string,
  options: ToastMessage = {}
): void {
  showToast(toastRef, summary, detail, 'warn', options);
}

export function infoToast(
  toastRef: RefObject<Toast> | undefined,
  summary: string,
  detail: string,
  options: ToastMessage = {}
): void {
  showToast(toastRef, summary, detail, 'info', options);
}

export function contextMenuModel(
  layout: [boolean, ContextMenuItem][][]
): ContextMenuItem[] {
  const filtered = layout
    .reduce((arr, current) => {
      arr.push(current.filter((item) => item[0]));

      return arr;
    }, [] as [boolean, ContextMenuItem][][])
    .filter((arr) => arr.length);

  let model: ContextMenuItem[] = [];

  for (let i = 0; i < filtered.length; i++) {
    model.push(...filtered[i].map((item) => item[1]));

    if (filtered[i + 1]) {
      model.push(contextMenuSeparator);
    }
  }

  return model;
}
