import './Payments.scss';

import { Field } from 'formik';
import { SelectButton } from 'primereact/selectbutton';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function Payments(): JSX.Element {
  const { t } = useTranslation();

  const paymentOptions = useMemo(
    () => [
      {
        label: t('Sender'),
        value: '1',
      },
      {
        label: t('Recipient'),
        value: '2',
      },
    ],
    [t]
  );

  return (
    <div className="variable-payments">
      <FieldWithErrors label={t('Postage')} name="postage">
        <Field
          as={SelectButton}
          name="postage"
          inputId="postage"
          options={paymentOptions}
        />
      </FieldWithErrors>

      <FieldWithErrors label={t('COD Fee')} name="redemption">
        <Field
          as={SelectButton}
          name="redemption"
          inputId="redemption"
          options={paymentOptions}
        />
      </FieldWithErrors>

      <FieldWithErrors label={t('Return Document')} name="return_document">
        <Field
          as={SelectButton}
          name="return_document"
          inputId="return_document"
          options={paymentOptions}
          className="payment-row"
        />
      </FieldWithErrors>

      <FieldWithErrors label={t('Insurance')} name="insurance">
        <Field
          as={SelectButton}
          name="insurance"
          inputId="insurance"
          options={paymentOptions}
          className="payment-row"
        />
      </FieldWithErrors>
    </div>
  );
}

export default Payments;
