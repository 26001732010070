function ThemeLoader() {
  switch (process.env.REACT_APP_THEME) {
    case 'red':
      import('./RedTheme');
      break;

    case 'blue':
      import('./BlueTheme');
      break;

    case 'green':
      import('./GreenTheme');
      break;

    case 'orange':
      import('./OrangeTheme');
      break;

    case 'purple':
      import('./PurpleTheme');
      break;

    default:
      import('./RedTheme');
  }

  return null;
}

export default ThemeLoader;
