import './Customers.scss';

import General from './General';
import Recipient from './Recipient';
import Sender from './Sender';

function Customers() {
  return (
    <div className="customers">
      <div className="general-section">
        <General />
      </div>
      <div className="customers-section">
        <Sender />
        <Recipient />
      </div>
    </div>
  );
}

export default Customers;
