import { Field, useFormikContext } from 'formik';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../../../hooks/useAxios';
import {
  MunicipalityCollection,
  MunicipalityPlaceCollection,
} from '../../../../../../types/api/municipalities';
import { PlaceStreetCollection } from '../../../../../../types/api/places';
import {
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../../../utils/helpers/misc';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';

function AddressInfo(): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();

  const { data: municipalitiesData, isLoading: isMunicipalitiesDataLoading } =
    useAxios<MunicipalityCollection>('/municipalities');

  const {
    data: placesInMunicipalityData,
    reload: reloadPlacesInMunicipalityData,
    isLoading: isPlacesInMunicipalityDataLoading,
  } = useAxios<MunicipalityPlaceCollection>(
    `/municipalities/${values.municipality}/places`,
    {
      skipWhen: !values.municipality,
    }
  );

  const {
    data: streetsData,
    reload: reloadStreetsData,
    isLoading: isStreetsDataLoading,
  } = useAxios<PlaceStreetCollection>(`/places/${values.place}/streets`, {
    skipWhen: !values.place,
  });

  const municipalityOptions = municipalitiesData?.map((municipality) => ({
    value: String(municipality.id),
    label: municipality.ime,
  }));

  const placeOptions = placesInMunicipalityData?.map((place) => ({
    value: String(place.id),
    label: place.ime,
    postal_code: place.postenski_broj,
  }));

  const streetOptions = streetsData?.map((street) => ({
    value: String(street.id),
    label: street.ime,
  }));

  return (
    <div className="p-fluid" style={{ maxWidth: 480 }}>
      <div className="p-grid">
        <div className="p-col">
          <FieldWithErrors name="municipality" label={t('Municipality')}>
            <Field
              name="municipality"
              inputId="municipality"
              as={Dropdown}
              filter
              options={municipalityOptions}
              placeholder={
                isMunicipalitiesDataLoading
                  ? t('Loading...')
                  : t('Select Municipality')
              }
              disabled={isMunicipalitiesDataLoading}
              onChange={(e: DropdownChangeParams) => {
                setFieldValue('municipality', e.value);
                setFieldValue('place', '');
                setFieldValue('street', '');
                setFieldValue('house_number', '');
                setFieldValue('entrance', '');
                setFieldValue('flat', '');

                reloadPlacesInMunicipalityData({
                  url: `municipalities/${e.value}/places`,
                });
              }}
            />
          </FieldWithErrors>
        </div>

        <div className="p-col">
          <FieldWithErrors name="place" label={t('Place')}>
            <Field
              type="input"
              name="place"
              inputId="place"
              as={Dropdown}
              filter
              options={placeOptions}
              itemTemplate={placeItemTemplate}
              valueTemplate={placeValueTemplate}
              placeholder={
                isPlacesInMunicipalityDataLoading ? t('Loading...') : ''
              }
              onChange={(e: DropdownChangeParams) => {
                setFieldValue('place', e.value);
                setFieldValue('street', '');
                setFieldValue('house_number', '');
                setFieldValue('entrance', '');
                setFieldValue('flat', '');
                reloadStreetsData({ url: `places/${e.value}/streets` });
              }}
              disabled={!values.municipality || false}
            />
          </FieldWithErrors>
        </div>

        <div className="p-col-3 p-lg-4">
          <FieldWithErrors name="zip">
            <Field id="zip" name="zip" as={InputText} disabled />
          </FieldWithErrors>
        </div>
      </div>

      <FieldWithErrors name="street" label={t('Street')}>
        <Field
          type="input"
          name="street"
          inputId="street"
          as={Dropdown}
          filter
          options={streetOptions}
          disabled={!values.place}
          placeholder={isStreetsDataLoading ? t('Loading...') : ''}
        />
      </FieldWithErrors>

      <div className="p-grid">
        <div className="p-col">
          <FieldWithErrors name="house_number" label={t('Number')}>
            <Field
              type="input"
              name="house_number"
              as={InputText}
              disabled={!values.street}
            />
          </FieldWithErrors>
        </div>

        <div className="p-col">
          <FieldWithErrors name="entrance" label={t('Entrance No.')}>
            <Field
              id="entrance"
              name="entrance"
              as={InputText}
              disabled={!values.street}
            />
          </FieldWithErrors>
        </div>

        <div className="p-col">
          <FieldWithErrors name="flat" label={t('Flat No.')}>
            <Field
              name="flat"
              id="flat"
              as={InputText}
              disabled={!values.street}
            />
          </FieldWithErrors>
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col">
          <FieldWithErrors name="phone" label={t('Phone')}>
            <Field
              as={InputText}
              name="phone"
              id="phone"
              className="data-cy-mobile"
            />
          </FieldWithErrors>
        </div>
        <div className="p-col">
          <FieldWithErrors name="contact" label={t('Contact')}>
            <Field name="contact" id="contact" as={InputText} />
          </FieldWithErrors>
        </div>
      </div>
    </div>
  );
}

export default AddressInfo;
