import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { useRef } from 'react';

type Props = {
  actions: {
    label: string;
    icon: string;
    disabled: boolean;
    command: () => void;
  }[];
};

const ActionsTableMenu = ({ actions }: Props) => {
  const menu = useRef<TieredMenu>(null);
  return (
    <>
      <Button
        label=""
        icon="fas fa-ellipsis-h"
        aria-haspopup // accessibility attribute for dropdown
        onClick={(e) => menu?.current!.toggle(e)}
        style={{ width: '2rem', height: '1.5rem', border: 'none' }}
        className="p-button-outlined"
      />
      <TieredMenu popup ref={menu} model={actions} />
    </>
  );
};

export default ActionsTableMenu;
