import { ReactNode, useEffect, useMemo, useState } from 'react';

import HeadlessStepperContext, {
  HeadlessStepperContextType,
} from './HeadlessStepperContext';

export type Step = {
  component: React.ComponentType;
  nextButtonLabel?: (context: HeadlessStepperContextType) => string;
};

export type HeadlessStepperProps = {
  steps: Step[];
  onNextBtnClick?: (context: HeadlessStepperContextType) => Promise<boolean>;
  onBackBtnClick?: (context: HeadlessStepperContextType) => Promise<boolean>;
  isLoading?: boolean;
  children: ReactNode;
};

function HeadlessStepper({
  steps,
  onNextBtnClick,
  onBackBtnClick,
  isLoading: isLoadingProp,
  children,
}: HeadlessStepperProps): JSX.Element {
  const [stepIndex, setStepIndex] = useState(0);
  const [_isLoading, setIsLoading] = useState(isLoadingProp ?? false);
  const isLoading = isLoadingProp || _isLoading;
  const isLastStep = stepIndex === steps.length - 1;
  const StepComponent = steps[stepIndex].component;

  useEffect(() => {
    setStepIndex(0);
  }, []);

  const context = useMemo<HeadlessStepperContextType>(() => {
    async function handleNextBtnClick(): Promise<void> {
      function handle() {
        if (!isLastStep) {
          setStepIndex((step) => step + 1);
        }
      }

      if (onNextBtnClick) {
        if (await onNextBtnClick(context)) {
          handle();
        }
      } else {
        handle();
      }
    }

    async function handleBackBtnClick(): Promise<void> {
      function handle() {
        if (stepIndex > 0) {
          setStepIndex((currentStep) => currentStep - 1);
        }
      }

      if (onBackBtnClick) {
        if (await onBackBtnClick(context)) {
          handle();
        }
      } else {
        handle();
      }
    }

    return {
      steps,
      currentStep: steps[stepIndex],
      totalSteps: steps.length,
      stepIndex,
      setStepIndex,
      isLastStep,
      isLoading,
      setIsLoading,
      StepComponent,
      handleNextBtnClick,
      handleBackBtnClick,
    };
  }, [
    StepComponent,
    isLastStep,
    isLoading,
    onBackBtnClick,
    onNextBtnClick,
    stepIndex,
    steps,
  ]);

  return (
    <HeadlessStepperContext.Provider value={context}>
      {children}
    </HeadlessStepperContext.Provider>
  );
}

export default HeadlessStepper;
