import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';

function ReferenceNo1({ type }: { type?: string }): JSX.Element {
  const { t } = useTranslation();

  return (
    <FieldWithErrors
      name="reference1"
      label={type === 'package' ? t('Reference No. 1') : t('Reference')}
    >
      <Controller
        name="reference1"
        render={({ field }) => (
          <InputText
            id="reference1"
            name="reference1"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default ReferenceNo1;
