import * as Sentry from '@sentry/react';

import { ClientResource } from '../../../types/api/clients';

type SingleNotification = {
  isChecked: boolean;
  subject?: string;
  body?: string;
  msg?: string;
};

type Notifications = {
  [key: string]: SingleNotification;
};
export type FormValues = {
  import_notification: boolean;
  cod_email_warrant: boolean;
  sms_gateway: string;
  notifications?: { [key: string]: any };
};

export function getInitialValues(data: ClientResource | undefined): FormValues {
  return {
    notifications: generateNotificationsObject(data),
    sms_gateway: data?.sms_gateway || '',
    import_notification: data?.import_notification === 1,
    cod_email_warrant: data?.cod_email_warrant === 1,
  };
}

function generateNotificationsObject(data: ClientResource | undefined) {
  let parsedNotifications;

  try {
    parsedNotifications = data?.notification
      ? JSON.parse(data.notification)
      : [];
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        data: data?.notification,
      },
    });
  }

  if (!parsedNotifications) {
    return {};
  }

  let notifications: Notifications = {};

  for (let n of parsedNotifications) {
    if (
      !n.order_notification_type ||
      !n.order_notification_recipient_type ||
      !n.order_notification_channel
    ) {
      continue;
    }

    let newObject: SingleNotification = { isChecked: false };

    if (parseInt(n.order_notification_channel) === 1) {
      newObject.subject = n.subject;
      newObject.body = n.body;
    } else if (parseInt(n.order_notification_channel) === 2) {
      newObject.msg = n.msg;
    }

    const id = `${n.order_notification_type}-${n.order_notification_recipient_type}-${n.order_notification_channel}`;

    notifications[id] = {
      ...newObject,
      isChecked: true,
    };
  }

  return notifications;
}

export function editDialogApiData(values?: FormValues) {
  let notifications = [];

  for (let notification in values?.notifications) {
    if (!values?.notifications[notification].isChecked) {
      continue;
    }

    const [notificationType, notificationRecipient, notificationChannel] =
      notification.split('-');
    notifications.push({
      order_notification_type: notificationType,
      order_notification_recipient_type: notificationRecipient,
      order_notification_channel: notificationChannel,

      ...(parseInt(notificationChannel) === 1
        ? {
            subject: values?.notifications[notification].subject,
            body: values?.notifications[notification].body,
          }
        : {
            msg: values?.notifications[notification].msg,
          }),
    });
  }

  return {
    notification: notifications,
    import_notification: values?.import_notification,
    cod_email_warrant: values?.cod_email_warrant,
  };
}
