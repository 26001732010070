import { addLocale } from 'primereact/api';

addLocale('mk', {
  accept: 'Да',
  reject: 'Не',
  choose: 'Избери',
  upload: 'Прикачи',
  cancel: 'Откажи',
  firstDayOfWeek: 1,
  dayNames: [
    'Недела',
    'Понеделник',
    'Вторник',
    'Среда',
    'Четврток',
    'Петок',
    'Сабота',
  ],
  dayNamesShort: ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб'],
  dayNamesMin: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
  monthNames: [
    'Јануари',
    'Февруари',
    'Март',
    'Април',
    'Мај',
    'Јуни',
    'Јули',
    'Август',
    'Септември',
    'Октомври',
    'Ноември',
    'Декември',
  ],
  monthNamesShort: [
    'Јан',
    'Фев',
    'Мар',
    'Апр',
    'Мај',
    'Јун',
    'Јул',
    'Авг',
    'Сеп',
    'Окт',
    'Ное',
    'Дек',
  ],
  today: 'Денес',
  clear: 'Избриши',
  weekHeader: 'Нед',
  dateFormat: 'mm/dd/yy',
  weak: 'Слаба',
  medium: 'Средна',
  strong: 'Сигурна',
  passwordPrompt: 'Внеси лозинка',
});

addLocale('sr-Cyrl', {
  accept: 'Да',
  reject: 'Не',
  choose: 'Изабрати',
  upload: 'Прикачити',
  cancel: 'Отказати',
  firstDayOfWeek: 1,
  dayNames: [
    'Недеља',
    'Понедељак',
    'Уторак',
    'Среда',
    'Четвртак',
    'Петак',
    'Субота',
  ],
  dayNamesShort: ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Суб'],
  dayNamesMin: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
  monthNames: [
    'Јануар',
    'Фебруар',
    'Март',
    'Април',
    'Мај',
    'Јун',
    'Јул',
    'Август',
    'Септембар',
    'Октомбар',
    'Новембар',
    'Децембар',
  ],
  monthNamesShort: [
    'Јан',
    'Феб',
    'Мар',
    'Апр',
    'Мај',
    'Јун',
    'Јул',
    'Авг',
    'Сеп',
    'Окт',
    'Ноб',
    'Дец',
  ],
  today: 'Данас',
  clear: 'Избрисати',
  weekHeader: 'Нед',
  dateFormat: 'mm/dd/yy',
  weak: 'Слаба',
  medium: 'Средња',
  strong: 'Безбедња',
  passwordPrompt: 'Унесите лозинку',
});

addLocale('sr-Latn', {
  accept: 'Da',
  reject: 'Ne',
  choose: 'Izabrati',
  upload: 'Prikačiti',
  cancel: 'Otkazati',
  firstDayOfWeek: 1,
  dayNames: [
    'Nedelja',
    'Ponedeljak',
    'Utorak',
    'Sreda',
    'Četvrtak',
    'Petak',
    'Subota',
  ],
  dayNamesShort: ['Ned', 'Pon', 'Vto', 'Sre', 'Čet', 'Pet', 'Sub'],
  dayNamesMin: ['N', 'P', 'V', 'S', 'Č', 'P', 'S'],
  monthNames: [
    'Januar',
    'Februar',
    'Mart',
    'April',
    'Maj',
    'Jun',
    'Jul',
    'Avgust',
    'Septembar',
    'Oktombar',
    'Novembar',
    'Decembar',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Avg',
    'Sep',
    'Okt',
    'Nob',
    'Dec',
  ],
  today: 'Danas',
  clear: 'Izbrisati',
  weekHeader: 'Ned',
  dateFormat: 'mm/dd/yy',
  weak: 'Slaba',
  medium: 'Srednja',
  strong: 'Bezbednja',
  passwordPrompt: 'Unesite lozinku',
});
