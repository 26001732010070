import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useAxios from '../../../../../../hooks/useAxios';
import { ClientImportListTypeCollection } from '../../../../../../types/api/clients';
import { ReduxState } from '../../../../../../types/redux';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import HeadlessStepperContext from '../../../../../Stepper/HeadlessStepperContext';
import Step from '../../../../../Stepper/Inline/Step';

type Props = {
  isListTypeLoading: boolean;
};

function ClientAndListType({ isListTypeLoading }: Props): JSX.Element {
  const { t } = useTranslation();

  const { setIsLoading } = useContext(HeadlessStepperContext);

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: clientListTypesData, isLoading: isClientListTypesLoading } =
    useAxios<ClientImportListTypeCollection>(
      `/clients/${clientLoggedIn}/importlists/types`,
      {
        skipWhen: !clientLoggedIn,
      }
    );

  useEffect(() => {
    setIsLoading!(isClientListTypesLoading);
  }, [isClientListTypesLoading, setIsLoading]);

  const clientListTypeOptions = useMemo(
    () =>
      clientListTypesData?.map((listType) => {
        return {
          label: listType.name,
          value: listType,
        };
      }) ?? [],
    [clientListTypesData]
  );

  return (
    <Step title={t('List Type')} subtitle={t('Select a list type')}>
      <FieldWithErrors name="_clientListType" label={t('List type')}>
        <Controller
          name="_clientListType"
          render={({ field }) => (
            <Dropdown
              name="_clientListType"
              inputId="_clientListType"
              options={clientListTypeOptions}
              filter
              disabled={
                isListTypeLoading ||
                (clientListTypeOptions?.length === 0 &&
                  !isClientListTypesLoading)
              }
              placeholder={
                clientListTypeOptions?.length === 0 && !isClientListTypesLoading
                  ? t('This client has no list types assigned yet')
                  : undefined
              }
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              className="data-cy-listtype"
            />
          )}
        />
      </FieldWithErrors>
    </Step>
  );
}

export default ClientAndListType;
