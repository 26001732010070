import { InputText } from 'primereact/inputtext';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { invalidIntCharactersRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import { FormFields } from '../../../CreateEditRecreate.functions';

function Quantity(): JSX.Element {
  const { t } = useTranslation();

  const { setValue } = useFormContext<FormFields>();

  return (
    <FieldWithErrors name="kolicina" label={t('Quantity')}>
      <Controller
        name="kolicina"
        render={({ field }) => (
          <InputText
            id="kolicina"
            name="kolicina"
            value={field.value}
            onChange={(e) =>
              setValue(
                'kolicina',
                e.target.value.replace(invalidIntCharactersRegex, '')
              )
            }
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default Quantity;
