import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { logosBig } from '../../configs/logos';
import { ClientPost } from '../../enums/clients';
import usePageTitle from '../../hooks/usePageTitle';
import styles from './UnauthPage.module.scss';

type Props = {
  title: string;
  pageTitle?: string;
  description?: string;
  error?: string;
  className?: string;
  children: ReactNode;
};

function UnauthPage({
  title,
  pageTitle,
  description,
  error,
  className,
  children,
}: Props): JSX.Element {
  usePageTitle(pageTitle ?? title);

  return (
    <div className={classNames('p-shadow-1', styles.formWrapper, className)}>
      <div className={styles.logoWrapper}>
        <img
          src={
            logosBig[process.env.REACT_APP_CLIENT_NAME as ClientPost] ??
            logosBig[ClientPost.Koder]
          }
          alt={`${process.env.REACT_APP_CLIENT_NAME_TITLE ?? 'Company'} Logo`}
        />
      </div>

      <h1 className="p-mt-6 p-mb-3 p-mx-6 p-text-center p-text-normal">
        {title}
      </h1>

      {description && (
        <p className="p-my-0 p-mx-6 p-pt-0 p-text-center p-text-secondary p-text-light">
          {description}
        </p>
      )}

      {error && (
        <div className={classNames('p-mt-5 p-mb-0 p-mx-6 p-p-3', styles.error)}>
          <FontAwesomeIcon icon={faExclamationCircle} className="p-mr-2" />
          {error}
        </div>
      )}

      <div
        className={classNames('p-mx-6 p-mb-6', {
          'p-mt-4': !!error,
          'p-mt-6': !error,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default UnauthPage;
