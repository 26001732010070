import { ColumnProps } from 'primereact/column';

export const toastLife = 5000;

export const contextMenuSeparator = {
  separator: true,
};

export const noColumnStyle: ColumnProps = {
  sortable: false,
  reorderable: false,
  style: {
    width: 60,
    flex: '0 1 auto',
  },
  bodyStyle: {
    width: 60,
    flex: '0 1 auto',
  },
};
