import { FormikProps } from 'formik';
import { RefObject } from 'react';

export type AllowedErrors = Record<string, Record<string, string>>;

// Console errors are intentional, so that we can easier debug, even on prod
// true - We have received an expected context from the back-end
// false - We received an unexpected context, it should be handled as an internal error
export function tryApiContextValidation(
  errorDescription: Record<string, string>,
  formRef: RefObject<FormikProps<unknown>>,
  allowedErrors: AllowedErrors
): boolean {
  if (Object.keys(errorDescription).length === 0 || !formRef.current) {
    console.error(
      'tryApiContextValidation:',
      errorDescription,
      !!formRef.current
    );

    return false;
  }

  let errorObj: Record<string, string> = {};

  for (const field in errorDescription) {
    const errorMessage = errorDescription[field];
    const uiMessage = allowedErrors[field]?.[errorMessage];

    if (!uiMessage) {
      console.error(
        'tryApiContextValidation:',
        errorDescription,
        allowedErrors,
        field,
        errorMessage,
        uiMessage
      );

      return false;
    }

    errorObj[allowedErrors[field].customName ?? field] = uiMessage;
  }

  formRef.current.setErrors(errorObj);

  return true;
}
