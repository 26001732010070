import { TFunction } from 'react-i18next';

import { OrderStatus } from '../../../../../enums/orders';
import { OrderCollection } from '../../../../../types/api/orders';

export function generateErrorMsg(t: TFunction, err: string) {
  switch (err) {
    case 'deleting_cod_order': {
      return t('Through this order a payment redemption is paid');
    }

    case 'deleting_order_with_cod': {
      return t('Orders with COD cannot be deleted');
    }

    case 'deleting_invoiced_order': {
      return t('Invoiced orders cannot be deleted');
    }

    default: {
      // TODO: ping the main API
      //  Log an error for easier debugging on production.
      return t('Unknown error');
    }
  }
}

export function toApiData(data: OrderCollection['data']) {
  return data.filter((o) => o.status_id < OrderStatus.PickedUp);
}
