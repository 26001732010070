import './Menu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Location } from 'history';
import { match, matchPath } from 'react-router';
import { NavLink } from 'react-router-dom';

import Tooltip from '../Misc/Tooltip/Tooltip';
import { MenuItem } from './MenuLinks';

export function renderMenuLink(link: MenuItem, isMenuExpanded: boolean) {
  function isNavLinkActive(match: match | null, location: Location<unknown>) {
    if (!match) {
      return false;
    }

    return (
      match.isExact ||
      !!link.additionalRoutes?.find((route) =>
        matchPath(location.pathname, { path: route })
      )
    );
  }

  return isMenuExpanded ? (
    <NavLink
      className="menu-link"
      activeClassName="active"
      to={link.path}
      isActive={isNavLinkActive}
    >
      <div>
        <FontAwesomeIcon icon={link.icon} className="link-icon" />
        <span className="link-label">{link.label}</span>
      </div>
    </NavLink>
  ) : (
    <Tooltip
      text={link.label}
      children={
        <NavLink
          className="menu-link"
          activeClassName="active"
          to={link.path}
          isActive={isNavLinkActive}
        >
          <div>
            <FontAwesomeIcon icon={link.icon} className="link-icon" />
          </div>
        </NavLink>
      }
    />
  );
}
