import _ from 'lodash';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

type Return = {
  show: () => void;
  hide: () => void;
  isVisible: boolean;
};

function useRouteDialog(baseRoute: string, additionalRoute: string): Return {
  const history = useHistory();
  const routeMatch = useRouteMatch(`${baseRoute}/${additionalRoute}`);

  const show = useCallback<() => void>(() => {
    history.replace({
      ..._.omit(history.location, 'key'),
      pathname: `${baseRoute}/${additionalRoute}`,
    });
  }, [additionalRoute, baseRoute, history]);

  const hide = useCallback<() => void>(() => {
    if (!routeMatch) {
      return;
    }

    history.replace({
      ..._.omit(history.location, 'key'),
      pathname: `${baseRoute}`,
    });
  }, [baseRoute, history, routeMatch]);

  return { show, hide, isVisible: !!routeMatch };
}

export default useRouteDialog;
