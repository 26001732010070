import { faMinus } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>> | undefined,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().subtract(1, 'month').toDate()
  );

  const [dateToFilter, setDateToFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const [serialNoFilter, setSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'serialNo') ?? ''
  );

  const [parcelSerialNoFilter, setParcelSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'parcelSerialNo') ?? ''
  );

  const [reference, setReference] = useState<string>(
    () => getSearchQueryParam(location.search, 'reference') ?? ''
  );

  const debouncedSerialNoFilter = useDebounce(serialNoFilter, debounceTimeout);
  const debouncedParcelSerialNo = useDebounce(
    parcelSerialNoFilter,
    debounceTimeout
  );
  const debouncedReference = useDebounce(reference, debounceTimeout);

  useEffect(() => {
    if (!setPage) {
      return;
    }
    setPage(1);
  }, [
    dateFromFilter,
    dateToFilter,
    debouncedSerialNoFilter,
    debouncedParcelSerialNo,
    setPage,
  ]);

  function resetAllFilters() {
    setDateFromFilter(new Date());
    setDateToFilter(new Date());
    setSerialNoFilter('');
    setParcelSerialNoFilter('');
    setReference('');
  }

  const filtersArr = useMemo(
    () => [
      dateFromFilter,
      dateToFilter,
      debouncedReference,
      debouncedSerialNoFilter,
      debouncedParcelSerialNo,
    ],
    [
      dateFromFilter,
      dateToFilter,
      debouncedReference,
      debouncedParcelSerialNo,
      debouncedSerialNoFilter,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(
    () => ({
      date_from: dateFromFilter ? httpDateFormat(dateFromFilter) : undefined,
      date_to: dateToFilter ? httpDateFormat(dateToFilter) : undefined,
      cod_serial_number: debouncedSerialNoFilter,
      cod_shipment_serial_number: debouncedParcelSerialNo,
      reference1: debouncedReference,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      dateFromFilter,
      dateToFilter,
      debouncedParcelSerialNo,
      debouncedReference,
      debouncedSerialNoFilter,
      haveFiltersChanged,
      limit,
      page,
    ]
  );

  useSearchQueryParam('serialNo', debouncedSerialNoFilter);
  useSearchQueryParam('parcelSerialNo', debouncedParcelSerialNo);
  useSearchQueryDateParam('date_from', dateFromFilter);
  useSearchQueryDateParam('date_to', dateToFilter);

  const basicFiltersActiveFilterCount = Object.values({
    dateFromFilter, // date_from and date_to is count like one
    debouncedParcelSerialNo,
    debouncedSerialNoFilter,
  }).filter(Boolean).length;

  const basicFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Basic filters')}</span>
        {basicFiltersActiveFilterCount > 0 && (
          <span className="badge">{basicFiltersActiveFilterCount}</span>
        )}
      </div>
    ),
    [t, basicFiltersActiveFilterCount]
  );

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Created Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                value={dateFromFilter ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="filter_date"
                onChange={(e) => {
                  setDateFromFilter(e.value as Date | null);
                }}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                value={dateToFilter ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="filter_date"
                onChange={(e) => {
                  setDateToFilter(e.value as Date | null);
                }}
              />
            </div>
          </div>

          <div className="sidebar_filter">
            <label htmlFor="filter_serial_no">{t('Serial No.')}</label>
            <InputText
              name="filter_serial_no"
              value={serialNoFilter}
              onChange={(e) => setSerialNoFilter(e.target.value)}
              id="filter_serial_no"
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="filter_parcel_serial_no">
              {t('Order Serial No.')}
            </label>
            <InputText
              name="filter_parcel_serial_no"
              id="filter_parcel_serial_no"
              value={parcelSerialNoFilter}
              onChange={(e) => setParcelSerialNoFilter(e.target.value)}
            />
          </div>

          <div className="sidebar_filter">
            <label htmlFor="filter_reference">{t('Reference')}</label>
            <InputText
              name="filter_reference"
              id="filter_reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      dateFromFilter,
      dateToFilter,
      parcelSerialNoFilter,
      reference,
      serialNoFilter,
      basicFiltersHeaderTemplate,
      t,
    ]
  );

  // here if there in the future have more tabs, sum all counter tabs with useMemo
  const headerFiltersCount = basicFiltersActiveFilterCount;

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
