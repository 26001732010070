import { RefObject, useCallback, useContext, useEffect, useState } from 'react';

import { SidebarFiltersContext } from '../context/SidebarFiltersContext';

function useTableScrollHeight(
  ref: RefObject<any>,
  filterHeight?: number
): number {
  const { isFiltersExpanded } = useContext(SidebarFiltersContext);

  const calculateInitialHeight = useCallback((): number => {
    const windowHeight = window.innerHeight;
    const filtersHeight = isFiltersExpanded
      ? filterHeight
        ? filterHeight
        : 250
      : 40;
    return windowHeight - filtersHeight - 240;
  }, [isFiltersExpanded, filterHeight]);
  const [scrollHeight, setScrollHeight] = useState(calculateInitialHeight);

  useEffect(() => {
    function handleResize(): void {
      if (!ref?.current?.el) {
        return;
      }
      setScrollHeight(calculateInitialHeight());
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, isFiltersExpanded, calculateInitialHeight]);
  return scrollHeight;
}

export default useTableScrollHeight;
