import './Profile.scss';

import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePhoneOptionsContext } from '../../../context/PhoneOptionsContext';
import useAxios from '../../../hooks/useAxios';
import { ClientResource } from '../../../types/api/clients';
import { MunicipalityCollection } from '../../../types/api/municipalities';
import {
  PlaceResource,
  PlaceStreetCollection,
} from '../../../types/api/places';
import { isStreetFreeInputAllowed } from '../../../utils/constants/misc';
import {
  mediumStrenthRegex,
  passwordFeedback,
  strongStrenthRegex,
} from '../../../utils/constants/passwords';
import { invalidPhoneNumberCharactersRegex } from '../../../utils/constants/phoneNumbers';
import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../utils/helpers/phoneNumbers';
import FieldWithErrors from '../../Forms/FieldWithErrors/FieldWithErrors';
import { FormFields } from './Profile.functions';

type Props = {
  data: ClientResource | undefined;
  isSmallerDevice: boolean;
};

function ProfileContent({ data, isSmallerDevice }: Props) {
  const { values, setFieldValue } = useFormikContext<FormFields>();

  const { t } = useTranslation();

  const passwordFieldClassName = classNames({
    'p-col-12  p-py-0': isSmallerDevice,
    'p-col-6': !isSmallerDevice,
  });

  const fieldClassName = classNames({
    'p-col-12': isSmallerDevice,
    'p-col-6': !isSmallerDevice,
  });

  const { data: municipalitiesData, isLoading: isMunicipalitiesDataLoading } =
    useAxios<MunicipalityCollection>('/municipalities');

  const { data: placeData } = useAxios<PlaceResource>(
    { url: `/places/${values.mesto_id}` },
    {
      skipWhen: !values.mesto_id,
    }
  );

  useEffect(() => {
    if (placeData) {
      setFieldValue('mesto_ime', placeData.ime);
      setFieldValue('opstina_id', placeData.opstina_id);

      const opstina = municipalitiesData?.find(
        (m) => m.id === placeData.opstina_id
      );

      setFieldValue('opstina_ime', opstina?.ime);
    }
  }, [municipalitiesData, placeData, setFieldValue]);

  const {
    data: placesInMunicipalityData,
    reload: reloadPlacesInMunicipalityData,
    isLoading: isPlacesInMunicipalityDataLoading,
  } = useAxios<MunicipalityCollection>(
    { url: `/municipalities/${values?.opstina_id}/places` },
    {
      skipWhen: !values.opstina_id,
    }
  );

  const {
    data: streetsData,
    reload: reloadStreetsData,
    isLoading: isStreetsDataLoading,
  } = useAxios<PlaceStreetCollection>(`/places/${values.mesto_id}/streets`, {
    skipWhen: !values.mesto_id,
  });

  useEffect(() => {
    if (streetsData) {
      const street = streetsData.find((s) => s.id === values.ulica_id);
      setFieldValue('ulica_ime', street?.ime);
    }
  }, [setFieldValue, streetsData, values.ulica_id]);

  const municipalitiesOptions = municipalitiesData?.map((m) => ({
    label: m.ime,
    value: m.id,
  }));

  const placesOptions = placesInMunicipalityData?.map((m) => ({
    label: m.ime,
    value: m.id,
  }));

  const streetsOptions =
    streetsData && streetsData?.length > 0
      ? streetsData?.map((s) => ({ label: s.ime, value: s.id }))
      : [];

  const { countryOptionTemplate, languages, selectedCountryTemplate } =
    usePhoneOptionsContext();

  return (
    <div className="p-fluid">
      <div className="p-d-flex  p-ai-center">
        <FieldWithErrors
          name="change_pass_switch"
          label={t('Change password')}
          className="change-pass-label"
        >
          <Field
            as={InputSwitch}
            checked={values.change_pass_switch}
            name="change_pass_switch"
            id="change_pass_switch"
            className="p-mr-4"
          />
        </FieldWithErrors>
      </div>

      {values.change_pass_switch && (
        <div className="p-grid p-mt-1">
          <div className={passwordFieldClassName}>
            <FieldWithErrors name="new_password" label={t('New Password')}>
              <Field
                as={Password}
                inputId="new_password"
                name="new_password"
                feedback
                header={passwordFeedback(t)}
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel=" "
              />
            </FieldWithErrors>
          </div>

          <div className={passwordFieldClassName}>
            <FieldWithErrors
              name="confirm_password"
              label={t('Confirm Password')}
            >
              <Field
                as={Password}
                inputId="confirm_password"
                name="confirm_password"
                feedback
                header={passwordFeedback(t)}
                mediumRegex={mediumStrenthRegex as any}
                strongRegex={strongStrenthRegex as any}
                toggleMask
                autoComplete="off"
                promptLabel=" "
              />
            </FieldWithErrors>
          </div>
        </div>
      )}

      <div className="p-grid p-mt-5">
        <div className={fieldClassName}>
          <FieldWithErrors name="ime" label={t('Name')}>
            <Field name="ime" as={InputText} id="ime" />
          </FieldWithErrors>

          <FieldWithErrors name="mobilen" label={t('Mobile')}>
            <div className="p-inputgroup">
              <Field
                as={Dropdown}
                className="mobilen_region"
                style={{ padding: 0, width: '5rem' }}
                id="mobilen_region"
                name="mobilen_region"
                options={languages}
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                data-cy="mobilen_region"
              />
              <Field
                as={InputText}
                name="mobilen"
                style={{ width: '100%' }}
                id="mobilen"
                value={formatMobileNumber(
                  values.mobilen,
                  values.mobilen_region ||
                    getPhoneorMobileNumberRegion(values.mobilen)
                )}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    'mobilen',
                    e.target.value?.replace(
                      invalidPhoneNumberCharactersRegex,
                      ''
                    )
                  );
                }}
              />
            </div>
          </FieldWithErrors>

          <FieldWithErrors name="telefon" label={t('Phone')}>
            <div className="p-inputgroup">
              <Field
                as={Dropdown}
                className="telefon_region"
                style={{ padding: 0, width: '5rem' }}
                id="telefon_region"
                name="telefon_region"
                options={languages}
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                data-cy="telefon_region"
              />
              <Field
                as={InputText}
                name="telefon"
                id="telefon"
                style={{ width: '100%' }}
                value={formatPhoneNumber(
                  values.telefon,
                  values.telefon_region ||
                    getPhoneorMobileNumberRegion(values.telefon)
                )}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    'telefon',
                    e.target.value?.replace(
                      invalidPhoneNumberCharactersRegex,
                      ''
                    )
                  );
                }}
              />
            </div>
          </FieldWithErrors>

          <FieldWithErrors name="email" label={t('Email (Contact)')}>
            <Field
              as={InputText}
              name="email"
              id="email"
              value={values.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFieldValue('email', e.target.value);
              }}
            />
          </FieldWithErrors>
        </div>

        <div className={fieldClassName}>
          <FieldWithErrors name="opstina_id" label={t('Municipality')}>
            <Field
              as={Dropdown}
              name="opstina_id"
              inputId="opstina_id"
              options={municipalitiesOptions}
              filter
              filterPlaceholder={t('Search')}
              placeholder={isMunicipalitiesDataLoading ? t('Loading...') : null}
              onChange={(e: DropdownChangeParams) => {
                setFieldValue('opstina_id', e.value);

                let ime = municipalitiesOptions?.find(
                  (m) => m.value === e.value
                );
                setFieldValue('opstina_ime', ime?.label);

                reloadPlacesInMunicipalityData({
                  url: `municipalities/${e.value}/places`,
                });

                setFieldValue('mesto_id', '');
                setFieldValue('mesto_ime', '');
                setFieldValue('ulica_id', '');
                setFieldValue('ulica_ime', '');
                setFieldValue('broj', '');
                setFieldValue('vlez', '');
                setFieldValue('stan', '');
              }}
              className="data-cy-municipality"
            />
          </FieldWithErrors>

          <FieldWithErrors name="mesto_id" label={t('Place')}>
            <Field
              as={Dropdown}
              name="mesto_id"
              inputId="mesto_id"
              disabled={!values.opstina_id || isPlacesInMunicipalityDataLoading}
              options={placesOptions}
              filter
              filterPlaceholder={t('Search')}
              placeholder={
                isPlacesInMunicipalityDataLoading ? t('Loading...') : null
              }
              onChange={(e: DropdownChangeParams) => {
                if (e.value) {
                  const place = placesInMunicipalityData?.find((p) => {
                    return p.id === e.value;
                  });

                  reloadStreetsData({ url: `places/${e.value}/streets` });

                  setFieldValue('mesto_id', e.value);
                  setFieldValue('mesto_ime', place?.ime);
                } else {
                  setFieldValue('mesto_id', '');
                  setFieldValue('mesto_ime', '');
                  setFieldValue('ulica_id', '');
                  setFieldValue('ulica_ime', '');
                  setFieldValue('broj', '');
                  setFieldValue('vlez', '');
                  setFieldValue('stan', '');
                }
              }}
              className="data-cy-place"
            />
          </FieldWithErrors>

          {isStreetFreeInputAllowed ? (
            <FieldWithErrors
              name="address"
              label={isStreetFreeInputAllowed ? t('Address') : t('Street')}
              includeErrorsFor={['ulica_id', 'adresa', 'broj']}
            >
              <Field
                as={InputText}
                name="adresa"
                id="adresa"
                disabled={!values.opstina_id ?? !values.mesto_id}
                maxLength="256"
              />
            </FieldWithErrors>
          ) : (
            <>
              <FieldWithErrors name="ulica_id" label={t('Street')}>
                <Field
                  as={Dropdown}
                  name="ulica_id"
                  inputId="ulica_id"
                  disabled={
                    !values.opstina_id ||
                    !values.mesto_id ||
                    isStreetsDataLoading
                  }
                  options={streetsOptions}
                  filter
                  filterPlaceholder={t('Search')}
                  placeholder={isStreetsDataLoading ? t('Loading...') : null}
                  onChange={(e: DropdownChangeParams) => {
                    const street = streetsData?.find((s) => s.id === e.value);

                    setFieldValue('ulica_id', e.value);
                    setFieldValue('ulica_ime', street?.ime);
                  }}
                  className="data-cy-street"
                />
              </FieldWithErrors>

              <FieldWithErrors name="broj" label={t('Street No.')}>
                <Field
                  disabled={!values.ulica_id}
                  as={InputText}
                  id="broj"
                  name="broj"
                  data-cy="street_no"
                />
              </FieldWithErrors>
            </>
          )}
        </div>
      </div>

      {data?.pravno_lice === 1 && (
        <div className="p-grid p-mt-4">
          <div className={`p-py-0 ${fieldClassName}`}>
            <FieldWithErrors name="danocen_broj" label={t('Tax')}>
              <Field name="danocen_broj" as={InputText} disabled />
            </FieldWithErrors>
          </div>

          <div className={`p-py-0 ${fieldClassName}`}>
            <FieldWithErrors name="smetka" label={t('Bank Account')}>
              <Field name="smetka" as={InputText} disabled />
            </FieldWithErrors>
          </div>

          <div className={`p-py-0 ${fieldClassName}`}>
            <FieldWithErrors name="kontakt_lice" label={t('Contact person')}>
              <Field name="kontakt_lice" as={InputText} />
            </FieldWithErrors>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileContent;
