import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UnauthPage from '../../../../../page/UnauthPage';

function ResetPasswordSuccess(): JSX.Element {
  const { t } = useTranslation();

  return (
    <UnauthPage
      title={t('auth.resetPasswordSuccess.title')}
      description={t('auth.resetPasswordSuccess.description')}
    >
      <Trans t={t} i18nKey="auth.resetPasswordSuccess.content">
        <p>
          You have successfully reset your password and can now{' '}
          <Link to="/login">log in</Link> with your new password.
        </p>
      </Trans>
    </UnauthPage>
  );
}

export default ResetPasswordSuccess;
