import {
  ComponentType,
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
} from 'react';

import { Step } from './HeadlessStepper';

export type HeadlessStepperContextType = {
  steps: Step[];
  currentStep: Step;
  totalSteps: number;
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  isLastStep: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  StepComponent: ComponentType;
  handleNextBtnClick: () => Promise<void>;
  handleBackBtnClick: () => Promise<void>;
};

const HeadlessStepperContext = createContext<HeadlessStepperContextType>({
  steps: [],
  currentStep: { component: Fragment },
  totalSteps: 0,
  stepIndex: 0,
  setStepIndex: () => {},
  isLastStep: false,
  isLoading: false,
  setIsLoading: () => {},
  StepComponent: Fragment,
  handleNextBtnClick: async () => {},
  handleBackBtnClick: async () => {},
});

export default HeadlessStepperContext;
