import { Sidebar } from 'primereact/sidebar';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuContext } from '../../../context/MenuContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePrevious from '../../../hooks/usePrevious';
import Footer from '../Footer/Footer';
import Menu from '../Menu';
import styles from './SideBarWrapper.module.scss';

function SideBarWrapper(): JSX.Element {
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const { isMenuExpanded, setIsMenuExpanded } = useContext(MenuContext);

  const pathname = useLocation().pathname;
  const prevPathname = usePrevious(pathname);

  const onHide = useCallback(
    () => setIsMenuExpanded(false),
    [setIsMenuExpanded]
  );

  useEffect(() => {
    // Prevents close upon rerender
    if (pathname === prevPathname || prevPathname === undefined) {
      return;
    }

    if (isOnMobile) onHide();
  }, [isOnMobile, onHide, pathname, prevPathname]);

  return !isOnMobile ? (
    <div
      className={`${styles.sidebarMenu} ${
        isMenuExpanded ? styles.sideMenuExpanded : styles.sideMenu
      }`}
    >
      <div className={styles.itemsWrapper}>
        <Menu />
      </div>
      <Footer isMenuExpanded={isMenuExpanded} />
    </div>
  ) : (
    <Sidebar
      showCloseIcon={false}
      baseZIndex={999999}
      blockScroll
      visible={isMenuExpanded}
      position="top"
      onHide={() => setIsMenuExpanded(false)}
      style={{
        height: isOnMobile ? 'auto' : undefined,
        width: isOnMobile ? '100%' : 'auto',
      }}
    >
      <Menu />
      <Footer isMenuExpanded={isMenuExpanded} />
    </Sidebar>
  );
}

export default SideBarWrapper;
