import { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-lodash-debounce';

import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import {
  ClientAddressCollection,
  ClientAddressLookupPayload,
} from '../../../../types/api/clientAddress';
import { NearestHubResource } from '../../../../types/api/hubs';
import {
  PlaceCollection,
  PlaceCollectionQueryParams,
  PlaceStreetCollection,
} from '../../../../types/api/places';
import { LabelValue } from '../../../../types/options';
import {
  debounceTimeout,
  isStreetFreeInputAllowed,
} from '../../../../utils/constants/misc';
import { queryString } from '../../../../utils/helpers/http';
import { Action, FormFields } from './CreateEditRecreate.functions';

type Return = {
  isPlacesDataLoading: boolean;
  placesOptions: LabelValue[];
  isStreetsDataLoading: boolean;
  streetOptions: LabelValue[];
  isContactDataLoading: boolean;
  contactData: ClientAddressCollection | undefined;
  reloadContactData: () => void;
};

function useSenderStep(
  methods: UseFormReturn<FormFields>,
  defaultValues: FormFields,
  action: Action
): Return {
  const isCreating = action === 'create';

  const { control, setValue } = methods;

  const mesto_od_id = useWatch<FormFields, 'mesto_od_id'>({
    name: 'mesto_od_id',
    control,
  });

  const mesto_od_ime = useWatch<FormFields, 'mesto_od_ime'>({
    name: 'mesto_od_ime',
    control,
  });

  const broj_od = useWatch<FormFields, 'broj_od'>({
    name: 'broj_od',
    control,
  });

  const ulica_od_ime = useWatch<FormFields, 'ulica_od_ime'>({
    name: 'ulica_od_ime',
    control,
  });

  const adresa_od = useWatch<FormFields, 'adresa_od'>({
    name: 'adresa_od',
    control,
  });

  const klient_od_ime = useWatch<FormFields, 'klient_od_ime'>({
    name: 'klient_od_ime',
    control,
  });

  const prev_mesto_od_id = usePrevious(mesto_od_id);

  const debounced_mesto_od_ime = useDebounce(mesto_od_ime, debounceTimeout);

  const { data: placesData, isLoading: isPlacesDataLoading } =
    useAxios<PlaceCollection>(
      '/places' +
        queryString<PlaceCollectionQueryParams>({
          page: 1,
          limit: 15,
          place: debounced_mesto_od_ime,
        })
    );

  const placesOptions = useMemo(
    () =>
      placesData?.data.map((place) => {
        return {
          value: String(place.place_id),
          label: place.place_name,
          postal_code: place.place_postal_code,
        };
      }) ?? [],
    [placesData]
  );

  const { data: streetsData, isLoading: isStreetsDataLoading } =
    useAxios<PlaceStreetCollection>(
      {
        url: `places/${mesto_od_id}/streets`,
      },
      {
        skipWhen: !mesto_od_id,
      }
    );

  const streetOptions: LabelValue[] = useMemo(
    () =>
      streetsData?.map((street) => {
        return { label: street.ime, value: String(street.id) };
      }) ?? [],
    [streetsData]
  );

  function getNearestHubAddress() {
    const trimmedStreetName = ulica_od_ime?.trim();
    const streetNo = broj_od ? ' ' + broj_od : '';

    return isStreetFreeInputAllowed
      ? adresa_od
      : trimmedStreetName
      ? trimmedStreetName + streetNo
      : null;
  }

  const { data: nearestHub } = useAxios<NearestHubResource>(
    {
      url: '/hubs/nearest',
      data: {
        place_id: mesto_od_id,
        address_from: getNearestHubAddress(),
      },
      method: 'POST',
    },
    {
      skipWhen:
        !mesto_od_id ||
        (!isCreating && defaultValues.mesto_od_id === mesto_od_id),
    }
  );

  useEffect(() => {
    if (mesto_od_id) {
      return;
    }

    setValue('ulica_od_id', '');
    setValue('ulica_od_ime', '');
    setValue('adresa_od', '');
    setValue('broj_od', '');
    setValue('vlez_od', '');
    setValue('stan_od', '');
  }, [mesto_od_id, setValue]);

  useEffect(() => {
    const isSenderPlaceModified = defaultValues.mesto_od_id !== mesto_od_id;

    const nearestHubId = isSenderPlaceModified
      ? nearestHub && mesto_od_id && mesto_od_id === prev_mesto_od_id
        ? String(nearestHub.id)
        : null
      : defaultValues.hub_od_id;

    setValue('hub_od_id', nearestHubId);
  }, [
    defaultValues.hub_od_id,
    defaultValues.mesto_od_id,
    mesto_od_id,
    nearestHub,
    prev_mesto_od_id,
    setValue,
  ]);

  const debouncedClientName = useDebounce(klient_od_ime, debounceTimeout);

  const {
    data: contactData,
    isLoading: isContactDataLoading,
    reload,
  } = useAxios<ClientAddressCollection>(
    '/client-addresses' +
      queryString<ClientAddressLookupPayload>({ contact: debouncedClientName })
  );

  return {
    isPlacesDataLoading,
    placesOptions,
    isStreetsDataLoading,
    streetOptions,
    isContactDataLoading,
    contactData,
    reloadContactData: reload,
  };
}

export default useSenderStep;
