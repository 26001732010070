import './FileImport.scss';

import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';

import { FileTypes } from '../../../../../../../enums/files';
import { cleanUpCsv } from '../../../../../../../utils/helpers/files';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { csvDelimiter } from '../../FileImporter.functions';

function FileImport(): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();

  async function uploadHandler(evt: FileUploadSelectParams) {
    const file = evt.files[0];

    if (file.size > 1000000) {
      return;
    }

    const fileReader = new FileReader();

    // The logic here is to first parse the file with XLSX - since this library has a powerful parser,
    //  it'd be best to avoid parsing it ourselves. The approach is to simply normalize it,
    //  i.e., act like we've imported an excel file, and then cleanup the potentially messy CSV.
    // The internal functions of "cleanUpCsv" collide when there's a bad CSV. Example:
    //  "Header 1; Header 2; Header 3; Header 4"
    //  "Value11;Value12;Value13;Value14"
    //  "Value21;Value22;Value23;Value24"
    //  "Value31;Value32;Value33;Value34"
    // XLSX can't possibly parse this as well, but according to the CSV only cells can be wrapped in quotes
    //  and it doesn't make sense to wrap rows in quotes. So, the joke is on the user.
    if (file.type === FileTypes.CSV) {
      fileReader.onload = (e) => {
        if (!e.target?.result || typeof e.target.result !== 'string') {
          return;
        }

        // Normalize CSV file before processing
        const workbook = XLSX.read(e.target.result, { type: 'string' });
        const firstSheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        const csvData = XLSX.utils.sheet_to_csv(firstSheet, {
          FS: csvDelimiter,
          forceQuotes: true,
        });

        // CSV cleanup
        const cleanedUpCsvData = cleanUpCsv(csvData, csvDelimiter);

        setFieldValue('csv', cleanedUpCsvData);
        setFieldValue('delimiter', csvDelimiter);
      };

      fileReader.readAsText(file);
    } else {
      fileReader.onload = (e) => {
        if (!e.target?.result || typeof e.target.result === 'string') {
          return;
        }

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        const csvData = XLSX.utils.sheet_to_csv(firstSheet, {
          FS: csvDelimiter,
          forceQuotes: true,
        });

        const cleanedUpCsvData = cleanUpCsv(csvData, csvDelimiter);

        setFieldValue('csv', cleanedUpCsvData);
        setFieldValue('delimiter', csvDelimiter);
      };

      fileReader.readAsArrayBuffer(file);
    }
  }

  return (
    <div className="upload-file">
      <FieldWithErrors name="csv" label={false} className="upload-file">
        <Field
          name="csv"
          as={FileUpload}
          id="csv-file-upload"
          customUpload
          mode="advanced"
          accept=".xlsx, .xls, .csv, .tsv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          maxFileSize={1000000}
          onSelect={uploadHandler}
          chooseLabel={t('Upload')}
          className="p-button-outlined"
          onRemove={() => setFieldValue('csv', '')}
          icon="fas fa-upload"
          disabled={!!values?.csv}
        />
      </FieldWithErrors>

      <Tooltip target=".import-all-or-nothing" />

      <div
        className="p-d-inline-block p-pr-3 import-all-or-nothing"
        data-pr-tooltip={t(
          'If an error occurs in a single order, all orders will be rolled back.'
        )}
      >
        <Field
          inputId="import_all_or_none"
          name="import_all_or_none"
          as={Checkbox}
          checked={values.import_all_or_none}
        />

        <label htmlFor="import_all_or_none">{t('Import all or nothing')}</label>
      </div>
    </div>
  );
}

export default FileImport;
