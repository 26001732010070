import { useContext } from 'react';

import HeadlessStepperContext from '../HeadlessStepperContext';

function Content(): JSX.Element {
  const { StepComponent } = useContext(HeadlessStepperContext);

  return (
    <div className="stepper-content">
      <StepComponent />
    </div>
  );
}

export default Content;
