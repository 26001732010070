import { SelectButton } from 'primereact/selectbutton';
import { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import Step from '../../../../../Stepper/Inline/Step';
import { FormValues } from '../WebImporter.functions';

function Payments(): JSX.Element {
  const { t } = useTranslation();

  const _listType = useWatch<FormValues, '_listType'>({
    name: '_listType',
  });

  const paymentOptions = useMemo(() => {
    let options = [
      {
        label: t('Recipient'),
        value: '2',
      },
      {
        label: t('Sender'),
        value: '1',
      },
    ];

    if (_listType?.klient_isSender === 0) {
      options.push({ label: t('Orderer'), value: '0' });
    }

    return options;
  }, [_listType?.klient_isSender, t]);

  return (
    <Step
      title={t('Payments')}
      subtitle={t('Who pays for what?')}
      childrenWrapperClassName="variable-payments"
      fullWidth
    >
      <FieldWithErrors label={t('Postage')} name="postarina">
        <Controller
          name="postarina"
          render={({ field }) => (
            <SelectButton
              id="postarina"
              options={paymentOptions}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors label={t('COD Fee')} name="otkup">
        <Controller
          name="otkup"
          render={({ field }) => (
            <SelectButton
              id="otkup"
              options={paymentOptions}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors label={t('Return document')} name="povraten_dokument">
        <Controller
          name="povraten_dokument"
          render={({ field }) => (
            <SelectButton
              id="povraten_dokument"
              options={paymentOptions}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors label={t('Insurance')} name="osiguruvanje">
        <Controller
          name="osiguruvanje"
          render={({ field }) => (
            <SelectButton
              id="osiguruvanje"
              options={paymentOptions}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
            />
          )}
        />
      </FieldWithErrors>
    </Step>
  );
}

export default Payments;
