import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export type FormValues = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export const initialValues = {
  username: '',
  password: '',
  rememberMe: false,
};

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    username: Yup.string().required(t('Username is a required field')).min(1),
    password: Yup.string().required(t('Password is a required field')).min(1),
  });
}
