import { Toast } from 'primereact/toast';
import { RefObject, createContext } from 'react';

type Context = {
  toastRef?: RefObject<Toast>;
  bottomRightToastRef?: RefObject<Toast>;
};

const ToastContext = createContext<Context>({});

export default ToastContext;
