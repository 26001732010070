import classNames from 'classnames';
import { HTMLAttributes, ReactNode, Ref, forwardRef } from 'react';

import styles from './Flex.module.scss';

type Props = {
  children: ReactNode;
  direction?: string;
} & HTMLAttributes<HTMLDivElement>;

const Flex = forwardRef(
  (
    { children, direction = 'row', ...other }: Props,
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    return (
      <div
        ref={ref}
        {...other}
        className={classNames(
          direction === 'row' ? styles.flex : styles.flexColumn
        )}
      >
        {children}
      </div>
    );
  }
);

export default Flex;
