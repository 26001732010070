import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {
  DataTableProps,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { TableProps } from '../../features/DataTable/Table/Table';
import { Numeric } from '../../types/general';
import { emptyCell } from '../constants/tables';
import { userPrefixedString } from './user';

export function getSavedSelectedColumns(
  key: string,
  defaultColumnOptions: object[]
): object[] {
  let returnVal: object[] = defaultColumnOptions;

  try {
    const storageVal = sessionStorage.getItem(userPrefixedString(key));

    if (storageVal) {
      const parsedStorageVal = JSON.parse(storageVal);

      if (Array.isArray(parsedStorageVal)) {
        returnVal = parsedStorageVal;
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return returnVal;
}

export function dateToFilterText(date: any): string {
  const first = dayjs(date[0]).format('DD.MM.YY');

  return date[1] ? `${first} - ${dayjs(date[1]).format('DD.MM.YY')}` : first;
}

export function singleDateToFilterText(date: any): string {
  return dayjs(date).format('DD.MM.YY');
}

export function multipleSelectionModeContextMenuChange(
  e: DataTableSelectionChangeParams,
  selectedRows: DataTableProps['selection'],
  setSelectedRows: Dispatch<SetStateAction<DataTableProps['selection']>>,
  selectionModifier?: TableProps['selectionModifier']
) {
  const isSelectedItemAlreadySelected = (
    Array.isArray(selectedRows) ? selectedRows : []
  )?.find((sr: { id: any }) => sr.id === e.value.id);

  if (!isSelectedItemAlreadySelected) {
    setSelectedRows(
      typeof selectionModifier === 'function'
        ? selectionModifier([e.value])
        : [e.value]
    );
  }
}

export function dateCell(date: string | null) {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('DD MMM YYYY');
  }

  return emptyCell;
}

export function dateTimeCell(date: string | Date | null) {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('DD MMM YYYY, HH:mm:ss');
  }

  return emptyCell;
}

export function weightCell(t: TFunction, value: Numeric): JSX.Element {
  return (
    <>
      {value} {t('kg')}
    </>
  );
}
