import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage } from '../../redux/actions/languageActions';
import { ReduxState } from '../../types/redux';

function LanguageSelector(): JSX.Element {
  const { i18n } = useTranslation();
  const menuLang = useRef<any>(null);
  const dispatch = useDispatch();
  const language = useSelector((state: ReduxState) => state.language);

  useLayoutEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const languageToDisplay = (() => {
    switch (language) {
      case 'sr-Cyrl':
        return 'СР';
      case 'sr-Latn':
        return 'SR';
      default:
        return language.toUpperCase();
    }
  })();

  const languages = [
    {
      label: 'English',
      icon: 'flag us-flag',
      command: () => {
        dispatch(changeLanguage('en'));
      },
    },
    {
      label: 'Македонски',
      icon: 'flag mk-flag',
      command: () => {
        dispatch(changeLanguage('mk'));
      },
    },
    {
      label: 'Српски (кирилица)',
      icon: 'flag rs-flag',
      command: () => {
        dispatch(changeLanguage('sr-Cyrl'));
      },
    },
    {
      label: 'Srpski (latinica)',
      icon: 'flag rs-flag',
      command: () => {
        dispatch(changeLanguage('sr-Latn'));
      },
    },
  ];

  return (
    <div className="cart flex justify-content-center">
      <Menu model={languages} popup ref={menuLang} id="menu_lang" />
      <Button
        icon={`flag ${
          language === 'sr-Cyrl' || language === 'sr-Latn'
            ? 'rs-flag'
            : language === 'en'
            ? 'us-flag'
            : `${language}-flag`
        }`}
        label={languageToDisplay}
        onClick={(event) => menuLang.current?.toggle(event)}
        aria-controls="menu_lang"
        aria-haspopup
        className="p-button-outlined menu-button"
      />
    </div>
  );
}

export default LanguageSelector;
