import { Button, ButtonProps } from 'primereact/button';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../hooks/useMediaQuery';
import HeadlessStepperContext from '../HeadlessStepperContext';

export type FooterProps = {
  onCancelBtnClick: () => void;
  isStepIndicatorShown: boolean;
  backButtonProps?: Record<string, any> & ButtonProps;
  nextButtonProps?: Record<string, any> & ButtonProps;
  cancelButtonProps?: Record<string, any> & ButtonProps;
  submitBtnLabel?: string;
};

function Footer({
  onCancelBtnClick,
  isStepIndicatorShown,
  backButtonProps,
  nextButtonProps,
  cancelButtonProps,
  submitBtnLabel,
}: FooterProps): JSX.Element {
  const { t } = useTranslation();

  const headlessStepperContext = useContext(HeadlessStepperContext);
  const {
    steps,
    stepIndex,
    currentStep,
    isLoading,
    isLastStep,
    handleBackBtnClick,
    handleNextBtnClick,
  } = headlessStepperContext;

  const isMobileLayout = useMediaQuery('(max-width: 560px)');

  return (
    <div className="p-d-flex p-jc-between p-ai-center footer">
      <div>
        <Button
          type="button"
          label={t('Cancel')}
          className="p-button-secondary p-button-text"
          onClick={onCancelBtnClick}
          {...(cancelButtonProps as any)}
        />
      </div>

      {isStepIndicatorShown && !isMobileLayout && (
        <div className="p-d-flex p-jc-center p-ai-center">
          {steps.map((_, idx) => {
            return (
              <div
                className={`step-dot ${idx === stepIndex && 'active'}`}
                key={idx}
              />
            );
          })}
        </div>
      )}

      <div className="nav-buttons-right p-d-flex">
        {stepIndex > 0 && (
          <Button
            type="button"
            label={isLoading ? t('Loading...') : t('Back')}
            onClick={handleBackBtnClick}
            disabled={isLoading}
            icon="fa fa-angle-left"
            className="p-button-text"
            {...(backButtonProps as any)}
          />
        )}

        <Button
          type={isLastStep ? 'submit' : 'button'}
          label={
            isLoading
              ? t('Loading...')
              : currentStep.nextButtonLabel?.(headlessStepperContext) ??
                (isLastStep ? submitBtnLabel ?? t('Submit') : t('Next'))
          }
          icon={isLastStep ? undefined : 'fas fa-angle-right'}
          iconPos="right"
          disabled={isLoading}
          onClick={(e) => {
            // Solves a nasty bug that makes the form submit before the last step even renders
            if (!isLastStep) {
              e.preventDefault();
            }

            handleNextBtnClick();
          }}
          className={isLastStep ? 'p-button-outlined' : 'p-button-primary'}
          {...(nextButtonProps as any)}
        />
      </div>
    </div>
  );
}

export default Footer;
