import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import { loginUser } from '../../../../redux/actions/userActions';
import { LoginResponse } from '../../../../types/api/users';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import UnauthPage from '../../../page/UnauthPage';
import {
  FormValues,
  getValidationSchema,
  initialValues,
} from './Login.functions';

function Login(): JSX.Element {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<FormValues>>(null);

  const dispatch = useDispatch();

  const { data, error, isLoading, reload } = useAxios<LoginResponse>(
    { url: '/users/login', method: 'POST' },
    { skipWhen: true }
  );

  const prevData = usePrevious(data);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }
    dispatch(loginUser(data, formRef.current?.values.rememberMe, Date.now()));
  }, [data, dispatch, prevData]);

  function handleSubmit(values: { username: string; password: string }) {
    reload({
      data: {
        username: values.username,
        password: values.password,
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      },
    });
  }

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  return (
    <UnauthPage
      title={t('Welcome Back')}
      pageTitle={t('Login')}
      description={t('Enter your credentials to access the customer website.')}
      error={
        error?.response?.status === 401
          ? t('Incorrect username or password')
          : undefined
      }
    >
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, isValid }) => (
          <Form>
            <div className="p-fluid">
              <FieldWithErrors name="username" label={t('Username')}>
                <Field
                  id="username"
                  name="username"
                  className="p-inputtext p-component"
                />
              </FieldWithErrors>

              <FieldWithErrors name="password" label={t('Password')}>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="p-inputtext p-component"
                />
              </FieldWithErrors>

              <Field
                as={Checkbox}
                inputId="rememberMe"
                name="rememberMe"
                checked={values.rememberMe}
              />
              <label htmlFor="rememberMe" className="p-checkbox-label">
                {t('Remember me')}
              </label>

              <p className="p-mt-5 p-mb-0 p-text-secondary p-text-light">
                {t('Not registered yet?')}{' '}
                <Link to="/register">{t('Register')}</Link>
              </p>

              <Button
                type="submit"
                label={t('Login')}
                className="p-mt-5"
                disabled={!isValid || isLoading}
              />

              <Link
                to="/profile/forgot-password"
                className="p-d-block p-text-center p-mt-3"
              >
                {t('Forgot your password?')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </UnauthPage>
  );
}

export default Login;
