import { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-lodash-debounce';

import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import {
  ClientAddressCollection,
  ClientAddressLookupPayload,
} from '../../../../types/api/clientAddress';
import { NearestHubResource } from '../../../../types/api/hubs';
import {
  PlaceCollection,
  PlaceCollectionQueryParams,
  PlaceStreetCollection,
} from '../../../../types/api/places';
import { LabelValue } from '../../../../types/options';
import {
  debounceTimeout,
  isStreetFreeInputAllowed,
} from '../../../../utils/constants/misc';
import { queryString } from '../../../../utils/helpers/http';
import { Action, FormFields } from './CreateEditRecreate.functions';

type Return = {
  isPlacesDataLoading: boolean;
  placesOptions: LabelValue[];
  isStreetsDataLoading: boolean;
  streetOptions: LabelValue[];
  isContactDataLoading: boolean;
  contactData: ClientAddressCollection | undefined;
  reloadContactData: () => void;
};

function useRecipientStep(
  methods: UseFormReturn<FormFields>,
  defaultValues: FormFields,
  action: Action
): Return {
  const isCreating = action === 'create';

  const { control, setValue } = methods;

  const mesto_do_id = useWatch<FormFields, 'mesto_do_id'>({
    name: 'mesto_do_id',
    control,
  });

  const mesto_do_ime = useWatch<FormFields, 'mesto_do_ime'>({
    name: 'mesto_do_ime',
    control,
  });

  const broj_do = useWatch<FormFields, 'broj_do'>({
    name: 'broj_do',
    control,
  });

  const ulica_do_ime = useWatch<FormFields, 'ulica_do_ime'>({
    name: 'ulica_do_ime',
    control,
  });

  const adresa_do = useWatch<FormFields, 'adresa_do'>({
    name: 'adresa_do',
    control,
  });

  const klient_do_ime = useWatch<FormFields, 'klient_do_ime'>({
    name: 'klient_do_ime',
    control,
  });

  const prev_mesto_do_id = usePrevious(mesto_do_id);

  const debounced_mesto_do_ime = useDebounce(mesto_do_ime, debounceTimeout);

  const { data: placesData, isLoading: isPlacesDataLoading } =
    useAxios<PlaceCollection>(
      '/places' +
        queryString<PlaceCollectionQueryParams>({
          page: 1,
          limit: 15,
          place: debounced_mesto_do_ime,
        })
    );

  const placesOptions = useMemo(
    () =>
      placesData?.data.map((place) => {
        return {
          value: String(place.place_id),
          label: place.place_name,
          postal_code: place.place_postal_code,
        };
      }) ?? [],
    [placesData]
  );

  const { data: streetsData, isLoading: isStreetsDataLoading } =
    useAxios<PlaceStreetCollection>(
      {
        url: `places/${mesto_do_id}/streets`,
      },
      {
        skipWhen: !mesto_do_id,
      }
    );

  const streetOptions: LabelValue[] = useMemo(
    () =>
      streetsData?.map((street) => {
        return { label: street.ime, value: String(street.id) };
      }) ?? [],
    [streetsData]
  );

  function getNearestHubAddress() {
    const trimmedStreetName = ulica_do_ime?.trim();
    const streetNo = broj_do ? ' ' + broj_do : '';

    return isStreetFreeInputAllowed
      ? adresa_do
      : trimmedStreetName
      ? trimmedStreetName + streetNo
      : null;
  }

  const { data: nearestHub } = useAxios<NearestHubResource>(
    {
      url: '/hubs/nearest',
      data: {
        place_id: mesto_do_id,
        address_from: getNearestHubAddress(),
      },
      method: 'POST',
    },
    {
      skipWhen:
        !mesto_do_id ||
        (!isCreating && defaultValues.mesto_do_id === mesto_do_id),
    }
  );

  useEffect(() => {
    if (mesto_do_id) {
      return;
    }

    setValue('ulica_do_id', '');
    setValue('ulica_do_ime', '');
    setValue('adresa_do', '');
    setValue('broj_do', '');
    setValue('vlez_do', '');
    setValue('stan_do', '');
  }, [mesto_do_id, setValue]);

  useEffect(() => {
    const isSenderPlaceModified = defaultValues.mesto_do_id !== mesto_do_id;

    const nearestHubId = isSenderPlaceModified
      ? nearestHub && mesto_do_id && mesto_do_id === prev_mesto_do_id
        ? String(nearestHub.id)
        : null
      : defaultValues.hub_do_id;

    setValue('hub_do_id', nearestHubId);
  }, [
    defaultValues.hub_do_id,
    defaultValues.mesto_do_id,
    mesto_do_id,
    nearestHub,
    prev_mesto_do_id,
    setValue,
  ]);

  const debouncedClientName = useDebounce(klient_do_ime, debounceTimeout);

  const {
    data: contactData,
    isLoading: isContactDataLoading,
    reload,
  } = useAxios<ClientAddressCollection>(
    '/client-addresses' +
      queryString<ClientAddressLookupPayload>({ contact: debouncedClientName })
  );

  return {
    isPlacesDataLoading,
    placesOptions,
    isStreetsDataLoading,
    streetOptions,
    isContactDataLoading,
    contactData,
    reloadContactData: reload,
  };
}

export default useRecipientStep;
