import * as Sentry from '@sentry/react';
import { locale } from 'primereact/api';
import { Middleware } from 'redux';

import { setDayjsLocale } from '../../utils/helpers/config';
import {
  AllowedLanguages,
  CHANGE_LANGUAGE,
  changeLanguage,
} from '../actions/languageActions';

function persistLanguageToStorage(language: AllowedLanguages) {
  try {
    localStorage.setItem('language', language);
  } catch (e) {
    Sentry.captureException(e);
  }
}

const languageMiddleware: Middleware = (_) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case CHANGE_LANGUAGE:
      locale((action as ReturnType<typeof changeLanguage>).language);
      setDayjsLocale((action as ReturnType<typeof changeLanguage>).language);
      persistLanguageToStorage(
        (action as ReturnType<typeof changeLanguage>).language
      );
      break;

    default:
      return;
  }
};

export default languageMiddleware;
