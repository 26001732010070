import { LabelValue } from '../../../types/options';

export function emptyItemTemplate<T>(option: LabelValue<T>): JSX.Element {
  return <i>{option.label}</i>;
}

export function findOption<T>(
  value: T,
  options: LabelValue<T>[]
): LabelValue<T> | undefined {
  return options.find((o) => o.value === value);
}
