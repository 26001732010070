export enum Country {
  Macedonia = 'mk',
  Serbia = 'rs',
}

export enum CountryPhone {
  Macedonia = 'mk',
  Serbia = 'rs',
  Russia = 'ru',
  Belgium = 'be',
  Bulgaria = 'bg',
  Czechia = 'cz',
  Denmark = 'dk',
  Germany = 'de',
  Estonia = 'ee',
  Ireland = 'ie',
  Greece = 'gr',
  Spain = 'es',
  France = 'fr',
  Croatia = 'hr',
  Italy = 'it',
  Cyprus = 'cy',
  Latvia = 'lv',
  Lithuania = 'lt',
  Luxembourg = 'lu',
  Hungary = 'hu',
  Malta = 'mt',
  Netherlands = 'nl',
  Austria = 'at',
  Poland = 'pl',
  Portugal = 'pt',
  Romania = 'ro',
  Slovenia = 'sl',
  Slovakia = 'sk',
  Finland = 'fi',
  Sweden = 'se',
  Iceland = 'is',
  Liechtenstein = 'li',
  Norway = 'no',
  Switzerland = 'ch',
  UnitedKingdom = 'gb',
  Montenegro = 'me',
  Albania = 'al',
  Turkey = 'tr',
  BosniaAndHerzegovina = 'ba',
  Kosovo = 'xk',
  Ukraine = 'ua',
  Georgia = 'ge',
  Moldova = 'md',
  Armenia = 'am',
  Azerbaijan = 'az',
  China = 'cn',
  Brazil = 'br',
  Australia = 'au',
  Canada = 'ca',
  Japan = 'jp',
  Mexico = 'mx',
  Taiwan = 'tw',
  UnitedStates = 'us',
  Singapore = 'sg',
  SouthKorea = 'kr',
}
