import { Dispatch, SetStateAction, createContext } from 'react';

import { useLocalStorage } from '../hooks/useLocalStorage';

type Context = {
  isFiltersExpanded: boolean;
  setIsFiltersExpanded: Dispatch<SetStateAction<boolean>>;
};

const SidebarFiltersContext = createContext<Context>({
  isFiltersExpanded: true,
  setIsFiltersExpanded: () => {},
});

type Props = {
  children: React.ReactNode;
};

function SidebarFiltersContextProvider({ children }: Props) {
  const [isFiltersExpanded, setIsFiltersExpanded] = useLocalStorage(
    'filtersExpanded',
    true
  );

  return (
    <SidebarFiltersContext.Provider
      value={{ isFiltersExpanded, setIsFiltersExpanded }}
    >
      {children}
    </SidebarFiltersContext.Provider>
  );
}

export { SidebarFiltersContext, SidebarFiltersContextProvider };
