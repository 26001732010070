import { useCallback } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../context/ToastContext';
import useNetworkStatus from '../../../hooks/useNetworkStatus';
import { infoToast, warnToast } from '../../../utils/helpers/primereact';

function NetworkNotifier(): null {
  const { t } = useTranslation();

  const { bottomRightToastRef } = useContext(ToastContext);

  const onlineCallback = useCallback(() => {
    if (!bottomRightToastRef?.current) {
      return;
    }

    infoToast(
      bottomRightToastRef,
      t('Back online'),
      t('Your network connection has been restored.')
    );
  }, [bottomRightToastRef, t]);

  const offlineCallback = useCallback(() => {
    if (!bottomRightToastRef?.current) {
      return;
    }

    warnToast(
      bottomRightToastRef,
      t('Offline'),
      t('You are currently offline. Please check your network connection.')
    );
  }, [bottomRightToastRef, t]);

  useNetworkStatus(onlineCallback, offlineCallback);

  return null;
}

export default NetworkNotifier;
