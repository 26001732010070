import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';

function useErrorState<T>(name: keyof T, methods: UseFormReturn<T>) {
  const {
    formState: { errors },
  } = methods;

  const hasError = !!(errors as any)[name];
  const errorMessage = (errors as any)[name]?.message;

  const parentClassName = classNames('rhf-field-with-errors', {
    invalid: hasError,
  });

  const error: JSX.Element | undefined = hasError ? (
    <span className="error">
      {typeof errorMessage === 'string'
        ? errorMessage
        : Array.isArray(errorMessage)
        ? errorMessage.join()
        : String(errorMessage)}
    </span>
  ) : undefined;

  return { error, parentClassName };
}

export default useErrorState;
