import { RefObject, useEffect, useState } from 'react';

export function useSidebarFiltersHeight(
  ref: RefObject<HTMLDivElement>,
  expanded: boolean,
  filterHeight?: number
) {
  const [height, setHeight] = useState(40);

  useEffect(() => {
    function handleResize() {
      setHeight(expanded ? (filterHeight ? filterHeight : 250) : 40);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [ref, expanded, filterHeight]);

  return height;
}
