import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { UserActivateRequestPayload } from '../../../../types/api/users';
import { yupRequiredPasswordField } from '../../../../utils/helpers/yup';

export type FormFields = {
  code: string;
  password: string;
  repassword: string;
};

export type SuccessObj = {
  message?: string;
};

export function getInitialValues(code: string): FormFields {
  return {
    code: code,
    password: '',
    repassword: '',
  };
}

export function toApiData(formValues: FormFields): UserActivateRequestPayload {
  return {
    code: formValues.code,
    password: formValues.password,
    repassword: formValues.repassword,
  };
}

export function getValidationSchema(t: TFunction): Yup.AnyObjectSchema {
  return Yup.object().shape({
    password: yupRequiredPasswordField(t, 'Password'),
    repassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('Passwords must match'))
      .required(t('Confirm password is required')),
  });
}

export function generateErrorMsg(t: TFunction, err: any) {
  const errorMsg = err.error_description.code;

  switch (errorMsg) {
    case 'invalid_parameter': {
      return t(
        'The code is invalid. Code does not exist or has expired or is associated with a non-existent user.'
      );
    }

    case 'empty': {
      return t('There is no code. Code is required for user activation.');
    }

    case 'invalid_user_status': {
      return t('Invalid status. Client is already registered..');
    }

    default: {
      return t('Unknown error');
    }
  }
}
