import { OrderStatus } from '../enums/orders';

type OrderStatusConfig = {
  color?: string;
};

export function getOrderStatusConfig(): Record<OrderStatus, OrderStatusConfig> {
  return {
    [OrderStatus.CreatedAddress]: {
      color: 'var(--blue-500)',
    },
    [OrderStatus.CreatedAddressFromCounter]: {
      color: 'var(--blue-500)',
    },
    [OrderStatus.Created]: {
      color: 'var(--blue-500)',
    },
    [OrderStatus.CreatedAddressFromMobile]: {
      color: 'var(--blue-500)',
    },
    [OrderStatus.CreatedGroupOrder]: {
      color: 'var(--blue-500)',
    },
    [OrderStatus.AssignedToAReceptionCourier]: {
      color: 'var(--cyan-700)',
    },
    [OrderStatus.UnsuccessfulPickup]: {
      color: '#d32f2f',
    },
    [OrderStatus.Cancelled]: {
      color: 'grey',
    },
    [OrderStatus.PickedUp]: {
      color: 'var(--teal-300)',
    },
    [OrderStatus.Sorting]: {
      color: '#c799a1',
    },
    [OrderStatus.AwaitingTransfer]: {
      color: '#d32f2f',
    },
    [OrderStatus.AwaitingControl]: {
      color: 'var(--yellow-400)',
    },
    [OrderStatus.AwaitingSecondControl]: {
      color: 'var(--yellow-600)',
    },
    [OrderStatus.AwaitingPickupFromClient]: {
      color: 'var(--purple-400)',
    },
    [OrderStatus.AwaitingPickupFromPostOffice]: {
      color: 'var(--teal-200)',
    },
    [OrderStatus.HandedOverToARelocationCourier]: {
      color: '#903345',
    },
    [OrderStatus.HandedOverToADeliveryCourier]: {
      color: 'var(--blue-600)',
    },
    [OrderStatus.UnsuccessfulDeliveryAttempt]: {
      color: '#d32f2f',
    },
    [OrderStatus.MarkedForReturn]: {
      color: 'var(--purple-400)',
    },
    [OrderStatus.Returned]: {
      color: 'var(--purple-400)',
    },
    [OrderStatus.MarkedAsDeliveredCourier]: {
      color: 'var(--green-200)',
    },
    [OrderStatus.MarkedAsDeliveredWarehouse]: {
      color: 'var(--green-300)',
    },
    [OrderStatus.Delivered]: {
      color: 'var(--green-500)',
    },
  };
}
