import { AxiosRequestConfig } from 'axios';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { tryString } from '../utils/helpers/parse';
import { getSearchQueryParam } from '../utils/helpers/searchQuery';
import useAxios from './useAxios';
import useSearchQueryParam from './useSearchQueryParam';

// Because we are dealing with an autocomplete component, data on first page load might not be available.
//  The following parameters are used to deal with these kind of scenarios:
//
//    - axiosRequestConfig - custom useAxios request config to load the initial data with
//    - initialFilterValueDataPath - defines which string will the filterValue be set upon initial data arrival.
//       In some scenarios, it might be a compound string (e.g., Employee.ime + ' ' + Employee.prezime), hence the need for it to be a function
//
//    - filterValueObjPath - which key of filterValueObj to set in the useSearchQueryParam
//    - initialDataModifier - sometimes, the API data for the entity in question might be different from what we expect.
//       However, the type of filterValueObj must be kept consistent.
//       Example: GET /employees/{id} returns an Employee wrapped in an object, but we store only (unwrapped) Employee data in filterValueObj.
//       This function can be used for such transformations of the initial data we load

function useSearchQueryAutoCompleteInputParam<T, D extends object>({
  param,
  filterValue,
  setFilterValue,
  filterValueObj,
  setFilterValueObj,
  axiosRequestConfig,
  initialFilterValueDataPath,
  filterValueObjPath,
  initialDataModifier,
}: {
  param: string;
  filterValue: string;
  setFilterValue: Dispatch<SetStateAction<string>>;
  filterValueObj: T | null;
  setFilterValueObj: Dispatch<SetStateAction<T | null>>;
  axiosRequestConfig: (
    initialParamValue: string | undefined
  ) => AxiosRequestConfig | string;
  initialFilterValueDataPath: keyof D | ((initialData: D) => string);
  filterValueObjPath: keyof T;
  initialDataModifier: (data: D) => T;
}) {
  const location = useLocation();

  const isFilterInitialized = useRef<boolean>(false);

  const [initialFilterValue] = useState(() =>
    getSearchQueryParam(location.search, param)
  );

  const { data: initialData, error: initialError } = useAxios<D>(
    axiosRequestConfig(initialFilterValue),
    {
      skipWhen: !initialFilterValue,
    }
  );

  useEffect(() => {
    // If the input has been modified when the data comes back, do nothing
    if (
      !initialData ||
      filterValue ||
      filterValueObj ||
      isFilterInitialized.current
    ) {
      return;
    }

    isFilterInitialized.current = true;

    setFilterValue(
      typeof initialFilterValueDataPath === 'function'
        ? initialFilterValueDataPath(initialData)
        : tryString(initialData?.[initialFilterValueDataPath]) ?? ''
    );

    setFilterValueObj(initialDataModifier(initialData));
  }, [
    filterValue,
    filterValueObj,
    initialData,
    initialDataModifier,
    initialFilterValueDataPath,
    setFilterValue,
    setFilterValueObj,
  ]);

  useEffect(() => {
    if (
      !initialError ||
      filterValue ||
      filterValueObj ||
      isFilterInitialized.current
    ) {
      return;
    }

    isFilterInitialized.current = true;

    setFilterValue('');
    setFilterValueObj(null);
  }, [
    filterValue,
    filterValueObj,
    initialError,
    setFilterValue,
    setFilterValueObj,
  ]);

  useSearchQueryParam(
    param,
    isFilterInitialized.current || filterValueObj
      ? tryString(filterValueObj?.[filterValueObjPath]) ?? ''
      : initialError
      ? ''
      : initialFilterValue ?? ''
  );
}

export default useSearchQueryAutoCompleteInputParam;
