import './ErrorPage.scss';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import illustration401 from '../../assets/svg/401-error-unauthorized-amico.svg';

function Page401(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="page-401">
      <div className="error-container p-shadow-1">
        <h1>{t('Unauthorized')}</h1>

        <p>
          {t('We could not confirm your identity.')}
          <br />
          {t('You have been logged out for security reasons.')}
        </p>

        <img src={illustration401} alt="" />

        <p>
          {t('Please')} <Link to="/login">{t('log in')}</Link> {t('again')}.
        </p>
      </div>
    </div>
  );
}

export default Page401;
