import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import {
  FormFields,
  getLetterTypeOptions,
  getOrderPackagingOptions,
} from '../../../CreateEditRecreate.functions';

type Props = {
  type?: 'package' | 'letter';
};

function PackagingServices({ type = 'package' }: Props): JSX.Element {
  const { t } = useTranslation();
  const { setValue } = useFormContext<FormFields>();

  const orderPackagingOptions = useMemo(() => getOrderPackagingOptions(t), [t]);
  const orderLetterTypesOptions = useMemo(
    () => getLetterTypeOptions(t).sort((a, b) => a.value - b.value),
    [t]
  );

  return (
    <>
      {type === 'package' ? (
        <>
          <FieldWithErrors
            name="adresnica_service_packaging_id"
            label={t('Order service packaging')}
          >
            <Controller
              name="adresnica_service_packaging_id"
              render={({ field }) => (
                <Dropdown
                  {...field}
                  id={field.name}
                  options={orderPackagingOptions}
                  showClear
                  placeholder={t('No packaging')}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="fragile" label={t('Is fragile ?')}>
            <Controller
              name="fragile"
              render={({ field }) => (
                <InputSwitch
                  {...field}
                  checked={Boolean(field.value)}
                  inputId={field.name}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors
            name="two_man_delivery"
            label={t('Is two man delivery ?')}
            className="two_man_delivery"
          >
            <Controller
              name="two_man_delivery"
              render={({ field }) => (
                <InputSwitch
                  {...field}
                  checked={Boolean(field.value)}
                  inputId={field.name}
                />
              )}
            />
          </FieldWithErrors>
        </>
      ) : (
        <FieldWithErrors name="personal_delivery" label={t('Type of letter')}>
          <Controller
            name="personal_delivery"
            render={({ field }) => (
              <Dropdown
                {...field}
                // add default value regular
                value={field.value ?? orderLetterTypesOptions[0].value}
                name="personal_delivery"
                id={field.name}
                options={orderLetterTypesOptions}
                onChange={(e) => {
                  field.onChange(e.value);
                  setValue('povraten_dokument', false);
                }}
              />
            )}
          />
        </FieldWithErrors>
      )}
    </>
  );
}

export default PackagingServices;
