import './ImportLogDialog.scss';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../../../hooks/useAxios';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import useTableColumns from '../../../../../../hooks/useTableColumns';
import {
  ImportLog,
  SingleBulkOrder,
  additionalColumnProperties,
  getColumHeadersMap,
} from './ImportLogDialog.functions';

type Props = {
  id: string | undefined;
  name: string | undefined;
  visible: boolean;
  onHide: () => void;
  showShipmentsDialog: () => void;
};

function ImportLogDialog({
  id,
  name,
  visible,
  onHide,
  showShipmentsDialog,
}: Props) {
  const { t } = useTranslation();

  const columnHeadersMap = useMemo(() => getColumHeadersMap(t), [t]);

  const { data: logsData } = useAxios<ImportLog>(`/importlists/${id}/log`, {
    skipWhen: !id,
  });

  const { data: bulkOrderData } = useAxios<SingleBulkOrder>(
    `/importlists/${id}`,
    {
      skipWhen: !id,
    }
  );

  const resultLog = logsData?.logs?.find((log) => log.poraka_tip === 'result');

  const resultLogMessages = resultLog?.poraka.split(/[!,?.]/);

  const tableData = logsData?.logs?.filter(
    (log) => log.poraka_tip !== 'result'
  );

  const dialogFooter = (
    <>
      <Button
        className="p-button-text"
        type="button"
        label={t('Show Shipments')}
        onClick={showShipmentsDialog}
      />
      <Button type="button" label={t('Close')} onClick={onHide} />
    </>
  );

  const { columns } = useTableColumns(
    1,
    0,
    columnHeadersMap,
    columnHeadersMap,
    (c) => additionalColumnProperties(c)
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={t('Import Log for Batch Order {{orderID}}', { orderID: name })}
      footer={dialogFooter}
      visible={visible}
      onHide={onHide}
      maximizable
      maximized={isOnMobile}
      className="bulk-order-import-log-dialog"
    >
      <div className="imported-lists-view-import-log">
        <div className="log-info">
          <div className="p-mb-4">
            <div className="p-d-flex p-jc-start">
              <span className="info-title">{t('Client')}:&nbsp;</span>
              <span className="info-value">{bulkOrderData?.client_name}</span>
            </div>

            <div className="p-d-flex p-jc-start">
              <span className="info-title">{t('List name')}:&nbsp;</span>
              <span className="info-value">{bulkOrderData?.name}</span>
            </div>

            <div className="p-d-flex p-jc-start">
              <span className="info-title">{'Barcode'}:&nbsp;</span>
              <span className="info-value">{bulkOrderData?.barcode}</span>
            </div>
          </div>

          <div className="p-mb-4">
            {resultLogMessages?.map((msg, index) => {
              return (
                <div key={index}>
                  <span>{msg}</span>
                </div>
              );
            })}
          </div>
        </div>

        <DataTable
          value={tableData}
          scrollable
          className="import-log-dt datatable-no-header p-mt-2 p-datatable-sm p-datatable-striped"
          rowClassName={(row: ImportLog['logs'][0]) => {
            return {
              warning: row.poraka_tip === 'warning',
              error: row.poraka_tip === 'error',
            };
          }}
        >
          {columns}
        </DataTable>
      </div>
    </Dialog>
  );
}

export default ImportLogDialog;
