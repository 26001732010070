import { Button } from 'primereact/button';
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../../../../context/ToastContext';
import useAxios from '../../../../../../hooks/useAxios';
import usePrevious from '../../../../../../hooks/usePrevious';
import { ReduxState } from '../../../../../../types/redux';
import { cleanUpObject } from '../../../../../../utils/helpers/object';
import {
  errorToast,
  successToast,
} from '../../../../../../utils/helpers/primereact';
import Tooltip from '../../../../../Misc/Tooltip/Tooltip';
import { FormFields } from '../../CreateEditRecreate.functions';

function SaveContact({ isSender }: { isSender: boolean }) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const userClientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state?.user.client_id
  );

  const klient_od_ime = useWatch<FormFields, 'klient_od_ime'>({
    name: 'klient_od_ime',
  });

  const mobilen_od = useWatch<FormFields, 'mobilen_od'>({
    name: 'mobilen_od',
  });

  const telefon_od = useWatch<FormFields, 'telefon_od'>({
    name: 'telefon_od',
  });

  const mesto_od_id = useWatch<FormFields, 'mesto_od_id'>({
    name: 'mesto_od_id',
  });

  const ulica_od_id = useWatch<FormFields, 'ulica_od_id'>({
    name: 'ulica_od_id',
  });

  const adresa_od = useWatch<FormFields, 'adresa_od'>({
    name: 'adresa_od',
  });

  const broj_od = useWatch<FormFields, 'broj_od'>({
    name: 'broj_od',
  });
  const vlez_od = useWatch<FormFields, 'vlez_od'>({
    name: 'vlez_od',
  });

  const stan_od = useWatch<FormFields, 'stan_od'>({
    name: 'stan_od',
  });

  const klient_do_ime = useWatch<FormFields, 'klient_do_ime'>({
    name: 'klient_do_ime',
  });

  const mobilen_do = useWatch<FormFields, 'mobilen_do'>({
    name: 'mobilen_do',
  });

  const telefon_do = useWatch<FormFields, 'telefon_do'>({
    name: 'telefon_do',
  });

  const mesto_do_id = useWatch<FormFields, 'mesto_do_id'>({
    name: 'mesto_do_id',
  });

  const ulica_do_id = useWatch<FormFields, 'ulica_do_id'>({
    name: 'ulica_do_id',
  });

  const adresa_do = useWatch<FormFields, 'adresa_do'>({
    name: 'adresa_do',
  });

  const broj_do = useWatch<FormFields, 'broj_do'>({
    name: 'broj_do',
  });
  const vlez_do = useWatch<FormFields, 'vlez_do'>({
    name: 'vlez_do',
  });

  const stan_do = useWatch<FormFields, 'stan_do'>({
    name: 'stan_do',
  });

  const {
    reload: saveAddress,
    data: saveAddressData,
    error: saveAddressError,
  } = useAxios<any, { error: string }>(
    {
      url: '/client-addresses',
      method: 'POST',
      data: cleanUpObject(
        isSender
          ? {
              client_id: String(userClientId),
              contact: klient_od_ime,
              mobile_phone: mobilen_od,
              phone: telefon_od,
              place_id: mesto_od_id,
              street_id: ulica_od_id,
              address: adresa_od,
              street_number: broj_od,
              entrance: vlez_od,
              apartment: stan_od,
              department_wide: 1,
            }
          : {
              client_id: String(userClientId),
              contact: klient_do_ime,
              mobile_phone: mobilen_do,
              phone: telefon_do,
              place_id: mesto_do_id,
              street_id: ulica_do_id,
              address: adresa_do,
              street_number: broj_do,
              entrance: vlez_do,
              apartment: stan_do,
              department_wide: 1,
            }
      ),
    },
    { skipWhen: true }
  );

  const previousSaveAddressData = usePrevious(saveAddressData);
  const previousSaveAddressError = usePrevious(saveAddressError);

  useEffect(() => {
    if (!saveAddressData || saveAddressData === previousSaveAddressData) {
      return;
    }

    successToast(toastRef, t('Success'), t('Contact stored successfully'));
  });

  useEffect(() => {
    if (!saveAddressError || saveAddressError === previousSaveAddressError) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occurred while saving contact due to {{errorMessage}}', {
        errorMessage: saveAddressError.response?.data.error,
      })
    );
  });

  return (
    <>
      <Tooltip
        style={{
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 0,
          display: 'block',
        }}
        text={t('Save frequently used contacts for usage in the lookup above.')}
      >
        <Button
          className="p-button-text save-address"
          label={t('Save Contact')}
          onClick={() => saveAddress()}
          icon="fas fa-save"
          type="button"
        />
      </Tooltip>
    </>
  );
}

export default SaveContact;
