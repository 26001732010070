import { Checkbox } from 'primereact/checkbox';
import { SelectButton } from 'primereact/selectbutton';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import Step from '../../../../Stepper/Inline/Step';
import { CustomerRole } from '../CreateEditRecreate.functions';

function General(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Step>
      <FieldWithErrors name="customer_role" label={t('My role')}>
        <Controller
          name="customer_role"
          render={({ field }) => (
            <SelectButton
              id="customer_role"
              options={[
                { label: t('I am the sender'), value: CustomerRole.Sender },
                {
                  label: t('I am the recipient'),
                  value: CustomerRole.Recipient,
                },
                { label: t('I am the orderer'), value: CustomerRole.Orderer },
              ]}
              unselectable={false}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="internal_shipment"
        label={false}
        className="p-mt-5"
      >
        <Controller
          name="internal_shipment"
          render={({ field }) => (
            <div className="p-field-checkbox">
              <Checkbox
                name="internal_shipment"
                inputId="internal_shipment"
                checked={field.value}
                onChange={(e) => field.onChange(e.checked)}
              />
              <label
                htmlFor="internal_shipment"
                className="p-checkbox-label"
                data-cy="internal_shipment"
              >
                {t('Internal order')}
              </label>
            </div>
          )}
        />
      </FieldWithErrors>
    </Step>
  );
}

export default General;
