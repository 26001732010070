import classNames from 'classnames';

import CollapsibleFilterSection from '../../Layout/flex/components/CollapsibleFilterSection';

interface IProps {
  filters: JSX.Element;
  resetAllFilters: Function;
  headerFiltersCount?: any;
  filterHeight?: number;
}

const Filters = ({
  filters,
  resetAllFilters,
  headerFiltersCount,
  filterHeight, // we set this height in the table on the page to because of height of the table on full screen
}: IProps) => {
  return (
    <CollapsibleFilterSection
      childrenWrapperClassName={classNames('p-accordion-filters')}
      activeFiltersCount={headerFiltersCount}
      onResetFields={resetAllFilters}
      filterHeight={filterHeight}
    >
      {filters}
    </CollapsibleFilterSection>
  );
};

export default Filters;
