export const ERROR = 'ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

export function error(statusCode: number | null, message: string) {
  return { type: ERROR, statusCode, message };
}

export function dismissError() {
  return { type: DISMISS_ERROR };
}
