import { useWatch } from 'react-hook-form';

import useMediaQuery from '../../../../../../../hooks/useMediaQuery';
import { FormFields } from '../../../CreateEditRecreate.functions';
import InsuredAmount from '../Fields/InsuredAmount';
import PackagingServices from '../Fields/PackagingServices';
import Quantity from '../Fields/Quantity';
import ReferenceNo1 from '../Fields/ReferenceNo1';
import ReturnDocument from '../Fields/ReturnDocument';
import styles from './OrderTypes.module.scss';

function Letter(): JSX.Element {
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const personal_delivery = useWatch<FormFields, 'personal_delivery'>({
    name: 'personal_delivery',
  });

  return (
    <div
      className={isOnMobile ? styles.twoColumnLayout : styles.fourColumnLayout}
    >
      <Quantity />
      <InsuredAmount />
      <ReferenceNo1 />
      <PackagingServices type="letter" />
      {personal_delivery === 1 && <ReturnDocument />}
    </div>
  );
}

export default Letter;
