import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import { ClientForgotPasswordRequestPayload } from '../../../../types/api/clients';
import { errorToast } from '../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import UnauthPage from '../../../page/UnauthPage';
import {
  getValidationSchema,
  initialFormValues,
} from './ForgotPassword.functions';
import { ForgotPasswordError, FormFields } from './ForgotPassword.types';

function ForgotPassword(): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);

  const { toastRef } = useContext(ToastContext);

  const reactHookFormMethods = useForm<FormFields>({
    resolver,
    defaultValues: initialFormValues,
  });

  const { handleSubmit, register } = reactHookFormMethods;

  const {
    data: forgotPasswordData,
    error: forgotPasswordError,
    isLoading,
    reload,
  } = useAxios<undefined, ForgotPasswordError>();

  const prevForgotPasswordData = usePrevious(forgotPasswordData);
  const prevForgotPasswordError = usePrevious(forgotPasswordError);

  useEffect(() => {
    if (!forgotPasswordData || forgotPasswordData === prevForgotPasswordData) {
      return;
    }

    history.replace('/profile/forgot-password/success');
  }, [forgotPasswordData, history, t, toastRef, prevForgotPasswordData]);

  useEffect(() => {
    if (
      !forgotPasswordError ||
      forgotPasswordError === prevForgotPasswordError
    ) {
      return;
    }

    switch (forgotPasswordError.response?.data.error_description?.email) {
      case 'invalid_parameter':
        errorToast(toastRef, t('Error'), t('The provided email is invalid.'));
        break;

      case 'client_not_found':
        errorToast(
          toastRef,
          t('Error'),
          t('No user with the provided email was found.')
        );
        break;

      case 'user_invalid':
        errorToast(
          toastRef,
          t('Error'),
          t(
            'No user with the provided email was found or your account is inactive. Please contact support.'
          )
        );
        break;

      default:
        errorToast(
          toastRef,
          t('Error'),
          t('An error occured. Please try again later or contact support.')
        );
    }
  }, [history, prevForgotPasswordError, forgotPasswordError, t, toastRef]);

  function handleFormSubmit(data: ClientForgotPasswordRequestPayload) {
    reload({
      url: `/clients/forgotpassword?client_id=${process.env.REACT_APP_CLIENT_ID}`,
      method: 'POST',
      data,
    });
  }

  return (
    <UnauthPage
      title={t('Reset your password')}
      description={t(
        "Enter your email address below and we'll send you a link with instructions."
      )}
    >
      <FormProvider {...reactHookFormMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FieldWithErrors name="email" label={t('Email') as string}>
            <Controller
              name="email"
              render={({ field }) => {
                return (
                  <InputText
                    value={field.value}
                    style={{ width: '100%' }}
                    {...register('email', { required: true })}
                  />
                );
              }}
            />
          </FieldWithErrors>

          <Button
            type="submit"
            label={isLoading ? t('Loading...') : t('Submit')}
            disabled={isLoading}
            style={{ width: '100%' }}
          />

          <Link to="/login" className="p-d-block p-text-center p-mt-3">
            {t('Login')}
          </Link>
        </form>
      </FormProvider>
    </UnauthPage>
  );
}

export default ForgotPassword;
