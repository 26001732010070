import * as Sentry from '@sentry/react';
import { locale } from 'primereact/api';
import { applyMiddleware, compose, createStore } from 'redux';

import { ReduxState } from '../types/redux';
import { setDayjsLocale } from '../utils/helpers/config';
import { deleteCookie, getCookie } from '../utils/helpers/cookies';
import { processUser } from '../utils/helpers/redux';
import { AllowedLanguages } from './actions/languageActions';
import languageMiddleware from './middleware/languageMiddleware';
import userMiddleware from './middleware/userMiddleware';
import reducers from './reducers/reducers';

const preloadedState: Partial<ReduxState> = {};
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

try {
  const language = getLanguage();
  const user = localStorage.getItem('user');

  if (user) {
    const sessionKeyCookie = getCookie('customerSessionKey');
    const parsedUser = JSON.parse(user) as Required<ReduxState['user']>;

    if (parsedUser.sessionKey === sessionKeyCookie) {
      preloadedState.user = {
        isLoggedIn: true,
        ...processUser(parsedUser),
      };
    } else {
      localStorage.clear();
      deleteCookie('customerSessionKey');
    }
  }

  preloadedState.language = language;

  locale(language);
  setDayjsLocale(language);
} catch (e) {
  Sentry.captureException(e);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  reducers,
  preloadedState,
  composeEnhancers(
    applyMiddleware(userMiddleware, languageMiddleware),
    sentryReduxEnhancer
  )
);

function getLanguage(): AllowedLanguages {
  // We always test with the English version of the UI
  if (window.Cypress) {
    return 'en';
  }

  const allowedLanguages: AllowedLanguages[] = [
    'en',
    'mk',
    'sr-Cyrl',
    'sr-Latn',
  ];
  const storedLanguage = localStorage.getItem('language');

  return storedLanguage && allowedLanguages.includes(storedLanguage as any)
    ? (storedLanguage as AllowedLanguages)
    : getLanguageFromString(process.env.REACT_APP_DEFAULT_LANGUAGE!);
}

function getLanguageFromString(language: string): AllowedLanguages {
  switch (language.toLowerCase()) {
    case 'mk_mk':
      return 'mk';

    case 'en_en':
      return 'en';

    case 'sr-Cyrl':
      return 'sr-Cyrl';

    case 'sr-Latn':
      return 'sr-Latn';

    default:
      return 'mk';
  }
}
