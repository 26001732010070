import { InputSwitch } from 'primereact/inputswitch';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';

function ReturnDocument(): JSX.Element {
  const { t } = useTranslation();

  return (
    <FieldWithErrors
      name="povraten_dokument"
      label={t('Return document')}
      className="data-cy-return_document"
    >
      <Controller
        name="povraten_dokument"
        render={({ field }) => (
          <InputSwitch
            {...field}
            checked={Boolean(field.value)}
            inputId={field.name}
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default ReturnDocument;
