import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function useSearchQueryParam(param: string, value: string | null): void {
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(param, value);
    } else {
      searchParams.delete(param);
    }

    history.replace({
      ..._.omit(history.location, 'key'),
      search: searchParams.toString(),
    });
  }, [history, param, value]);
}

export default useSearchQueryParam;
