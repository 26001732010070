import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import mk from 'dayjs/locale/mk';
import sr from 'dayjs/locale/sr';
import srCyrl from 'dayjs/locale/sr-cyrl';

import { AllowedLanguages } from '../../redux/actions/languageActions';

const dayjsLocaleMapper: Record<AllowedLanguages, any> = {
  en: en,
  mk: mk,
  'sr-Cyrl': srCyrl,
  'sr-Latn': sr,
};

export function setDayjsLocale(language: AllowedLanguages): void {
  dayjs.locale(dayjsLocaleMapper[language]);
}
