import { Action } from 'redux';

import { ReduxState } from '../../types/redux';
import {
  AllowedLanguages,
  CHANGE_LANGUAGE,
  changeLanguage,
} from '../actions/languageActions';

function languageReducer(
  state: AllowedLanguages = 'en',
  action: Action
): ReduxState['language'] {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return (action as ReturnType<typeof changeLanguage>).language;

    default:
      return state;
  }
}

export default languageReducer;
