import './Header.scss';

import { useSelector } from 'react-redux';

import { ReduxState } from '../../types/redux';
import AuthHeader from './AuthHeader';
import UnauthHeader from './UnauthHeader';

export function Header() {
  const isUserLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['isLoggedIn']
  >((state) => state.user.isLoggedIn);

  return (
    <div className="header-wrapper">
      <div className={`header ${isUserLoggedIn ? 'auth' : 'unauth'}`}>
        <div className="header-content">
          {isUserLoggedIn ? <AuthHeader /> : <UnauthHeader />}
        </div>
      </div>
    </div>
  );
}
