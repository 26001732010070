import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';

function useTableFilters() {
  const { t } = useTranslation();
  const location = useLocation();

  const [nameFilter, setNameFilter] = useState(
    getSearchQueryParam(location.search, 'name') ?? ''
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);

  useSearchQueryParam('name', debouncedNameFilter);

  const httpFiltersObj = useMemo(
    () => ({
      name: debouncedNameFilter,
    }),
    [debouncedNameFilter]
  );

  const basicFiltersActiveFilterCount = Object.values({
    debouncedNameFilter,
  }).filter(Boolean).length;

  const basicFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Basic filters')}</span>
        {basicFiltersActiveFilterCount > 0 && (
          <span className="badge">{basicFiltersActiveFilterCount}</span>
        )}
      </div>
    ),
    [t, basicFiltersActiveFilterCount]
  );

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="filter_name">{t('Name')}</label>
            <InputText
              id="filter_name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [nameFilter, basicFiltersHeaderTemplate, t]
  );

  function resetAllFilters() {
    setNameFilter('');
  }

  // here if there in the future have more tabs, sum all counter tabs with useMemo
  const headerFiltersCount = basicFiltersActiveFilterCount;

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
