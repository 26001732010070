import './ViewStatusDialog.scss';

import { AxiosRequestConfig } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ToastContext from '../../../../../../context/ToastContext';
import useAxios from '../../../../../../hooks/useAxios';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../../hooks/usePrevious';
import { OrderResource } from '../../../../../../types/api/orders';
import { ReduxState } from '../../../../../../types/redux';
import { errorToast } from '../../../../../../utils/helpers/primereact';
import ShipmentTracking from '../../../../../ShipmentTracking/ShipmentTracking';
import { StatusApiData } from '../../../../../ShipmentTracking/ShipmentTracking.functions';

type Props = {
  serialNumber: string;
  visible: boolean;
  onHide: () => void;
};

function ViewStatusDialog({
  serialNumber,
  visible,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { toastRef } = useContext(ToastContext);
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const [showGoBackBtn, setShowGoBackBtn] = useState<boolean>(false);

  const clientLoggedIn = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );

  const requestStatusConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: '/orders/status',
      data: { serialnumber: [serialNumber] },
      method: 'POST',
    };
    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, serialNumber]);

  const {
    data: orderStatusTrackingData,
    error: orderStatusTrackingError,
    isLoading: isOrderStatusTrackingLoading,
  } = useAxios<StatusApiData>(requestStatusConfig, {
    skipWhen: !serialNumber || !visible,
  });

  const orderStatusTrackingErrorPrev = usePrevious(orderStatusTrackingError);

  useEffect(() => {
    if (
      !orderStatusTrackingError ||
      orderStatusTrackingError === orderStatusTrackingErrorPrev
    ) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading status data.')
      );
    }
  }, [orderStatusTrackingError, orderStatusTrackingErrorPrev, t, toastRef]);

  const {
    data: shipmentData,
    error: shipmentError,
    isLoading: isShipmentLoading,
    reload: reloadShipmentData,
  } = useAxios<OrderResource>(`/orders/track/${serialNumber}`, {
    skipWhen: true,
  });

  const shipmentErrorPrev = usePrevious(shipmentError);

  useEffect(() => {
    if (!shipmentError || shipmentError === shipmentErrorPrev) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading order data.')
      );
    }
  }, [shipmentError, shipmentErrorPrev, t, toastRef]);

  useEffect(() => {
    if (orderStatusTrackingData?.identifier) {
      reloadShipmentData();
    }
  }, [orderStatusTrackingData?.identifier, reloadShipmentData]);

  const { data: returnDocData, reload: reloadReturnDocData } =
    useAxios<OrderResource>(`/orders/${shipmentData?.return_document_id}`, {
      skipWhen: true,
    });

  const { data: originReturnDocData, reload: reloadOriginReturnDocData } =
    useAxios<OrderResource>(
      `/orders/${shipmentData?.origin_return_document_id}`,
      {
        skipWhen: true,
      }
    );

  const { data: returnShipmentData, reload: reloadReturnShipmentData } =
    useAxios<OrderResource>(`/orders/${shipmentData?.return_shipment_id}`, {
      skipWhen: true,
    });

  const {
    data: originReturnShipmentData,
    reload: reloadOriginReturnShipmentData,
  } = useAxios<OrderResource>(
    `/orders/${shipmentData?.origin_return_shipment_id}`,
    {
      skipWhen: true,
    }
  );

  useEffect(() => {
    if (orderStatusTrackingData?.[serialNumber]?.identifier) {
      reloadShipmentData();
    }
  }, [orderStatusTrackingData, reloadShipmentData, serialNumber]);

  useEffect(() => {
    if (shipmentData?.return_document_id) {
      reloadReturnDocData();
    }
  }, [reloadReturnDocData, shipmentData]);

  useEffect(() => {
    if (shipmentData?.origin_return_document_id) {
      reloadOriginReturnDocData();
    }
  }, [reloadOriginReturnDocData, shipmentData]);

  useEffect(() => {
    if (shipmentData?.return_shipment_id) {
      reloadReturnShipmentData();
    }
  }, [reloadReturnShipmentData, shipmentData]);

  useEffect(() => {
    if (shipmentData?.origin_return_shipment_id) {
      reloadOriginReturnShipmentData();
    }
  }, [reloadOriginReturnShipmentData, shipmentData]);

  const returnDocumentSerialNo = useMemo(() => {
    return shipmentData?.return_document_id && returnDocData
      ? returnDocData.seriski_broj
      : null;
  }, [returnDocData, shipmentData?.return_document_id]);

  const originReturnDocumentSerialNo = useMemo(() => {
    return shipmentData?.origin_return_document_id && originReturnDocData
      ? originReturnDocData.seriski_broj
      : null;
  }, [originReturnDocData, shipmentData?.origin_return_document_id]);

  const returnShipmentSerialNo = useMemo(() => {
    return shipmentData?.return_shipment_id && returnShipmentData
      ? returnShipmentData.seriski_broj
      : null;
  }, [returnShipmentData, shipmentData?.return_shipment_id]);

  const originReturnShipmentSerialNo = useMemo(() => {
    return shipmentData?.origin_return_shipment_id && originReturnShipmentData
      ? originReturnShipmentData.seriski_broj
      : null;
  }, [originReturnShipmentData, shipmentData?.origin_return_shipment_id]);

  return (
    <Dialog
      header={t('Order Tracking - {{serialNumber}}', {
        serialNumber: serialNumber,
      })}
      footer={
        <>
          {showGoBackBtn && (
            <Button
              type="button"
              icon="fas fa-chevron-left"
              label={t('Go Back')}
              onClick={() => {
                history.goBack();
                setShowGoBackBtn(false);
              }}
              className="p-button-secondary p-button-text"
            />
          )}
          <Button
            type="button"
            label={t('Close')}
            onClick={() => {
              onHide();
              setShowGoBackBtn(false);
            }}
            data-cy="close-btn"
          />
        </>
      }
      visible={visible}
      onHide={() => {
        onHide();
        setShowGoBackBtn(false);
      }}
      maximizable
      maximized={isOnMobile}
      className="shipment-tracking-dialog"
    >
      <ShipmentTracking
        trackData={orderStatusTrackingData}
        shipmentData={shipmentData}
        serialNo={serialNumber}
        isDataLoading={isOrderStatusTrackingLoading || isShipmentLoading}
        isUrlPublic={false}
        setShowGoBackBtn={setShowGoBackBtn}
        returnDoc={returnDocumentSerialNo}
        originReturnDoc={originReturnDocumentSerialNo}
        returnShipment={returnShipmentSerialNo}
        originReturnShipment={originReturnShipmentSerialNo}
      />
    </Dialog>
  );
}

export default ViewStatusDialog;
