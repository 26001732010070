import { useMemo } from 'react';

import { emptyCell } from '../utils/constants/tables';
import useMediaQuery from './useMediaQuery';
import useTableColumns from './useTableColumns';

type Props = {
  body?: any;
  style?: any;
  bodyStyle?: any;
};

function useResponsiveTableColumns(
  page: number,
  limit: number,
  defaultSelectedColumns: Record<string, string> | object[],
  columnHeadersMap: Record<string, string>,
  mobileColumnHeadersMap: Record<string, string>,
  additionalColumnProps?: (c: string) => object,
  mobileAdditionalColumnProps?: (c: string) => object
) {
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const headersMap = isOnMobile ? mobileColumnHeadersMap : columnHeadersMap;

  const additionalProps = useMemo<((c: string) => object) | undefined>(() => {
    if (!isOnMobile) {
      return additionalColumnProps;
    }

    if (typeof mobileAdditionalColumnProps !== 'function') {
      return undefined;
    }

    return (c: string): object => {
      const currentColumnAdditionalProps: Props =
        mobileAdditionalColumnProps(c);

      return {
        ...currentColumnAdditionalProps,
        body: (rowData: any, colData: any) => {
          const bodyContent =
            c === 'no'
              ? colData.rowIndex + 1 + (page - 1) * limit
              : currentColumnAdditionalProps.body
              ? currentColumnAdditionalProps.body(rowData, colData)
              : (typeof rowData[c] === 'string'
                  ? rowData[c].trim()
                  : rowData[c]) || emptyCell;

          if (headersMap[c]) {
            return (
              <div
                className="p-d-flex p-flex-wrap"
                style={{ width: 'max-content' }}
              >
                <div style={{ width: '130px', fontWeight: 'bold' }}>
                  {headersMap[c]}
                </div>
                <div>{bodyContent}</div>
              </div>
            );
          }
        },
      };
    };
  }, [
    additionalColumnProps,
    headersMap,
    isOnMobile,
    limit,
    mobileAdditionalColumnProps,
    page,
  ]);

  return useTableColumns(
    page,
    limit,
    defaultSelectedColumns,
    headersMap,
    additionalProps
  );
}

export default useResponsiveTableColumns;
