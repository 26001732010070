import { FormikConfig, FormikHelpers, FormikValues } from 'formik';

export type StepProps = Pick<
  FormikConfig<FormikValues>,
  'children' | 'validationSchema'
> & {
  title?: string;
  subtitle?: string;
  onSubmit?: (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => void | Promise<void>;
};

function Step({ children }: StepProps): JSX.Element {
  return <>{children}</>;
}

export default Step;
