import { LoginResponse } from '../../types/api/users';
import { processUser } from '../../utils/helpers/redux';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN';

export function loginUser(
  data: LoginResponse,
  rememberMe: boolean = false,
  timestamp: number
) {
  return {
    type: LOGIN_USER,
    data: processUser(data),
    rememberMe,
    timestamp,
  };
}

export function logoutUser() {
  return { type: LOGOUT_USER };
}

export function refreshAuthToken(data: LoginResponse) {
  return { type: REFRESH_AUTH_TOKEN, data: processUser(data) };
}
