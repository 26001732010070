import { useCallback, useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AutoCompleteInput from '../../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import { FormFields } from '../../../CreateEditRecreate.functions';
import StepsContext from '../../../StepsContext';

function RelatedOrder(): JSX.Element {
  const { t } = useTranslation();

  const {
    orderSettings: { relatedOrderOptions, isRelatedOrderOptionsDataLoading },
  } = useContext(StepsContext);

  const { setValue } = useFormContext<FormFields>();

  const related_order_filter = useWatch<FormFields, 'related_order_filter'>({
    name: 'related_order_filter',
  });

  const handleReferenceIdFilterChange = useCallback(
    (e: typeof relatedOrderOptions[0]['value']) =>
      setValue('related_order_filter', e),
    [setValue]
  );

  return (
    <FieldWithErrors name="reference_id" label={t('Related Order')}>
      <Controller
        name="reference_id"
        render={({ field }) => (
          <AutoCompleteInput
            id="reference_id"
            name="reference_id"
            filterValue={related_order_filter}
            value={field.value}
            onFilterChange={handleReferenceIdFilterChange}
            onSelectionChange={field.onChange}
            options={relatedOrderOptions}
            isLoading={isRelatedOrderOptionsDataLoading}
            placeholder={t('Serial No.')}
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default RelatedOrder;
