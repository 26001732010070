import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../configs/primereact';
import { OrderStatus } from '../../../enums/orders';
import { ImportListCollection } from '../../../types/api/importlists';
import { Unpacked } from '../../../types/util';
import { emptyCell } from '../../../utils/constants/tables';
import ActionsTableMenu from '../../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'bulkOrder_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Pick<
      Unpacked<ImportListCollection>,
      | 'import_list_type_name'
      | 'name'
      | 'created'
      | 'client_name'
      | 'barcode'
      | 'status'
      | 'parcels_count'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    import_list_type_name: t('List Type'),
    status: t('Status'),
    name: t('Name'),
    created: t('Created At'),
    client_name: t('Client'),
    barcode: t('Barcode'),
    parcels_count: t('Total'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction?: any
) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'name':
      return {
        body: (row: Unpacked<ImportListCollection>) =>
          typeof row[column] === 'string' ? row.name.split(' ')[0] : emptyCell,
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'status':
      return {
        body: (row: Unpacked<ImportListCollection>) => (
          <span className={`status-badge ${row.status_tip}`}>{row.status}</span>
        ),
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'parcels_count':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'actions':
      return {
        body: (row: Unpacked<ImportListCollection>) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Import Log'),
                  icon: 'fas fa-boxes',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-import-log');
                  },
                },
                {
                  label: t('View Orders'),
                  icon: 'fas fa-edit',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-orders');
                  },
                },
                {
                  label: t('Print Address Books'),
                  icon: 'fas fa-address-book',
                  disabled: parseInt(row.parcels_count) <= 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-address-books');
                  },
                },
                {
                  label: t('Print Stickers'),
                  icon: 'fas fa-ticket-alt',
                  disabled: parseInt(row.parcels_count) <= 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-stickers');
                  },
                },
                {
                  label: t('Print Specification'),
                  icon: 'fas fa-clipboard-list',
                  disabled: parseInt(row.parcels_count) <= 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-specification');
                  },
                },
                {
                  label: t('Export to CSV'),
                  icon: 'fas fa-file-csv',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-csv');
                  },
                },
                {
                  label: t('Delete list and addresses'),
                  icon: 'fas fa-trash',
                  disabled: !(
                    !!row?.max_status_id &&
                    row.max_status_id < OrderStatus.Cancelled
                  ),
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('delete-list-and-addresses');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}
