import { ReactNode } from 'react';

import styles from './Flex.module.scss';

type Props = {
  children: ReactNode;
};

function MainContent({ children }: Props): JSX.Element {
  return <div className={styles.mainContent}>{children}</div>;
}

export default MainContent;
