import _ from 'lodash';

export function isEqual<T>(currentValues: T, initialValues: T) {
  for (const [key, value] of Object.entries(currentValues)) {
    if (initialValues[key as keyof T] !== value) {
      return false;
    }
  }

  return true;
}

export function getChangedValues<T>(
  currentValues: T,
  initialValues: T
): { [key: string]: any } {
  let changedValues: [key: string, value: any][] = [];

  for (const [key, value] of Object.entries(currentValues)) {
    if (!_.isEqual(initialValues[key as keyof T], value)) {
      changedValues.push([key, value]);
    }
  }

  return Object.fromEntries(changedValues);
}

export function objDiff<T extends R, R extends object>(
  obj: T,
  keysToOmit: Record<keyof Omit<T, keyof R>, true>
): R {
  return _.pick(
    obj,
    _.difference(
      Object.keys(obj) as (keyof T)[],
      Object.keys(keysToOmit) as (keyof typeof keysToOmit)[]
    )
  );
}

export function cleanUpObject<T extends object>(obj: T): Partial<T> {
  return _.pick(
    obj,
    Object.keys(obj).filter((key) => {
      const value = obj[key as keyof T];

      if (typeof value === 'boolean') {
        return true;
      }

      if (typeof value === 'number') {
        return !isNaN(value);
      }

      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return !!value;
    })
  );
}
