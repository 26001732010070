import { useContext, useEffect } from 'react';

import HeadlessStepperContext from '../HeadlessStepperContext';

function Content(): JSX.Element {
  const { StepComponent, setStepIndex } = useContext(HeadlessStepperContext);

  useEffect(() => {
    return () => {
      setStepIndex(0);
    };
  }, [setStepIndex]);

  return (
    <div className="stepper-content">
      <StepComponent />
    </div>
  );
}

export default Content;
