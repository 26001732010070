import { Action } from 'redux';

import { ReduxState } from '../../types/redux';
import { DISMISS_ERROR, ERROR, error } from '../actions/httpActions';

const defaultState: ReduxState['http'] = {
  message: '',
  hasErrorOccured: false,
};

function httpReducer(state = defaultState, action: Action): ReduxState['http'] {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        statusCode: (action as ReturnType<typeof error>).statusCode,
        message: (action as ReturnType<typeof error>).message,
      };

    case DISMISS_ERROR:
      return defaultState;

    default:
      return state;
  }
}

export default httpReducer;
