import { useEffect } from 'react';

function useNetworkStatus(
  onlineCallback: () => void,
  offlineCallback: () => void
): void {
  useEffect(() => {
    window.addEventListener('online', onlineCallback);
    window.addEventListener('offline', offlineCallback);

    return () => {
      window.removeEventListener('online', onlineCallback);
      window.removeEventListener('offline', offlineCallback);
    };
  }, [offlineCallback, onlineCallback]);
}

export default useNetworkStatus;
