import classNames from 'classnames';
import { Dialog, DialogProps } from 'primereact/dialog';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Content from './Content';
import Footer, { FooterProps } from './Footer';
import Header, { HeaderProps } from './Header';

export type DialogContainerProps = HeaderProps &
  FooterProps & {
    dialogProps: Omit<DialogProps, 'header' | 'footer'>;
  };

function DialogContainer({
  // Header
  title,
  isStepIndicatorShown,
  // Footer
  onCancelBtnClick,
  backButtonProps,
  nextButtonProps,
  cancelButtonProps,
  submitBtnLabel,
  // Dialog,
  dialogProps: { className, style, ...restDialogProps },
}: DialogContainerProps): JSX.Element {
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const header = (
    <Header title={title} isStepIndicatorShown={isStepIndicatorShown} />
  );

  const footer = (
    <Footer
      isStepIndicatorShown={isStepIndicatorShown}
      onCancelBtnClick={onCancelBtnClick}
      backButtonProps={backButtonProps}
      nextButtonProps={nextButtonProps}
      cancelButtonProps={cancelButtonProps}
      submitBtnLabel={submitBtnLabel}
    />
  );

  return (
    <Dialog
      header={header}
      footer={footer}
      resizable={false}
      maximizable
      maximized={isOnMobile}
      className={classNames('dialog-stepper', className)}
      style={{ width: 720, ...(style ?? {}) }}
      {...restDialogProps}
    >
      <Content />
    </Dialog>
  );
}

export default DialogContainer;
