// DBTable "adresnica_status"
export enum OrderStatus {
  CreatedAddress = 10,
  CreatedAddressFromCounter = 11,
  Created = 15,
  CreatedAddressFromMobile = 16,
  CreatedGroupOrder = 20,
  AssignedToAReceptionCourier = 30,
  UnsuccessfulPickup = 33,
  Cancelled = 34,
  PickedUp = 35,
  Sorting = 40,
  AwaitingTransfer = 41,
  AwaitingControl = 45,
  AwaitingSecondControl = 46,
  AwaitingPickupFromClient = 47,
  AwaitingPickupFromPostOffice = 48,
  HandedOverToARelocationCourier = 50,
  HandedOverToADeliveryCourier = 51,
  UnsuccessfulDeliveryAttempt = 55,
  MarkedForReturn = 60,
  Returned = 65,
  MarkedAsDeliveredCourier = 75,
  MarkedAsDeliveredWarehouse = 76,
  Delivered = 80,
}

export enum MyRole {
  Sender = 'sender',
  Receiver = 'receiver',
  Orderer = 'orderer',
  Any = 'any',
}
