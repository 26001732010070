import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { CountryPhone } from '../../../../enums/countries';
import { isStreetFreeInputAllowed } from '../../../../utils/constants/misc';
import {
  getPhoneorMobileNumberRegion,
  isValidMobileNumber,
} from '../../../../utils/helpers/phoneNumbers';
import {
  conditionallyRequiredField,
  yupMaxField,
  yupRequiredField,
} from '../../../../utils/helpers/yup';

export type FormFields = {
  legal_entity: boolean;
  name: string;
  email: string;
  phone: string;
  phone_region: CountryPhone;
  tax_number: string;
  municipality: string;
  place: string;
  street: string;
  address: string;
  house_number: string;
  entrance_number: string;
  flat_number: string;
};

export function getInitialValues(): FormFields {
  return {
    legal_entity: false,
    name: '',
    email: '',
    phone: '',
    phone_region: getPhoneorMobileNumberRegion(''),
    tax_number: '',
    municipality: '',
    place: '',
    street: '',
    address: '',
    house_number: '',
    entrance_number: '',
    flat_number: '',
  };
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    name: Yup.string().required(yupRequiredField(t, t('Name'))),
    phone: Yup.string().test(
      'phone',
      t('Invalid mobile phone number'),
      (value, context): boolean => {
        if (!value) {
          return true;
        }

        const { phone_region } = context.parent;
        return isValidMobileNumber(value, phone_region);
      }
    ),
    email: Yup.string()
      .required(yupRequiredField(t, t('Email')))
      .email()
      .max(254, yupMaxField(t, t('Email'), 254)),
    tax_number: Yup.string().when('legal_entity', {
      is: true,
      then: Yup.string()
        .max(45, yupMaxField(t, t('Tax Number'), 45))
        .required(yupRequiredField(t, t('Tax Number'))),
    }),
    municipality: Yup.string().required(yupRequiredField(t, t('Municipality'))),
    place: Yup.string().required(yupRequiredField(t, t('Place'))),
    street: conditionallyRequiredField(
      Yup.string().nullable(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Street'))
    ),
    address: conditionallyRequiredField(
      Yup.string(),
      isStreetFreeInputAllowed,
      yupRequiredField(t, t('Address'))
    ),
    house_number: conditionallyRequiredField(
      Yup.string(),
      !isStreetFreeInputAllowed,
      yupRequiredField(t, t('Number'))
    ),
  });
}
