import '../BulkOrders.scss';

import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type Props = {
  onImportAgainBtnClick: () => void;
  hasFailed?: boolean;
  subtitle?: string | undefined;
};

function ImportResult({ onImportAgainBtnClick, hasFailed, subtitle }: Props) {
  const { t } = useTranslation();

  const history = useHistory();

  const className = classNames('import-result', {
    'import-failure': hasFailed,
    'import-success': !hasFailed,
  });

  return (
    <div className={className}>
      <div>
        <FontAwesomeIcon
          icon={hasFailed ? faTimesCircle : faCheckCircle}
          className="status-icon"
        />
      </div>

      <h2 className="title">
        {hasFailed ? t('Oops!') : t('Batch Order Created')}
      </h2>

      <div className="subtitle">
        {subtitle?.trim() ||
          (hasFailed
            ? t('An error occured while trying to import your list')
            : t('Your batch order was successfully created!'))}
      </div>

      <div className="p-d-flex p-jc-center p-mt-5">
        <Button
          type="button"
          label={t('Import again')}
          icon="fas fa-undo"
          className="p-mr-2"
          onClick={onImportAgainBtnClick}
        />

        <Button
          type="button"
          label={t('Back to Batch Orders')}
          icon="fas fa-fast-backward"
          className="p-button-outlined"
          onClick={() => {
            history.replace('/orders/batch');
          }}
        />
      </div>
    </div>
  );
}

export default ImportResult;
