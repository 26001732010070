import './FieldWithErrors.scss';

import { useFormikContext } from 'formik';
import { TFunctionResult } from 'i18next';
import _ from 'lodash';

type Props = {
  name: string;
  label?: string | TFunctionResult | false;
  includeErrorsFor?: string[];
  className?: string;
  children?: JSX.Element | JSX.Element[];
  childrenWrapperClassName?: string;
};

function FieldWithErrors({
  name,
  label,
  includeErrorsFor = [],
  className = '',
  children,
  childrenWrapperClassName,
}: Props) {
  const { errors, touched } = useFormikContext<any>();

  const hasError =
    (errors[name] || includeErrorsFor.some((err) => errors[err])) &&
    (touched[name] || includeErrorsFor.some((err) => touched[err]));

  let elementClassName = 'p-field field-with-errors';
  if (hasError) {
    elementClassName += ' invalid';
  }
  if (className) {
    elementClassName += ' ' + className;
  }

  let errorMessage = errors[name];
  if (hasError && !errorMessage) {
    for (let err of includeErrorsFor) {
      if (errors[err]) {
        errorMessage = errors[err];
      }
    }
  }

  return (
    <div className={elementClassName}>
      {label !== false && label !== null && (
        <label htmlFor={name}>
          {label || _.capitalize(name.replace(/_/g, ' '))}
        </label>
      )}

      <div className={childrenWrapperClassName}>{children}</div>

      {hasError && typeof errorMessage === 'string' && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
}

export default FieldWithErrors;
