import './DialogStepper.scss';

import HeadlessStepper, { HeadlessStepperProps } from '../HeadlessStepper';
import DialogContainer, { DialogContainerProps } from './DialogContainer';

type Props = Omit<HeadlessStepperProps, 'children'> & DialogContainerProps;

function DialogStepper({
  // Header
  title,
  isStepIndicatorShown,
  // Footer
  onCancelBtnClick,
  backButtonProps,
  nextButtonProps,
  cancelButtonProps,
  submitBtnLabel,
  // Dialog,
  dialogProps,
  // Own
  ...rest
}: Props): JSX.Element {
  return (
    <HeadlessStepper {...rest}>
      <DialogContainer
        title={title}
        isStepIndicatorShown={isStepIndicatorShown}
        onCancelBtnClick={onCancelBtnClick}
        backButtonProps={backButtonProps}
        nextButtonProps={nextButtonProps}
        cancelButtonProps={cancelButtonProps}
        submitBtnLabel={submitBtnLabel}
        dialogProps={dialogProps}
      />
    </HeadlessStepper>
  );
}

export default DialogStepper;
