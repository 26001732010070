import './ErrorPage.scss';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import illustration404 from '../../assets/svg/404-error-pana.svg';

function Page404(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="page-404">
      <div className="error-container p-shadow-1">
        <h1>{t('Oops!')}</h1>
        <p>{t('The page you requested could not be found')}.</p>

        <img src={illustration404} alt="" />

        <p>
          {t('Go to the')} <Link to="/">{t('home page')}</Link>
        </p>
      </div>
    </div>
  );
}

export default Page404;
