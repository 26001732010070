import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getEmailActionLabels,
  getEmailRecipientLabels,
} from '../../../configs/email';
import ToastContext from '../../../context/ToastContext';
import useErrorState from '../../../hooks/react-hook-form/useErrorState';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import usePrevious from '../../../hooks/usePrevious';
import useToastMessage from '../../../hooks/useToastMessage';
import {
  ClientResource,
  ClientSelfUpdateRequestPayload,
} from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { infoToast } from '../../../utils/helpers/primereact';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Action from './Action';
import {
  getDefaultValues,
  getValidationSchema,
  toApiData,
} from './EmailConfiguration.functions';
import styles from './EmailConfiguration.module.scss';
import { FormFields } from './EmailConfiguration.types';

function EmailConfiguration(): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  usePageTitle(t('Email Configuration'));

  const userClientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state.user.client_id
  );

  const {
    data: clientResourceData,
    error: clientResourceError,
    isLoading: isClientResourceLoading,
    reload: clientResourceReload,
  } = useAxios<ClientResource>(`/clients/${userClientId}`);

  useToastMessage(undefined, clientResourceError, {
    error: {
      summary: t('An error occured while reading client data!'),
    },
  });

  const defaultValues = useMemo(
    () => getDefaultValues({ clientResource: clientResourceData }),
    [clientResourceData]
  );

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);
  const methods = useForm<FormFields>({
    defaultValues,
    resolver,
  });
  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const emailActionLabels = useMemo(() => getEmailActionLabels(t), [t]);
  const emailRecipientLabels = useMemo(() => getEmailRecipientLabels(t), [t]);

  const {
    data: formSubmissionData,
    error: formSubmissionError,
    isLoading: isFormSubmissionLoading,
    reload: formSubmissionReload,
  } = useAxios();

  const previousFormSubmissionData = usePrevious(formSubmissionData);

  useEffect(() => {
    if (previousFormSubmissionData !== formSubmissionData) {
      clientResourceReload();
    }
  }, [clientResourceReload, formSubmissionData, previousFormSubmissionData]);

  function onFormSubmit(values: FormFields) {
    const data: ClientSelfUpdateRequestPayload = toApiData(values);

    if (_.isEqual(data, toApiData(defaultValues))) {
      infoToast(
        toastRef,
        t('No changes made'),
        t("You haven't made any changes yet.")
      );

      return;
    }

    formSubmissionReload({
      method: 'PUT',
      url: `/clients/${userClientId}/self`,
      data,
    });
  }

  useToastMessage(formSubmissionData, formSubmissionError, {
    success: {
      summary: t('Successfully updated your email configuration!'),
    },
    error: {
      summary: t('An error occured while updating your email configuration.'),
    },
  });

  const { error, parentClassName } = useErrorState(
    'email_configuration',
    methods
  );

  return (
    <article
      className={classNames(styles.emailConfigurationPage, parentClassName)}
    >
      <HeaderPages
        title={t('Email Configuration')}
        subtitle={t('Here you can configure your e-mail addresses.')}
        icon={faEnvelopeOpenText}
      />

      {error}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {Object.keys(emailActionLabels).map((key) => (
            <Action
              key={key}
              emailAction={parseInt(key)}
              emailActionLabels={emailActionLabels}
              emailRecipientLabels={emailRecipientLabels}
              isLoading={isClientResourceLoading}
            />
          ))}

          <div className="p-d-flex p-jc-end">
            <Button
              type="submit"
              label={t('Save')}
              icon="fas fa-save"
              disabled={
                !!clientResourceError ||
                isClientResourceLoading ||
                isFormSubmissionLoading
              }
            />
          </div>
        </form>
      </FormProvider>
    </article>
  );
}

export default EmailConfiguration;
