import './Stepper.scss';

import classNames from 'classnames';
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from 'formik';
import { Button } from 'primereact/button';
import {
  CSSProperties,
  Children,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { StepProps } from './Step';

type CustomButtonProps = {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  class?: string;
};

type Props = Omit<FormikConfig<FormikValues>, 'innerRef'> & {
  title: string;
  customBackButton?: CustomButtonProps;
  customNextButton?: CustomButtonProps;
  customSubmitButton?: CustomButtonProps;
  customCancelButton?: CustomButtonProps;
  goToRoute?: string;
  maxExpandableWidth?: CSSProperties['maxWidth'];
  style?: CSSProperties;
  isBackBtnDisabled?: boolean;
  isNextBtnDisabled?: ({
    isLastStep,
    values,
  }: {
    isLastStep: boolean;
    values: any;
  }) => boolean;
  isStepIndicatorShown?: boolean;
  className?: string;
};

function Stepper({
  title,
  customBackButton,
  customNextButton,
  customSubmitButton,
  customCancelButton,
  goToRoute,
  children,
  onSubmit,
  initialValues,
  maxExpandableWidth = 720,
  style,
  isBackBtnDisabled,
  isNextBtnDisabled,
  isStepIndicatorShown = true,
  className,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const [stepIndex, setStepIndex] = useState(0);
  const [snapshot, setSnapshot] = useState(initialValues);
  const [isExpanded, setIsExpanded] = useState(false);

  const isMobileLayout = useMediaQuery('(max-width: 560px)');

  const formRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    setSnapshot(initialValues);
  }, [initialValues]);

  useEffect(() => {
    formRef.current?.resetForm();
  }, []);

  const steps = Children.toArray(children) as ReactElement<StepProps>[];
  const step = steps[stepIndex];
  const totalSteps = steps.length;
  const isLastStep = stepIndex === totalSteps - 1;

  function nextStep(values: FormikValues) {
    setSnapshot(values);
    setStepIndex((previousStepIndex) => previousStepIndex + 1);
  }

  function previousStep(values: FormikValues) {
    setSnapshot(values);
    setStepIndex((previousStepIndex) => previousStepIndex - 1);
  }

  async function handleSubmit(
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, helpers);
    }

    if (!isLastStep) {
      helpers.setTouched({});
      nextStep(values);
    }

    if (isLastStep) {
      await onSubmit(values, helpers);
    }
  }

  const header = (
    <div className="stepper-header">
      <div>
        <span className="stepper-title">{title}</span>
        <span
          className={classNames('step-indicator', {
            'p-d-none': !isStepIndicatorShown,
          })}
        >
          {t('Step')} {stepIndex + 1} {t('of')} {totalSteps}
        </span>
      </div>
      <div>
        <Button
          type="button"
          icon={isExpanded ? 'fas fa-compress' : 'fas fa-expand'}
          tooltip={isExpanded ? t('Compress') : t('Expand')}
          tooltipOptions={{ position: isExpanded ? 'left' : 'right' }}
          onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
          className="p-button-rounded p-button-text p-button-plain"
        />
      </div>
    </div>
  );

  const stepperStyle = {
    ...(isExpanded ? {} : { maxWidth: maxExpandableWidth }),
    ...(style ?? {}),
  };

  return (
    <Formik
      {...props}
      innerRef={formRef}
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {({ values, handleSubmit }) => (
        <div
          className={classNames('formik-stepper', className)}
          style={stepperStyle}
        >
          {header}
          <Form>
            <div className="content">
              {step.props.title && (
                <div className="title-and-subtitle-section">
                  {step.props.title && (
                    <span className="section-title">
                      {stepIndex + 1}. {step.props.title}
                    </span>
                  )}
                  {step.props.subtitle && (
                    <span className="section-subtitle">
                      {step.props.subtitle}
                    </span>
                  )}
                </div>
              )}
              {step}
            </div>

            <div className="p-d-flex p-jc-between p-ai-center footer">
              <div>
                <Button
                  type="button"
                  label={t('Cancel')}
                  className="p-button-secondary p-button-text"
                  onClick={() => goToRoute && history.push(goToRoute)}
                />
              </div>

              {!isMobileLayout && (
                <div className="p-d-flex p-jc-center p-ai-center">
                  {steps.map((s, index) => {
                    return (
                      <div
                        className={`step-dot ${
                          index === stepIndex && 'active'
                        }`}
                        key={s.props.title}
                      />
                    );
                  })}
                </div>
              )}

              <div className="nav-buttons-right p-d-flex">
                {stepIndex > 0 && (
                  <Button
                    className={customBackButton?.class ?? 'p-button-text'}
                    label={customBackButton?.label ?? t('Back')}
                    type={customBackButton?.type ?? 'button'}
                    onClick={() => previousStep(values)}
                    disabled={isBackBtnDisabled}
                  />
                )}

                <Button
                  label={
                    isLastStep
                      ? customSubmitButton?.label ?? t('Submit')
                      : customNextButton?.label ?? t('Next')
                  }
                  type={customNextButton?.type ?? 'submit'}
                  className={
                    isLastStep
                      ? customSubmitButton?.class
                      : customNextButton?.class
                  }
                  onClick={() => handleSubmit}
                  disabled={isNextBtnDisabled?.({ isLastStep, values })}
                  data-cy="submit-btn"
                />
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}

export default Stepper;
