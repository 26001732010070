import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs, { UnitType } from 'dayjs';
import i18next from 'i18next';
import { get as getIdb, set as setIdb } from 'idb-keyval';
import _ from 'lodash';
import * as rax from 'retry-axios';

import { error as httpError } from '../redux/actions/httpActions';
import { logoutUser, refreshAuthToken } from '../redux/actions/userActions';
import store from '../redux/store';

type CustomRequestConfig = AxiosRequestConfig & {
  _retry?: boolean;
  skipAttachingAuthToken?: boolean;
  isFromCache?: boolean;
};

type CacheConfig = Record<
  string,
  {
    maxAge?: [number, UnitType];
  }
>;

type CachedObj = {
  timestamp: Date;
  data: object;
};

type Cache = Record<string, CachedObj>;

// Some of these configurations are used in api.test.ts .
//  After modifying them, make sure you apply the changes in the test file as well.
const cacheConfig: CacheConfig = {
  '^/municipalities': {},
  '^/municipalities/[0-9]+/places': {},
  '^/places/[0-9]+': {},
  '^/occupations': {},
  '^/banks': {},
  '^/roles': {},
  '^/roles/employees': {},
  '^/clients/sms/gateways': {},
  '^/sms/gateways': {},
  '^/hubs(?!/.)': {
    maxAge: [1, 'hour'],
  },
  '^/entities': {
    maxAge: [1, 'hour'],
  },
  '^/entityproperties': {
    maxAge: [1, 'hour'],
  },
  '^/employees/all': {
    maxAge: [25, 'minutes'],
  },
};

let cache: Cache = {};

let isRefreshing = false;

let refreshQueue: {
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}[] = [];

const authTokenRefreshmentDisabledForRoutes: string[] = ['/users/login'];

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV !== 'test' ? process.env.REACT_APP_API_URL : undefined,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Retry
if (process.env.NODE_ENV !== 'test') {
  axiosInstance.defaults.raxConfig = {
    instance: axiosInstance,
    retry: 5,
    noResponseRetries: 5,
  };

  rax.attach(axiosInstance);
}

// Auth
function processQueue(error?: any): void {
  refreshQueue.forEach((refreshObj) => {
    if (error) {
      refreshObj.reject(error);
    } else {
      refreshObj.resolve();
    }
  });

  refreshQueue = [];
}

function authRequestInterceptor(
  request: CustomRequestConfig
): AxiosRequestConfig {
  if (!request.skipAttachingAuthToken) {
    const accessToken = store.getState().user?.access_token;

    if (accessToken) {
      request.headers.Authorization = 'Bearer ' + accessToken;
    }
  }

  return request;
}

function authTokenRefreshResponseInterceptor(
  error: AxiosError
): Promise<unknown> {
  let originalRequest = error.config as CustomRequestConfig;

  if (
    error.response?.status === 401 &&
    !originalRequest._retry &&
    (originalRequest.url
      ? !authTokenRefreshmentDisabledForRoutes.includes(originalRequest.url)
      : true)
  ) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshQueue.push({ resolve, reject });
      })
        .then(() => {
          return axiosInstance(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest._retry = true;

    isRefreshing = true;

    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          '/users/login',
          {
            refresh_token: store.getState().user?.refresh_token,
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
          },
          { skipAttachingAuthToken: true } as CustomRequestConfig
        )
        .then((res) => {
          store.dispatch(refreshAuthToken(res.data));
          processQueue();

          resolve(axiosInstance(originalRequest));
        })
        .catch((err) => {
          store.dispatch(logoutUser());
          processQueue(err);

          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
}

// Cache
async function getIdbCache(): Promise<Cache> {
  let returnVal = {};

  try {
    const cache = await getIdb('cache');

    if (cache && _.isObject(cache)) {
      returnVal = cache;
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return returnVal;
}

async function setIdbCache(newCacheObj: Cache): Promise<void> {
  try {
    await setIdb('cache', newCacheObj);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function cacheMutateRequestAdapter(
  request: AxiosRequestConfig,
  cachedData: CachedObj['data']
): void {
  request.adapter = () =>
    Promise.resolve({
      data: cachedData,
      status: 200,
      statusText: 'OK',
      headers: request.headers,
      config: { ...request, isFromCache: true },
      request: request,
    });
}

async function cacheRequestInterceptor(
  request: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  const url = request.url;
  const matchKey = Object.keys(cacheConfig).find((u) => !!url?.match(u));

  if (url && matchKey) {
    const cacheObj = cache[url] ?? (await getIdbCache())[url];
    const maxAge = cacheConfig[matchKey]?.maxAge ?? [1, 'day'];

    if (
      cacheObj &&
      dayjs(cacheObj.timestamp).add(maxAge[0], maxAge[1]).isAfter(dayjs())
    ) {
      cacheMutateRequestAdapter(request, cacheObj.data);
    }
  }

  return request;
}

async function cacheResponseInterceptor(
  response: AxiosResponse
): Promise<AxiosResponse> {
  if ((response.config as CustomRequestConfig).isFromCache) {
    return response;
  }

  const url = response.config.url;
  const matchKey = Object.keys(cacheConfig).find(
    (u) => !!response.config.url?.match(u)
  );

  if (url && matchKey) {
    const newCacheObj: CachedObj = {
      data: response.data,
      timestamp: new Date(),
    };

    cache[url] = newCacheObj;

    let idbCache = await getIdbCache();
    idbCache[url] = newCacheObj;
    setIdbCache(idbCache);
  }

  return response;
}

// Other
function errorInterceptor(error: AxiosError): Promise<never> {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // if (error.response.status === 403) {
    //   window.location.href = '/403';
    // }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    store.dispatch(
      httpError(
        null,
        i18next.t(
          'A network error occured. Please make sure you are connected to the internet and try again.'
        )
      )
    );
  } else {
    // Something happened in setting up the request that triggered an Error
    // Cancelled requests are brought here, so we don't do anything
  }

  return Promise.reject(error);
}

function urlConfigsRequestInterceptor(
  request: AxiosRequestConfig
): AxiosRequestConfig {
  request.headers['Accept-Language'] =
    store.getState().language ??
    process.env.REACT_APP_DEFAULT_LANGUAGE ??
    'en_US';

  return request;
}

// Axios instance
axiosInstance.interceptors.request.use(urlConfigsRequestInterceptor);
axiosInstance.interceptors.request.use(cacheRequestInterceptor);
axiosInstance.interceptors.request.use(authRequestInterceptor);
axiosInstance.interceptors.response.use(
  undefined,
  authTokenRefreshResponseInterceptor
);
axiosInstance.interceptors.response.use(cacheResponseInterceptor);
axiosInstance.interceptors.response.use(undefined, errorInterceptor);

export default axiosInstance;
