import './FieldWithErrors.scss';

import classNames from 'classnames';
import _ from 'lodash';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

type Props<T> = {
  name: keyof T;
  children: ReactNode;
  childrenWrapperClassName?: string;
  label?: ReactNode | false | null;
  labelClassName?: string;
  className?: string;
};

function FieldWithErrors<T extends object>({
  name,
  children,
  childrenWrapperClassName,
  label,
  labelClassName,
  className,
}: Props<T>): JSX.Element {
  const {
    formState: { errors },
  } = useFormContext<T>();

  const hasError = !!(errors as any)[name];
  const errorMessage = (errors as any)[name]?.message;

  const parentClassName = classNames(
    'p-field rhf-field-with-errors',
    {
      invalid: hasError,
    },
    className
  );

  return (
    <div className={parentClassName}>
      {label !== false && (
        <label htmlFor={String(name)} className={labelClassName}>
          {label || _.capitalize(String(name).replace(/_/g, ' '))}
        </label>
      )}

      <div className={childrenWrapperClassName}>{children}</div>

      {hasError && typeof errorMessage === 'string' && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
}

export default FieldWithErrors;
