import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import HeadlessStepperContext from '../HeadlessStepperContext';

export type HeaderProps = {
  title: string;
};

function Header({ title }: HeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { totalSteps, stepIndex } = useContext(HeadlessStepperContext);
  return (
    <div className="step-header">
      <HeaderPages
        title={title}
        subtitle={`${t('Step')} ${stepIndex + 1} ${t('of')} ${totalSteps}`}
        icon={faPlusSquare}
      />
    </div>
  );
}

export default Header;
