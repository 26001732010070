import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UnauthPage from '../../../../../page/UnauthPage';

function ForgotPasswordSuccess(): JSX.Element {
  const { t } = useTranslation();

  return (
    <UnauthPage
      title={t('auth.forgotPasswordSuccess.title')}
      description={t('auth.forgotPasswordSuccess.description')}
    >
      <Trans t={t} i18nKey="auth.forgotPasswordSuccess.content">
        <p>
          We sent a password reset link to your email. Please follow the
          instructions from there.
        </p>

        <p>
          If the message doesn't appear in your inbox in about 10 minutes,
          please check your spam folder or request a{' '}
          <Link to="/profile/forgot-password">new password reset link</Link>.
        </p>

        <p>
          Alternatively, you can go to the <Link to="/login">login page</Link>.
        </p>
      </Trans>
    </UnauthPage>
  );
}

export default ForgotPasswordSuccess;
